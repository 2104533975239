import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { ReactTable } from '@components';
import { MRT_TablePagination, MRT_ToggleGlobalFilterButton } from 'material-react-table'
import CreateTrade from './CreateTrade';
import { useDispatch } from 'react-redux';
import { apiInquireBiogasMarketBidAsk, apiDeleteBiogasMarketBidAsk, apiGetBiogas } from '@store';
import { showToast } from '@utils';
import TermsAndCondition from './TermsAndCondition';

const Biogas = ({ biogas, isLoading, openModal, handleCloseModal }) => {
    const dispatch = useDispatch();

    const handleInquire = (uuid) => async () => {
        try {
            const response = await dispatch(apiInquireBiogasMarketBidAsk(uuid));
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('success', 'Inquiry sent successfully');
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response?.payload);
            }
        } catch (error) {
            showToast('error', 'Failed to send inquiry');
        }
    }

    const handleDelete = (uuid) => async () => {
        try {
            const response = await dispatch(apiDeleteBiogasMarketBidAsk(uuid));
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Deleted successfully');
                dispatch(apiGetBiogas())
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response?.payload);
            }
        } catch (error) {
            showToast('error', 'Failed to delete');
        }
    }

    // terms and condition modal
    const [openTermsModal, setOpenTermsModal] = useState(false);
    const handleOpenTermsModal = () => { setOpenTermsModal(true); }
    const handleCloseTermsModal = () => { setOpenTermsModal(false); }

    const columns = useMemo(
        () => [
            {
                header: "Product",
                id: "product",
                accessorFn: ({ product }) => product || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Subsidy",
                id: "subsidy",
                accessorFn: ({ subsidy }) => subsidy || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Origin",
                id: "origin",
                accessorFn: ({ origin }) => origin || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Certification",
                id: "certification",
                accessorFn: ({ certification }) => certification || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Biogas Register</Box>
                        <Box>Delivery</Box>
                    </Box>
                ),
                id: "register",
                accessorFn: ({ register }) => register || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Feedstock",
                id: "feedstock",
                accessorFn: ({ feedstock }) => feedstock || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Quality",
                id: "quality",
                accessorFn: ({ quality }) => quality || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Delivery</Box>
                        <Box>Period</Box>
                    </Box>
                ),
                id: "delivery_period",
                accessorFn: ({ delivery_period }) => delivery_period || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Volume</Box>
                        <Box>[MWh]</Box>
                    </Box>
                ),
                id: "volume_str",
                accessorFn: ({ volume_str }) => volume_str?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Price",
                id: "price_str",
                accessorFn: ({ price_str }) => price_str?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4' key={'buttons'}>
                        <Button
                            variant='outlined'
                            color='primary'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: '1px solid #27A9FF',
                                color: '#27A9FF',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: '1px solid #27A9FF',
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                },
                            }}
                            onClick={handleInquire(row.original.uuid)}
                        >
                            Inquire
                        </Button>
                        <Button
                            variant='outlined'
                            color='error'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: 'brown',
                                color: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: 'brown',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={handleDelete(row.original.uuid)}
                        >
                            Delete
                        </Button>
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreateTrade
                    handleClose={handleCloseModal}
                    open={openModal}
                />
            </Modal>
            {/* terms and Condition */}
            <Modal open={openTermsModal} onClose={handleCloseTermsModal}>
                <TermsAndCondition handleClose={handleCloseTermsModal} />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                        </div>
                    )
                }
                renderBottomToolbar={
                    ({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '10px',
                            }}
                        >
                            <Typography
                                style={{
                                    color: '#0B9CFD',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleOpenTermsModal}
                            >
                                Terms & Conditions
                            </Typography>
                            <MRT_TablePagination table={table} />
                        </Box>
                    )
                }
                headerText={'Market Overview'}
                columns={columns}
                data={biogas || []}
                actions={actionsMenu}
            />
        </Box>
    )
}

export default Biogas