import { Box, InputAdornment, List, TextField } from '@mui/material';
import React, { useRef } from 'react';
import {
    useGetChatProfileQuery,
    useGetOrSearchChatUsersQuery
} from '@store/feature/chat/chatApi';
import { ChatCardOverlay } from './ChatCardOverlay';
import SearchIcon from '@mui/icons-material/Search';

const ChatList = ({ handleGoToSingleChat }) => {
    const searchTimeout = 500;
    const searchRef = useRef(null);

    const [searchText, setSearchText] = React.useState('');
    const { data: usersData } = useGetOrSearchChatUsersQuery(searchText);
    const { data: profile } = useGetChatProfileQuery();

    const handleChange = (e) => {
        e.preventDefault();

        const text = e.target.value;

        // If search is already set, clear it
        if (searchRef.current) {
            clearTimeout(searchRef.current);
        }

        // Set a new search timeout
        searchRef.current = setTimeout(() => {
            setSearchText(text);
        }, searchTimeout);
    };

    return (
        <>
            <Box
                sx={{
                    mt: '10px',
                    px: '16px',
                    position: 'sticky',
                    top: '0',
                    pb: '4px'
                }}
            >
                <TextField
                    label="Search by name"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                />
            </Box>
            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 'calc(100% - (165px))',
                    overflowY: 'scroll'
                }}
            >
                {usersData?.map((u, index) => {
                    return (
                        <ChatCardOverlay
                            imageURL={u?.user?.image_url}
                            name={u?.user?.fullname}
                            key={index}
                            userId={u?.user?.uuid}
                            messageCount={u?.unread_messages}
                            message={u?.latest_message?.body}
                            time={u?.latest_message?.created_at}
                            isActive={
                                u?.user?.status === 'active' ||
                                profile?.uuid === u?.user?.uuid
                            }
                            handleGoToSingleChat={handleGoToSingleChat}
                        />
                    );
                })}
            </List>
        </>
    );
};

export default ChatList;
