import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px' }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ tabs }) {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue) };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        background: '#fff',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '5px',
                        margin: '0 0 10px 10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                        minHeight: '45px',
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                        '& .MuiTab-root': {
                            minHeight: '35px',
                            height: '35px',
                            '&.Mui-selected': {
                                color: '#3AACFE',
                                background: '#DFF2FF',
                                borderRadius: '5px',
                                borderBottom: 'none',
                            },
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.name}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: '0 10px'
                    }}
                >
                    {tabs[value].buttons && tabs[value].buttons.map((button, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            className='page-button'
                            onClick={button.onClick}
                            sx={{
                                bgcolor: '#0b9cfd',
                                '&:hover': {
                                    bgcolor: '#0b9cfd',
                                },
                            }}
                        >
                            {button.text}
                        </Button>
                    ))}
                </Box>
            </Box>
            {tabs.map((tab, index) => (
                <CustomTabPanel value={value} index={index} key={index}>
                    {tab.content}
                </CustomTabPanel>
            ))}
        </Box>
    );
}