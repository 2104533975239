import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import { apiGetSetPriceProfile, apiSavePriceProfile } from '@store'
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateProfile = forwardRef(({ handleClose, eur, user }, ref) => {
    const dispatch = useDispatch()
    const contract = eur?.symbols

    const [state, setState] = useState({
        name: '',
        currency: '',
        product: 'THE',
        user: user,
        contracts: [],
        contractvolume: {}
    })

    const handleChangeMultiple = (event) => {
        const { target: { value }, } = event;
        const contracts = typeof value === 'string' ? value.split(',') : value
        setState({ ...state, contracts: contracts })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleContractVolumeChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, contractvolume: { ...prev.contractvolume, [name]: value } }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Filter out the contractvolume that are no in contracts
        const tempContractVolume = Object.keys(state.contractvolume).reduce((acc, key) => {
            if (state.contracts.includes(key)) {
                acc[key] = state.contractvolume[key]
            }
            return acc
        }, {})

        // convert contractvolume to float32 or float64
        Object.keys(tempContractVolume).forEach(key => {
            tempContractVolume[key] = parseFloat(tempContractVolume[key])
        })

        const data = {
            name: state.name,
            currency: state.currency,
            product: state.product,
            user: state.user,
            contractvolume: tempContractVolume
        }

        const resp = await dispatch(apiSavePriceProfile(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetSetPriceProfile())
            showToast('success', 'Profile created successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload || 'Failed to create profile')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            modalTitle={`Create Profile`}
            handleSubmit={handleSubmit}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box className=''>
                <Box className='flex gap-2 mb-4'>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="profileNameLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Profile Name
                        </InputLabel>
                        <OutlinedInput
                            id="name"
                            label="Profile Name"
                            name='name'
                            onChange={handleChange}
                            value={state.name}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Currency"
                            name='currency'
                            onChange={handleChange}
                            value={state.currency}
                            required
                        >
                            <MenuItem value='chf'>CHF</MenuItem>
                            <MenuItem value='eur'>EUR</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">Contracts</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={state.contracts}
                            onChange={handleChangeMultiple}
                            input={<OutlinedInput id="select-multiple-chip" label="Contracts" />}
                            MenuProps={MenuProps}
                        >
                            {contract?.map((name) => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                {/* map selected contracts  */}
                {/* also add an input for each contract in which user can input the volume for each contract in table form */}
                <Box className='mt-4 flex flex-col gap-3 max-h-96 overflow-y-auto px-4'>
                    {/* {contracts.map((contract, index) => (
                        <Box key={index} className='flex justify-between items-center bg-gray-200 p-2 rounded-lg'>
                            <Box>{contract}</Box>
                        </Box>
                    ))} */}

                    {state.contracts.map((contract, index) => {
                        const contractName = contract

                        return (
                            <Box key={index} className='flex items-center gap-10'>
                                <Box className='w-24'>{contract}</Box>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel
                                        id="contractLabel"
                                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                                    >
                                        Volume
                                    </InputLabel>
                                    <OutlinedInput
                                        id="volume"
                                        label="Volume"
                                        name={contractName}
                                        onChange={handleContractVolumeChange}
                                        // onChange={handleChange}
                                        // value={state.volume}
                                        type="number"
                                        inputProps={{ step: 0.01 }}
                                        required
                                        endAdornment={
                                            <InputAdornment position="start" sx={{ ml: 2 }}>
                                                MW
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </CustomModal>
    )
})

export default CreateProfile