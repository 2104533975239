import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import newsfeedReducer from './newsfeed/newsfeedSlice';
import priceprofileReducer from './priceprofile/priceprofileSlice';
import marketReducer from './market/marketSlice';
import theReducer from './the/theSlice';
import ttfReducer from './ttf/ttfSlice';
import marketplaceReducer from './marketplace/marketplaceSlice';
import intradayReducer from './intraday/intradaySlice';
import pegnordReducer from './pegnord/pegnordSlice';
import orderReducer from './order/orderSlice';
import watchlistReducer from './watchlist/watchlistSlice';
import accountReducer from './settings/account/accountSlice';
import endpointReducer from './settings/endpoint/endpointSlice';
import notificationReducer from './notification/notificationSlice';
import cheReducer from './che/cheSlice';
import chReducer from './ch/chSlice';
import announcementReducer from './announcement/announcementSlice';
import overviewReducer from './overview/overviewSlice';
import chatReducer from './chat/chatSlice';
import { chatApi } from './chat/chatApi';

export const store = configureStore({
    reducer: {
        user: userReducer,
        newsfeed: newsfeedReducer,
        priceprofile: priceprofileReducer,
        market: marketReducer,
        the: theReducer,
        ttf: ttfReducer,
        marketplace: marketplaceReducer,
        intraday: intradayReducer,
        pegnord: pegnordReducer,
        order: orderReducer,
        watchlist: watchlistReducer,
        account: accountReducer,
        endpoint: endpointReducer,
        notification: notificationReducer,
        che: cheReducer,
        ch: chReducer,
        announcement: announcementReducer,
        overview: overviewReducer,
        chat: chatReducer,
        [chatApi.reducerPath]: chatApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(chatApi.middleware)
});
