import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    marketplace: [],
    isGetMarketplaceLoading: false,
    isCreateMarketBidAskLoading: false,
    biogas: [],
    isGetBiogasLoading: false,
    isInquireBiogasMarketBidAskLoading: false,
    isDeleteBioGasMarketBidAskLoading: false,
    isCreateBioGasMarketBidAskLoading: false,
    isSaveBidOrderLoading: false,
    isDeleteMarketBidAskLoading: false
};

export const apiGetMarketplace = createAsyncThunk(
    "marketplace/getMarketplace",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_market_bids_and_asks');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateMarketBidAsk = createAsyncThunk(
    "marketplace/createMarketBid",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/post_market_bid_ask', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetBiogas = createAsyncThunk(
    "marketplace/getBiogas",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_biogas_market_bids_and_asks');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateBiogasMarketBidAsk = createAsyncThunk(
    "marketplace/createBiogasMarketBid",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/post_biogas_market_bid_ask', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiInquireBiogasMarketBidAsk = createAsyncThunk(
    "marketplace/inquireBiogasMarketBid",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/inquire_biogas_market_bid_ask?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteBiogasMarketBidAsk = createAsyncThunk(
    "marketplace/deleteBiogasMarketBid",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_biogas_market_bid_ask?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiSaveBidOrder = createAsyncThunk(
    "marketplace/saveBidOrder",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/save_bid_order', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteMarketBidAsk = createAsyncThunk(
    "marketplace/deleteMarketBid",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_market_bid_ask?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const marketplaceSlice = createSlice({
    name: "marketplace",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Marketplace
        builder
            .addCase(apiGetMarketplace.pending, (state) => {
                state.isGetMarketplaceLoading = true;
            })
            .addCase(apiGetMarketplace.fulfilled, (state, action) => {
                state.marketplace = action.payload.data;
                state.isGetMarketplaceLoading = false;
            })
            .addCase(apiGetMarketplace.rejected, (state) => {
                state.isGetMarketplaceLoading = false;
            });

        // Create Market Bid
        builder
            .addCase(apiCreateMarketBidAsk.pending, (state) => {
                state.isCreateMarketBidAskLoading = true;
            })
            .addCase(apiCreateMarketBidAsk.fulfilled, (state) => {
                state.isCreateMarketBidAskLoading = false;
            })
            .addCase(apiCreateMarketBidAsk.rejected, (state) => {
                state.isCreateMarketBidAskLoading = false;
            });

        // Get Biogas
        builder
            .addCase(apiGetBiogas.pending, (state) => {
                state.isGetBiogasLoading = true;
            })
            .addCase(apiGetBiogas.fulfilled, (state, action) => {
                state.biogas = action.payload.data;
                state.isGetBiogasLoading = false;
            })
            .addCase(apiGetBiogas.rejected, (state) => {
                state.isGetBiogasLoading = false;
            });

        // Create Biogas Market Bid
        builder
            .addCase(apiCreateBiogasMarketBidAsk.pending, (state) => {
                state.isCreateBioGasMarketBidAskLoading = true;
            })
            .addCase(apiCreateBiogasMarketBidAsk.fulfilled, (state) => {
                state.isCreateBioGasMarketBidAskLoading = false;
            })
            .addCase(apiCreateBiogasMarketBidAsk.rejected, (state) => {
                state.isCreateBioGasMarketBidAskLoading = false;
            });

        // Inquire Biogas Market Bid
        builder
            .addCase(apiInquireBiogasMarketBidAsk.pending, (state) => {
                state.isInquireBiogasMarketBidAskLoading = true;
            })
            .addCase(apiInquireBiogasMarketBidAsk.fulfilled, (state) => {
                state.isInquireBiogasMarketBidAskLoading = false;
            })
            .addCase(apiInquireBiogasMarketBidAsk.rejected, (state) => {
                state.isInquireBiogasMarketBidAskLoading = false;
            });

        // Delete Biogas Market Bid
        builder
            .addCase(apiDeleteBiogasMarketBidAsk.pending, (state) => {
                state.isDeleteBioGasMarketBidAskLoading = true;
            })
            .addCase(apiDeleteBiogasMarketBidAsk.fulfilled, (state) => {
                state.isDeleteBioGasMarketBidAskLoading = false;
            })
            .addCase(apiDeleteBiogasMarketBidAsk.rejected, (state) => {
                state.isDeleteBioGasMarketBidAskLoading = false;
            });
        
        // Save Bid Order
        builder
            .addCase(apiSaveBidOrder.pending, (state) => {
                state.isSaveBidOrderLoading = true;
            })
            .addCase(apiSaveBidOrder.fulfilled, (state) => {
                state.isSaveBidOrderLoading = false;
            })
            .addCase(apiSaveBidOrder.rejected, (state) => {
                state.isSaveBidOrderLoading = false;
            });
        
        // Delete Market Bid
        builder
            .addCase(apiDeleteMarketBidAsk.pending, (state) => {
                state.isDeleteMarketBidAskLoading = true;
            })
            .addCase(apiDeleteMarketBidAsk.fulfilled, (state) => {
                state.isDeleteMarketBidAskLoading = false;
            })
            .addCase(apiDeleteMarketBidAsk.rejected, (state) => {
                state.isDeleteMarketBidAskLoading = false;
            });
    }
});

export default marketplaceSlice.reducer;