import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    Box,
    Typography,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { AppName, UpdatePasswordTitle } from "@utils";

import HeroBackgroudImage from '../assets/images/background.png';
import { loginPageData } from "../data";

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useDispatch } from "react-redux";
import KeyIcon from "@mui/icons-material/Key";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { apiUpdatePassword } from "@store";
import { showToast } from "@utils";

export default function UpdatePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${AppName} | ${UpdatePasswordTitle}`
    }, [])

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.hash]);

    // get reset_token from url
    const search = location.search;
    const params = new URLSearchParams(search);
    const reset_token = params.get('token');

    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false)

    const [state, setState] = useState({
        password: "",
        confirm_password: "",
        reset_token: reset_token,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // check if password and confirm password are same
        // remove confirm_password from state before sending to api
        if (state.password !== state.confirm_password) {
            showToast("error", "Password and Confirm Password do not match");
            return;
        } else {
            const { confirm_password, ...data } = state;
            const response = await dispatch(apiUpdatePassword(data));
            if (response?.meta?.requestStatus === 'fulfilled') {
                showToast("success", "Password updated successfully");
                navigate("/login");
            } else if (response?.meta?.requestStatus === 'rejected') {
                showToast("error", response.payload);
            }
        }
    }

    return (
        <Box sx={styles.root}>
            <Box sx={styles.topSection} id="home">
                <Box sx={styles.header}>
                    <Typography sx={styles.logo}>
                        Price<span style={{ color: '#2EA3F0' }}>Blick</span>
                    </Typography>
                    <Box sx={styles.headerLinks}>
                        {/* scroll down the page to respective section when click with id */}
                        <Typography
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("#home")}
                        >
                            Home
                        </Typography>
                        <Typography
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("#about")}
                        >
                            About
                        </Typography>
                        <Typography
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("#contact")}
                        >
                            Contact
                        </Typography>
                    </Box>
                </Box>
                <Box sx={styles.hero}>
                    <Typography
                        sx={styles.heroText}
                    >
                        Empowering growth with real data
                    </Typography>
                    <Box sx={styles.loginContainer}>
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                            Update Password
                        </Typography>
                        {/* update password */}
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} variant="outlined">
                                        <InputLabel htmlFor="login-password">
                                            Password
                                        </InputLabel>
                                        <OutlinedInput
                                            label="Password"
                                            value={state.password}
                                            autoComplete="current-password"
                                            onChange={(e) =>
                                                setState({
                                                    ...state,
                                                    password: e.target.value,
                                                })
                                            }
                                            id="login-password"
                                            required
                                            type={showPass ? "text" : "password"}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPass(!showPass)
                                                        }
                                                        onMouseDown={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPass ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <KeyIcon />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true} variant="outlined">
                                        <InputLabel htmlFor="login-password">
                                            Confirm Password
                                        </InputLabel>
                                        <OutlinedInput
                                            label="Confirm Password"
                                            value={state.confirm_password}
                                            autoComplete="current-password"
                                            onChange={(e) =>
                                                setState({
                                                    ...state,
                                                    confirm_password: e.target.value,
                                                })
                                            }
                                            id="login-password"
                                            required
                                            type={showConfirmPass ? "text" : "password"}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowConfirmPass(!showConfirmPass)
                                                        }
                                                        onMouseDown={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        edge="end"
                                                    >
                                                        {showConfirmPass ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <KeyIcon />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={styles.button}
                            >
                                Update Password
                            </Button>
                        </Box>
                        {/* end */}
                        <Box sx={{ mt: '20px' }}>
                            <Link to="/login" style={{ textDecoration: "none" }}>
                                <Typography variant="body2">
                                    Back to login
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.midSection} id="about">
                <Typography sx={styles.midSectionHeading}>
                    Meet PriceBlick
                </Typography>
                <Box sx={styles.midSectionBox}>
                    {loginPageData.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "20px",
                                padding: "50px 50px",
                                border: "2px solid #3dc3c1",
                                borderRadius: "5px",
                                background: "#E9F3F3",
                                width: "400px",
                            }}
                        >
                            <img
                                src={item.image}
                                alt={item.title}
                                style={{
                                    width: "144px",
                                    height: "140px",
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    fontSize: "28px",
                                }}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "center",
                                    fontSize: '18px'
                                }}
                            >
                                {item.description}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={styles.bottomSection} id="contact">
                <Typography sx={styles.midSectionHeading}>
                    GET IN TOUCH
                </Typography>
                <Box sx={styles.bottomSectionContainer}>
                    <Box>
                        <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                            <EmailOutlinedIcon />
                            <Typography variant="body1">
                                info@priceblick.com
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                            <LocationOnOutlinedIcon />
                            <Typography variant="body1">
                                United Kingdom
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                            <LocalPhoneOutlinedIcon />
                            <Typography variant="body1">
                                +44 07714138214
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter your name"
                            sx={{
                                width: "500px",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter your phone Number"
                            sx={{
                                width: "500px",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="email"
                            placeholder="Enter valid email address"
                            sx={{
                                width: "500px",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter your message"
                            multiline
                            rows={4}
                            sx={{
                                width: "500px",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                backgroundColor: "light-blue",
                                '&:active': {
                                    backgroundColor: 'light-blue'
                                },
                                '&:hover': {
                                    backgroundColor: 'light-blue'
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const styles = {
    root: {
        height: '100%',
    },
    topSection: {
        backgroundImage: `url(${HeroBackgroudImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        px: '70px',
        py: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        fontSize: "1rem",
        fontWeight: "bold",
        color: "white",
        px: '150px',
    },
    logo: {
        borderRight: '3px solid #fff',
        fontSize: "2rem",
        fontWeight: "bold",
        color: "white",
        textDecoration: "none",
        corsor: "pointer",
        paddingRight: '20px',
    },
    headerLinks: {
        display: 'flex',
        gap: '20px',
    },
    hero: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100% - 250px)',
        px: '150px'
    },
    heroText: {
        color: '#fff',
        fontSize: '50px',
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '66px',
        width: '35%',
    },
    loginContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "400px",
        border: "1px solid #ccc",
        padding: "30px 40px",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        marginTop: '100px',
    },
    icon: {
        color: "#fff",
        backgroundColor: "#F50057",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
    },
    button: {
        fontWeight: "bold",
        marginTop: "20px",
        backgroundColor: "light-blue",
        height: "50px",
        '&:active': {
            backgroundColor: 'light-blue'
        },
        '&:hover': {
            backgroundColor: 'light-blue'
        }
    },
    midSection: {
        padding: '0 70px 50px',
        px: '150px'
    },
    midSectionHeading: {
        fontSize: '48px',
        textAlign: 'center',
        marginBottom: '70px',
    },
    midSectionBox: {
        display: 'flex',
        justifyContent: 'space-around',
        gap: '20px',
    },
    bottomSection: {
        background: 'linear-gradient(88.89deg, #3B9D9D 0%, #27485D 83.49%)',
        px: '150px',
        py: '50px',
        color: '#fff',
    },
    bottomSectionContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    }
}