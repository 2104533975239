import { toast } from 'react-toastify';

const showToast = (type, message, position) => {
    toast(message, {
        position,
        type,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        newestOnTop: true,
        rtl: false,
        pauseOnVisibilityChange: true,
    });
}

export default showToast;