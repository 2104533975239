import { useSelector } from "react-redux"
import { TabComponent } from "@components"
import EUR from "./eur/EUR"
import { useEffect, useState } from "react"
import { AppName, GAS_PEG_TITLE } from "@utils"

const The = () => {

    useEffect(() => {
        document.title = `${AppName} | ${GAS_PEG_TITLE}`
    }, [])

    const { pegnordEur, isGetPegnordEurLoading } = useSelector((state) => state.pegnord)
    const { disableTrading } = useSelector(state => state.the);

    // price notification modal
    const [priceNotificationModal, setPriceNotificationModal] = useState(false)
    const handleOpenModal = () => { setPriceNotificationModal(true); }
    const handleCloseModal = () => { setPriceNotificationModal(false); }

    // filter modal
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const handleOpenFilterModal = () => setOpenFilterModal(true)
    const handleCloseFilterModal = () => setOpenFilterModal(false)

    const tabs = [
        {
            name: 'EUR',
            content:
                <EUR
                    eur={pegnordEur}
                    isLoading={isGetPegnordEurLoading}
                    openModal={priceNotificationModal}
                    handleCloseModal={handleCloseModal}
                    openFilterModal={openFilterModal}
                    hanldeOpenFilterModal={handleOpenFilterModal}
                    handleCloseFilterModal={handleCloseFilterModal}
                    disableTradingStatus={disableTrading?.disable}
                />,
            buttons: [
                {
                    text: 'Set Price Notification',
                    backgroundColor: 'blue',
                    color: 'white',
                    onClick: () => { handleOpenModal() }
                }
            ]
        }
    ]

    return (
        <TabComponent tabs={tabs} />
    )
}

export default The