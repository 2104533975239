import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { TabComponent } from "@components"
import CHF from "./chf/CHF"
import EUR from "./eur/EUR"
import Profiles from "./profile/Profiles"
import ClosingPrice from "./closingPrice/ClosingPrice"
import { AppName, GAS_THE_TITLE } from "@utils"

const The = () => {

    useEffect(() => {
        document.title = `${AppName} | ${GAS_THE_TITLE}`
    }, [])

    const {
        theeur,
        isGetTheEurLoading,
        chf,
        isGetChfLoading,
        setPriceProfile,
        isGetSetPriceProfileLoading,
        minMaxDisplay,
        trailingStopForDisplay,
        disableTrading
    } = useSelector(state => state.the);

    // price notification modal
    const [priceNotificationModal, setPriceNotificationModal] = useState(false)
    const handleOpenModal = useCallback(() => { setPriceNotificationModal(true); }, [])
    const handleCloseModal = useCallback(() => { setPriceNotificationModal(false); }, [])

    const { user } = useSelector((state) => state.user);

    // trailing stop modal
    const [trailingStopModal, setTrailingStopModal] = useState(false)
    const handleOpenTrailingStopModal = () => { setTrailingStopModal(true); }
    const handleCloseTrailingStopModal = () => { setTrailingStopModal(false); }

    // filter modal
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const handleOpenFilterModal = () => setOpenFilterModal(true)
    const handleCloseFilterModal = () => setOpenFilterModal(false)

    const tabs = [
        {
            name: 'EUR',
            content: <EUR
                eur={theeur}
                isLoading={isGetTheEurLoading}
                minMaxDisplay={minMaxDisplay}
                trailingStop={trailingStopForDisplay}
                openModal={priceNotificationModal}
                handleCloseModal={handleCloseModal}
                openTrailingStopModal={trailingStopModal}
                handleCloseTrailingStopModal={handleCloseTrailingStopModal}
                openFilterModal={openFilterModal}
                hanldeOpenFilterModal={handleOpenFilterModal}
                handleCloseFilterModal={handleCloseFilterModal}
                disableTradingStatus={disableTrading?.disable}
            />,
            buttons: [
                {
                    text: 'Set Price Notification',
                    onClick: handleOpenModal
                }
            ]
        },
        {
            name: 'Profiles',
            content: <Profiles
                priceProfile={setPriceProfile}
                isLoading={isGetSetPriceProfileLoading}
                eur={theeur}
                disableTradingStatus={disableTrading?.disable}
            />,
        }, {
            name: 'Closing Prices',
            content: <ClosingPrice />,
        }
    ]

    if (user?.HideNCGCHF === false) {
        tabs.splice(1, 0, {
            name: 'CHF',
            content: <CHF
                chf={chf}
                isLoading={isGetChfLoading}
                openFilterModal={openFilterModal}
                hanldeOpenFilterModal={handleOpenFilterModal}
                handleCloseFilterModal={handleCloseFilterModal}
                disableTradingStatus={disableTrading?.disable}
            />,
        })
    }

    if (user?.HideTrailingStop === false) {
        if (tabs[0].buttons && tabs[0].buttons.length === 1) {
            tabs[0].buttons.push({
                text: 'Set Trailing Stop',
                backgroundColor: 'blue',
                color: 'white',
                onClick: handleOpenTrailingStopModal
            })
        }
    } else {
        tabs[0].buttons = tabs[0].buttons.filter(button => button.text !== 'Set Trailing Stop')
    }

    return (
        <TabComponent tabs={tabs} />
    )
}

export default The