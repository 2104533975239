import React, { useEffect, useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { COMMON_PATH, AppName, ForgotPasswordTitle } from '@utils'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiForgotPassword } from '@store';
import { showToast } from '@utils'

const ForgotPassword = () => {
    useEffect(() => {
        document.title = `${AppName} | ${ForgotPasswordTitle}`
    }, [])

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState({
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resp = await dispatch(apiForgotPassword(state));
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Reset password link sent to your email')
            navigate(COMMON_PATH.LOGIN)
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Error sending reset password link')
        }
    }

    return (
        <Box component="form" sx={{ mt: 1 }}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                onChange={handleChange}
                name="email"
                value={state.email}
                autoComplete="email"
                autoFocus
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.button}
                onClick={handleSubmit}
            >
                Reset password
            </Button>
        </Box>
    );
};

export default ForgotPassword;


const styles = {
    root: {
        height: '100%',
    },
    topSection: {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        px: '70px',
        py: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        fontSize: "1rem",
        fontWeight: "bold",
        color: "white",
        px: '150px',
    },
    logo: {
        borderRight: '3px solid #fff',
        fontSize: "2rem",
        fontWeight: "bold",
        color: "white",
        textDecoration: "none",
        corsor: "pointer",
        paddingRight: '20px',
    },
    headerLinks: {
        display: 'flex',
        gap: '20px',
    },
    hero: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100% - 250px)',
        px: '150px'
    },
    heroText: {
        color: '#fff',
        fontSize: '50px',
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '66px',
        width: '35%',
    },
    loginContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "400px",
        border: "1px solid #ccc",
        padding: "40px",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
    },
    icon: {
        color: "#fff",
        backgroundColor: "#F50057",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
    },
    button: {
        fontWeight: "bold",
        marginTop: "20px",
        backgroundColor: "light-blue",
        height: "50px",
        '&:active': {
            backgroundColor: 'light-blue'
        },
        '&:hover': {
            backgroundColor: 'light-blue'
        }
    },
    midSection: {
        padding: '0 70px 50px',
        px: '150px'
    },
    midSectionHeading: {
        fontSize: '48px',
        textAlign: 'center',
        marginBottom: '70px',
    },
    midSectionBox: {
        display: 'flex',
        justifyContent: 'space-around',
        gap: '20px',
    },
    bottomSection: {
        background: 'linear-gradient(88.89deg, #3B9D9D 0%, #27485D 83.49%)',
        px: '150px',
        py: '50px',
        color: '#fff',
    },
    bottomSectionContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    }
}
