import React, { useState } from "react";
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockIcon from '@mui/icons-material/Lock';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { COMMON_PATH, showToast } from "@utils";
import { apiLogout } from "@store";

const TopbarWithLogo = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const topbarLinks = [
        {
            title: 'Profile',
            url: COMMON_PATH.PROFILE,
            icon: <PersonIcon />
        },
        {
            title: 'Manage Accounts',
            url: COMMON_PATH.SETTINGS,
            icon: <ManageAccountsIcon />,
            isAdmin: true
        },
        {
            title: 'Change Password',
            url: COMMON_PATH.CHANGE_PASSWORD,
            icon: <LockIcon />
        }
    ];

    // menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // logout
    const handleLogout = async () => {
        if (window.confirm("Are you sure you want to log out?")) {
            const resp = await dispatch(apiLogout());
            if (resp.meta.requestStatus === "fulfilled") {
                localStorage.removeItem("loginState");
                window.location.reload();
            } else if (resp.meta.requestStatus === "rejected") {
                showToast("error", resp.payload);
            } else {
                showToast("error", "Failed to logout");
            }
        }
    }

    return (
        <Box className='bg-gradient-to-r from-[#27485D] to-[#3B9D9D] text-white h-[69px] flex justify-between px-6 py-2 items-center'>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "white",
                }}
            >
                <Link
                    to={COMMON_PATH.HOME}
                    style={{
                        borderRight: '3px solid #fff',
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                        '&:hover': {
                            color: "#2FB5FE",
                        },
                        paddingRight: '10px',
                    }}
                >
                    Price<span style={{ color: '#2EA3F0' }}>Blick</span>
                </Link>
                {/* <Typography>
                    {title}
                </Typography> */}
            </Box>
            <Box
                display="flex"
                marginLeft="auto"
                alignItems="center"
                gap={2}
            >
                <Link to={'https://www.gov.uk/bank-holidays'} target="_blank" className='border-r-2 border-white pr-3'>
                    Next UK Holiday : {user?.NextBankHoliday ?? 'N/A'}
                </Link>
                <Box>
                    <Tooltip title="Menu">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <PersonOutlineOutlinedIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{
                            zIndex: 9999
                        }}
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: { ...styles },
                            zIndex: 999
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {topbarLinks.map((link, index) => {
                            if (link.isAdmin && !user?.Admin) {
                                return null;
                            }
                            return (
                                <MenuItem key={index} component={Link} to={link.url} className='flex gap-2'>
                                    {link.icon}
                                    {link.title}
                                </MenuItem>
                            );
                        })}
                        <MenuItem onClick={handleLogout}>
                            <PowerSettingsNewIcon className='mr-2' />
                            Logout
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Box>
    )
}

export default TopbarWithLogo

const styles = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
}