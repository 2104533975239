import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    announcement: [],
    isGetAnnouncementLoading: false,
}

export const apiGetAnnouncement = createAsyncThunk(
    "announcement/getAnnouncement",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_announcements');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const announcementSlice = createSlice({
    name: "announcement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Announcement
        builder
            .addCase(apiGetAnnouncement.pending, (state) => {
                state.isGetAnnouncementLoading = true;
            })
            .addCase(apiGetAnnouncement.fulfilled, (state, action) => {
                state.announcement = action.payload.data;
                state.isGetAnnouncementLoading = false;
            })
            .addCase(apiGetAnnouncement.rejected, (state) => {
                state.isGetAnnouncementLoading = false;
            });
    }
});

export default announcementSlice.reducer;