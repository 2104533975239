import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    account: [],
    isGetAccountLoading: false,
    isCreateAccountLoading: false,
    isUpdateAccountLoading: false,
    isCreateUserLoading: false,
    isUpdateAccountUserLoading: false
};

export const apiGetAccount = createAsyncThunk(
    "account/getAccount",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/getaccounts');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateAccount = createAsyncThunk(
    "account/createAccount",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/createaccount', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdateAccount = createAsyncThunk(
    "account/updateAccount",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/updateaccount', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateAccountUser = createAsyncThunk(
    "account/createUser",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/createuser', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const apiUpdateAccountUser = createAsyncThunk(
    "account/updateUser",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/updateuser', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(apiGetAccount.pending, (state) => {
                state.isGetAccountLoading = true;
            })
            .addCase(apiGetAccount.fulfilled, (state, action) => {
                state.isGetAccountLoading = false;
                state.account = action.payload.data;
            })
            .addCase(apiGetAccount.rejected, (state) => {
                state.isGetAccountLoading = false;
            });

        // create account
        builder
            .addCase(apiCreateAccount.pending, (state) => {
                state.isCreateAccountLoading = true;
            })
            .addCase(apiCreateAccount.fulfilled, (state) => {
                state.isCreateAccountLoading = false;
            })
            .addCase(apiCreateAccount.rejected, (state) => {
                state.isCreateAccountLoading = false;
            });

        // update account
        builder
            .addCase(apiUpdateAccount.pending, (state) => {
                state.isUpdateAccountLoading = true;
            })
            .addCase(apiUpdateAccount.fulfilled, (state) => {
                state.isUpdateAccountLoading = false;
            })
            .addCase(apiUpdateAccount.rejected, (state) => {
                state.isUpdateAccountLoading = false;
            });

        // create user
        builder
            .addCase(apiCreateAccountUser.pending, (state) => {
                state.isCreateUserLoading = true;
            })
            .addCase(apiCreateAccountUser.fulfilled, (state) => {
                state.isCreateUserLoading = false;
            })
            .addCase(apiCreateAccountUser.rejected, (state) => {
                state.isCreateUserLoading = false;
            });

        // update user
        builder
            .addCase(apiUpdateAccountUser.pending, (state) => {
                state.isUpdateAccountUserLoading = true;
            })
            .addCase(apiUpdateAccountUser.fulfilled, (state) => {
                state.isUpdateAccountUserLoading = false;
            })
            .addCase(apiUpdateAccountUser.rejected, (state) => {
                state.isUpdateAccountUserLoading = false;
            });
    },
});

export default accountSlice.reducer;