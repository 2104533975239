import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import { COMMON_PATH } from '@utils';

const NoAuth = () => {
    const { auth } = useAuth()
    const to = COMMON_PATH.HOME

    return (
        auth?.session
            ? <Navigate to={to} replace />
            : <Outlet />
    )
}

export default NoAuth