import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import UserAvatar from '@pages/common/chat/components/UserAvatar'

// for future use
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

import { useGetChatProfileQuery } from '@store/feature/chat/chatApi';

const TopBar = ({ profile }) => {
    const { data: currentLoggedInUser } = useGetChatProfileQuery();

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                p: '8px 10px',
                borderBottom: '2px solid #ccc',
            }}
        >
            <UserAvatar
                imgSource={profile?.image_url}
                fullName={profile?.fullname}
                isActive={profile?.status === 'active' || profile?.uuid === currentLoggedInUser?.uuid}
            />
            <Typography
                variant='body2'
                sx={{
                    color: '#000',
                    fontWeight: 'medium',
                    padding: '0',
                    margin: '0',
                }}
            >
                {profile?.fullname}
            </Typography>
            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                }}
            >
                <IconButton>
                    <LocalPhoneOutlinedIcon />
                </IconButton>
                <IconButton>
                    <VideocamOutlinedIcon />
                </IconButton>
            </Box> */}
        </Box>
    )
}

export default TopBar