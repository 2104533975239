import React from 'react';
import UserAvatar from './UserAvatar';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

export const TickMemberCard = ({
    imageURL = 'logo192.png',
    userId = 'defaultUserId',
    name = 'Anonymous',
    isActive = false,
    isChecked = false,
    onCheckboxChange
}) => {

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    const handleCheckboxChange = (event) => {
        onCheckboxChange(userId, event.target.checked);
    };

    return (
        <div className={`flex justify-between gap-1 my-2`}>
            <div className='ml-2 pl-4'>
                <UserAvatar
                    fullName={name}
                    imgSource={imageURL}
                    isActive={isActive}
                />
            </div>
            {!isSmallScreen && <div className="truncate w-[140px] pt-[11px] text-sm font-semibold ml-[2px] flex flex-wrap">{name}</div>}
            <div >
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
            </div>
        </div>
    );
};

TickMemberCard.propTypes = {
    imageURL: PropTypes.string,
    userId: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
    messageCount: PropTypes.number
};

TickMemberCard.defaultProps = {
    userId: '',
    name: '',
    message: '',
    time: '',
    messageCount: 0,
    isActive: true
};
