import React, { useEffect, useState, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { RxCross2 } from "react-icons/rx";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";




const ImageModal = ({
    isOpen,
    onClose,
    imageUrl,
    sharedMedia,
    handleImageClick
}) => {
    const [currentStartIndex, setCurrentStartIndex] = useState(0);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const imagesPerPage = 5;

    // Sync the selected image with the imageUrl passed as props
    useEffect(() => {
        const index = sharedMedia.findIndex(
            (media) => `/chat-media/${media.file_url}` === imageUrl
        );
        if (index !== -1) {
            setSelectedImageIndex(index);
        }
    }, [imageUrl, sharedMedia]);

    const handleNext = useCallback(() => {
        if (selectedImageIndex < sharedMedia.length - 1) {
            const nextIndex = selectedImageIndex + 1;
            setSelectedImageIndex(nextIndex);
            handleImageClick(`/chat-media/${sharedMedia[nextIndex].file_url}`);

            // If the next index is beyond the current view range, increase the start index
            if (nextIndex >= currentStartIndex + imagesPerPage) {
                setCurrentStartIndex((prevIndex) => prevIndex + imagesPerPage);
            }
        }
    }, [
        selectedImageIndex,
        sharedMedia,
        handleImageClick,
        currentStartIndex,
        imagesPerPage
    ]);

    const handlePrevious = useCallback(() => {
        if (selectedImageIndex > 0) {
            const prevIndex = selectedImageIndex - 1;
            setSelectedImageIndex(prevIndex);
            handleImageClick(`/chat-media/${sharedMedia[prevIndex].file_url}`);

            // If the previous index is before the current view range, decrease the start index
            if (prevIndex < currentStartIndex) {
                setCurrentStartIndex((prevIndex) => prevIndex - imagesPerPage);
            }
        }
    }, [
        selectedImageIndex,
        sharedMedia,
        handleImageClick,
        currentStartIndex,
        imagesPerPage
    ]);

    const handleClose = useCallback(() => {
        if (isOpen) {
            setSelectedImageIndex(0);
            setCurrentStartIndex(0);
            onClose();
        }
    }, [isOpen, onClose]);

    // Add keyboard event listeners when the modal is open
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isOpen) {
                if (event.key === 'ArrowRight') {
                    handleNext();
                } else if (event.key === 'ArrowLeft') {
                    handlePrevious();
                } else if (event.key === 'Escape') {
                    handleClose();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isOpen, handleNext, handlePrevious, handleClose]);

    const handleThumbnailClick = (url, index) => {
        setSelectedImageIndex(index);
        handleImageClick(url);
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="image-viewer"
            aria-describedby="modal-to-view-image"
            sx={{
                zIndex: 100000,
                backdropFilter: 'blur(5px)',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    background: 'linear-gradient(135deg, #1c1e21 0%, #23272a 100%)',                    
                    boxShadow: 24,
                    p: 4,
                    // border: '2px solid #000',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    outline: 'none',
                    borderRadius: '12px',
                }}
            >
                {/* Close button */}
                <button
                    className="absolute top-0 right-0 w-8 h-8 rounded-full cursor-pointer  text-white flex items-center justify-center"
                    onClick={handleClose}
                    style={{ background: 'linear-gradient(135deg, #1c1e21 0%, #23272a 100%)' ,zIndex: 10 }}

                >
                    <RxCross2 />

                </button>

                {/* Image Display */}
                <div className="w-[600px] h-[400px] ">
                    <img
                        src={
                            sharedMedia[selectedImageIndex]?.file_url
                                ? `/chat-media/${sharedMedia[selectedImageIndex].file_url}`
                                : ''
                        }
                        alt="Selected"
                        style={{
                            width: '600px',
                            height: '400px',
                            objectFit: 'contain'
                        }}
                    />
                </div>

                {/* Next and Previous Buttons */}
                <button
                    className="absolute bottom-1/2 right-0 rounded-full w-22 h-22 bg-gray-500 text-white p-1 font-medium text-2xl ml-4"
                    onClick={handleNext}
                    style={{ background: 'linear-gradient(135deg, #1c1e21 0%, #23272a 100%)' ,zIndex: 10 }}
                >
<BsArrowRightCircleFill />
</button>
                <button
                    className="absolute bottom-1/2 left-0 bg-gray-500 rounded-full w-22 h-22 text-white p-1 font-medium text-2xl mr-4"
                    onClick={handlePrevious}
                    style={{ background: 'linear-gradient(135deg, #1c1e21 0%, #23272a 100%)' ,zIndex: 10 }}
                >
<BsArrowLeftCircleFill />
</button>

                {/* Thumbnail Images */}
                <div className="flex justify-center gap-6 mt-4 ">
                    {sharedMedia
                        .slice(
                            currentStartIndex,
                            currentStartIndex + imagesPerPage
                        )
                        .map((media, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    borderRadius: '8px',
                                    // border: '1px solid #E0E0E0',
                                    padding: '4px',
                                    cursor: 'pointer'
                                }}
                            >
                                <img
                                    src={`/chat-media/${media.file_url}`}
                                    alt="preview"
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        objectFit: 'cover',
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                        border:
                                            currentStartIndex + index ===
                                            selectedImageIndex
                                                ? '2px solid blue'
                                                : '1px solid #E0E0E0'
                                    }}
                                    onClick={() =>
                                        handleThumbnailClick(
                                            `/chat-media/${media.file_url}`,
                                            currentStartIndex + index
                                        )
                                    }
                                />
                            </Box>
                        ))}
                </div>
            </Box>
        </Modal>
    );
};

export default ImageModal;
