import React, { forwardRef, useEffect, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { apiBuyIntraday } from '@store';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';

const Buy = forwardRef(({ handleClose, selectedContract, user }, ref) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        market: 'NCG',
        type: 'buy',
        price_selected: '',
        seller: '',
        buyer: user?.CompanyShort,
        volume: '',
        ordertype: 'internal',
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resp = await dispatch(apiBuyIntraday(state))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Purchase order succesfully saved')
            handleClose()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload)
        }
    }

    // timer
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (ref.current) {
            setTimer(60);
        }
    }, [ref]);

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            timer={timer}
            modalTitle={`Buy Contract`}
            buttonText={'Buy'}
            disabled={state.volume ? false : true}
            ref={ref}
        >
            <Box className='flex flex-col gap-3'>
                <Box className='flex gap-3'>
                    <Typography>Contract:</Typography>
                    <Typography color={'black'}>{selectedContract?.Contract}</Typography>
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Max Volume User Can Buy:</Typography>
                    <Typography color={'black'}>{selectedContract?.Quantity}</Typography>
                </Box>
                <Box className='flex gap-3'>
                    <Typography>Price:</Typography>
                    <Typography color={'black'}>{selectedContract?.Price}</Typography>
                </Box>
                {/* <Box className='flex gap-3'>
                    <Typography>Settlement Price:</Typography>
                    <Typography color={'black'}>{selectedContract?.Price * state?.volume || 0}</Typography>
                </Box> */}
                <FormControl fullWidth variant="outlined">
                    <InputLabel
                        id="contractLabel"
                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                    >
                        Volume
                    </InputLabel>
                    <OutlinedInput
                        id="volume"
                        label="Volume"
                        name="volume"
                        onChange={handleChange}
                        value={state.volume}
                        type="number"
                        inputProps={{ step: 0.01 }}
                        required
                        endAdornment={
                            <InputAdornment position="start">
                                MW
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Seller
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Seller"
                        name='seller'
                        value={state.seller}
                        onChange={handleChange}
                    >
                        {user?.CanBuyFrom?.map((seller, index) => (
                            <MenuItem key={index} value={seller}>{seller}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Delivery point
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Delivery point"
                    >
                        <MenuItem value='N/A'>N/A</MenuItem>
                    </Select>
                </FormControl> */}
            </Box>
        </CustomModal>
    )
})

export default Buy