import { Box, ListItem, ListItemButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import UserAvatar from '@pages/common/chat/components/UserAvatar';

export const ChatCardOverlay = ({
    imageURL,
    userId,
    name,
    message,
    time,
    messageCount,
    isActive,
    handleGoToSingleChat
}) => {
    if (messageCount > 9) {
        messageCount = '9+';
    }

    const onClick = () => {
        handleGoToSingleChat(userId);
    };

    return (
        <ListItem disablePadding onClick={onClick}>
            <ListItemButton
                sx={{
                    display: 'flex',
                    flexGrow: '1',
                    py: '12px',
                    px: '16px'
                }}
            >
                <UserAvatar
                    fullName={name}
                    imgSource={imageURL}
                    isActive={isActive}
                />

                <Box
                    sx={{
                        flexGrow: 1,
                        ml: '8px',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 'medium',
                            maxWidth: '120px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '0.75rem',
                            maxWidth: '124px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {message || `Say hi!`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                    }}
                >
                    <Typography sx={{ fontSize: '0.6rem' }}>
                        {/* Unix time to local time */}
                        {time !== 0 && (
                            <>
                                {new Date(
                                    parseInt(time) * 1000
                                ).toLocaleTimeString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </>
                        )}
                    </Typography>
                    <Box
                        sx={{
                            height: '18px',
                            width: messageCount >= 1 ? '24px' : '18px',
                            bgcolor:
                                messageCount >= 1 ? 'green' : 'transparent',
                            borderRadius: '16px',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {messageCount > 0 && (
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '0.7rem',
                                    lineHeight: '32px',
                                    margin: '0',
                                    marginTop: '1.5px',
                                    marginRight: '1px',
                                    padding: '0'
                                }}
                            >
                                {messageCount}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

ChatCardOverlay.propTypes = {
    imageURL: PropTypes.string,
    userId: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
    messageCount: PropTypes.number
};

ChatCardOverlay.defaultProps = {
    userId: '',
    name: '',
    message: '',
    time: '',
    messageCount: 0,
    isActive: true
};
