import React, { forwardRef, useState } from 'react'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import { CustomModal } from '@components'
import ReactFileReader from 'react-file-reader';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDispatch } from 'react-redux';
import { apiEditNews, apiGetNews } from '@store';
import { showToast } from '@utils';

const Edit = forwardRef(({ handleClose, singleNews }, ref) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        uuid: singleNews?.uuid,
        headline: singleNews?.headline || '',
        postFile: '',
        text: singleNews?.text || '',
        postUrl: singleNews?.location || '',
        isNewFile: false
    })

    const handleFiles = (files) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            setState({
                ...state,
                postFile: files[0],
                postUrl: e.target.result,
                isNewFile: true
            })
        }
        reader.readAsDataURL(files[0]);
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('UUID', state?.uuid)
        formData.append('Headline', state?.headline)
        formData.append('Text', state?.text)
        formData.append('postFile', state?.postFile)

        const resp = await dispatch(apiEditNews(formData))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetNews())
            showToast('success', 'Post updated successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload)
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Edit post`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box>
                <FormControl fullWidth>
                    <TextField
                        label="Headline"
                        name="headline"
                        value={state.headline}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </FormControl>
                <Box className='border-dashed border-2 border-[#C0C0C0] mt-2 mb-3'>
                    <ReactFileReader handleFiles={handleFiles} fileTypes={'.png,.jpg,.jpeg'} >
                        {state?.postUrl ?
                            <Box className='relative cursor-pointer'>
                                <img src={state.isNewFile ? state.postUrl : `/media/${state.postUrl}`} alt={state.headline} className='object-cover h-80 w-full relative' />
                                <Box className='absolute top-0 right-0 mt-1 mr-1 bg-white p-1 rounded-full cursor-pointer' >
                                    <FileUploadIcon
                                        sx={{ zIndex: 100 }}
                                        titleAccess='Upload another image'
                                    />
                                </Box>
                            </Box>
                            :
                            <>
                                <Box className='flex flex-col items-center justify-center h-80'>
                                    <FileUploadIcon fontSize='large' />
                                    <Typography caption='caption'>Upload Image</Typography>
                                </Box>
                            </>
                        }
                    </ReactFileReader>
                </Box>
                <TextField
                    id="outlined-multiline-static"
                    label="Content"
                    variant="outlined"
                    name="text"
                    value={state.text}
                    onChange={handleChange}
                    rows={4}
                    multiline
                    fullWidth
                />
            </Box>
        </CustomModal>
    )
})

export default Edit