import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiCreateMarketBidAsk, apiGetMarketplace } from '@store'
import { showToast } from '@utils'

const CreateTrade = forwardRef(({ handleClose, contracts }, ref) => {
    const dispatch = useDispatch()
    const initialState = {
        contract: '',
        price: '',
        quantity: '',
        ttl: '',
        type: '',
        location: '',
        currency: '',
        option: ''
    }

    const [state, setState] = useState(initialState)

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            ...state,
            price: parseFloat(state.price),
            quantity: parseFloat(state.quantity),
            ttl: parseFloat(state.ttl)
        }

        const resp = await dispatch(apiCreateMarketBidAsk(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Trade created successfully')
            dispatch(apiGetMarketplace())
            handleClose()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Failed to create trade')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Create New Trade`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box className='flex gap-3'>
                <Box className='flex flex-col gap-2'>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Contract</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Contract"
                            name='contract'
                            onChange={handleChange}
                            value={state?.contract}
                        >
                            {contracts?.map((name, index) => (
                                <MenuItem key={index} value={name}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            id="priceLabel"
                        >
                            Price
                        </InputLabel>
                        <OutlinedInput
                            id="price"
                            label="Price"
                            name='price'
                            type='number'
                            onChange={handleChange}
                            value={state.price}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Bid/Ask</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="bid/ask"
                            name='type'
                            onChange={handleChange}
                            value={state.type}
                        >
                            <MenuItem value='bid'>Bid</MenuItem>
                            <MenuItem value='ask'>Ask</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="bid/ask"
                            name='option'
                            onChange={handleChange}
                            value={state.option}
                        >
                            <MenuItem value='AON'>All Or None</MenuItem>
                            <MenuItem value='POK'>Partial Fill Ok</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex flex-col gap-2'>
                    <FormControl>
                        <InputLabel
                            id="maxVolumeLabel"
                        >
                            Max Volume [MWh]
                        </InputLabel>
                        <OutlinedInput
                            id="max_volume"
                            label="Max Volume [MWh]"
                            name='quantity'
                            type='number'
                            onChange={handleChange}
                            value={state.quantity}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            id="maxVolumeLabel"
                        >
                            TTL (Time To Live) In Minutes
                        </InputLabel>
                        <OutlinedInput
                            id="ttl"
                            label="TTL (Time To Live) In Minutes"
                            name='ttl'
                            type='number'
                            onChange={handleChange}
                            value={state.ttl}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="location"
                            name='location'
                            onChange={handleChange}
                            value={state.location}
                        >
                            <MenuItem value='SEA'>SEA</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="currency"
                            name='currency'
                            onChange={handleChange}
                            value={state.currency}
                        >
                            <MenuItem value='EUR'>EUR</MenuItem>
                            <MenuItem value='CHF'>CHF</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default CreateTrade