import React, { forwardRef } from 'react';
import { CustomModal } from '@components';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useBlockChatUserMutation } from '@store/feature/chat/chatApi';

const BlockModal = forwardRef(({ handleClose }, ref) => {
    const { id } = useParams();

    const [blockChatUser] = useBlockChatUserMutation();

    const handleSubmit = (e) => {
        e.preventDefault();
        blockChatUser(id);
        handleClose();
    };

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Block the contact`}
            buttonText={'Block'}
            modalTitleColor={'#EB6969'}
            className="modal-button-danger"
            ref={ref}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'black'
                }}
            >
                {/* <Typography
                    sx={{
                        fontSize: '14px'
                    }}
                >
                    Are you sure you want to block this contact?
                </Typography> */}

                <Typography
                    sx={{
                        fontSize: '14px'
                    }}
                >
                    Blocked contacts will no longer be able to call you or send
                    you messages.
                </Typography>
            </Box>
        </CustomModal>
    );
});

export default BlockModal;
