import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const AddMemberSearch = ({ handleChange }) => {
    return (
        <Box
            sx={{
                px: '16px',
                position: 'sticky',
                top: '0',
                pb: '8px',

            }}
        >
            <TextField
            sx={{
                width:"380px"

            }}
                label="Search by name"
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                        </InputAdornment>
                    )
                }}
                onChange={handleChange}
            />
        </Box>
    );
};

export default AddMemberSearch;
