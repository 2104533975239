import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    pegnordEur: [],
    isGetPegnordEurLoading: false,
};

export const apiGetPegnordEur = createAsyncThunk(
    "pegnord/getPegnord",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getpegnorddata');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const pegnordSlice = createSlice({
    name: "pegnord",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(apiGetPegnordEur.pending, (state) => {
                state.isGetPegnordEurLoading = true;
            })
            .addCase(apiGetPegnordEur.fulfilled, (state, action) => {
                state.isGetPegnordEurLoading = false;
                state.pegnordEur = action.payload.data;
            })
            .addCase(apiGetPegnordEur.rejected, (state) => {
                state.isGetPegnordEurLoading = false;
            });
    },
});

export default pegnordSlice.reducer;