import { styled } from '@mui/material';
import React from 'react'
import RevoCalendar from "revo-calendar";

const StyledCalendar = styled(RevoCalendar)({})

const CalendarComponent = ({ events }) => {
    const eventList = events?.map((event, index) => {
        return {
            name: event.Headline,
            date: new Date(event.Date).getTime(),
            // allDay: event.allDay,
            extra: {
                text: event.Display
            }
        }
    })

    return (
        <StyledCalendar
            events={eventList}
            primaryColor="#216583"
            secondaryColor="#fff"
            todayColor="#000"
            textColor="#333333"
            lang="en"
            indicatorColor="red"
            animationSpeed={300}
            sidebarWidth={180}
            detailWidth={280}
            showDetailToggler={true}
            showSidebarToggler={true}
            onePanelAtATime={false}
            openDetailsOnDateSelection={true}
            showAllDayLabel={false}
            detailDateFormat="MMM DD, YYYY"
            className="revo-calendar"
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                zIndex: 1,
                borderRadius: "5px",
                "& div[role=button]": {
                    background: '#216583',
                    border: 'none',
                    color: 'white',
                    "&:hover": {
                        background: 'white',
                        color: '#216583',
                        transition: 'all 0.1s ease-in-out'
                    }
                },
                "& div[role=button] p": {
                    color: "inherit",
                    fontWeight: 'bold',
                },
                "& div[role=button] div:first-of-type div:first-of-type": {
                    display: "none"
                },
                "& div[role=button] div:first-of-type div:nth-of-type(2) svg": {
                    display: "none"
                },
                "& div[role=button] div:first-of-type div:nth-of-type(2) span": {
                    fontWeight: "normal",
                },
            }}
        />
    )
}

export default CalendarComponent