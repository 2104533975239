import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiCreateBiogasMarketBidAsk, apiGetBiogas } from '@store'
import { showToast } from '@utils'

const productOptions = [
    { value: 'GOBiogas', label: 'GO Biogas' },
    { value: 'GOBiogas+gas', label: 'GO Biogas + Gas' },
    { value: 'GOBiogas+poi', label: 'GO Biogas + POI' },
]

const subsidyOptions = [
    { value: 'subsidy-sde', label: 'Subsidy (sde)' },
    { value: 'no-subsidy', label: 'No Subsidy (no sde)' },
]

const originOptions = [
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'France', label: 'France' },
    { value: 'UK', label: 'UK' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Chech Republic', label: 'Chech Republic' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'European Mix', label: 'European Mix' },
]

const biogasRegisterOptions = [
    { value: 'Energinet-G-Rex', label: 'Energinet-G-Rex' },
    { value: 'VertiCer', label: 'VertiCer' },
    { value: 'GGCS', label: 'GGCS' },
    { value: 'Dena', label: 'Dena' },
    { value: 'Any', label: 'Any' },
]

const qualityOptions = [
    { value: 'OZD-positive-list', label: 'OZD positive list' },
    { value: 'ISCC-REDcert', label: 'ISCC/REDcert' },
    { value: 'GEG-EEWärmeG', label: 'GEG/EEWärmeG' },
    { value: 'No-quality', label: 'No quality' },
]

const certificationOptions = [
    { value: 'Naturemade star', label: 'Naturemade star' },
    { value: 'ISCC/REDcert', label: 'ISCC/REDcert' },
    { value: 'Uncertified', label: 'Uncertified' },
]

const feedstockOptions = [
    { value: 'Waste-residues', label: 'Waste/Residues' },
    { value: 'crop', label: 'Crop' },
    { value: 'mixed-feedstock', label: 'Mixed Feedstock' },
    { value: 'no-specific-quality', label: 'No Specific Quality' },
]

const deliveryPeriodOptions = [
    { value: '2024', label: '2024' },
    { value: '1H-2024', label: '1H 2024' },
    { value: '2H-2024', label: '2H 2024' },
    { value: '2025', label: '2025' },
    { value: '1H-2025', label: '1H 2025' },
    { value: '2H-2025', label: '2H 2025' },
    { value: '2026', label: '2026' },
    { value: '1H-2026', label: '1H 2026' },
    { value: '2H-2026', label: '2H 2026' },
]

const CreateTrade = forwardRef(({ handleClose, contracts }, ref) => {
    const dispatch = useDispatch()
    const initialState = {
        product: '',
        origin: '',
        subsidy: '',
        certification: '',
        price_str: '',
        volume_str: '',
        register: '',
        quality: '',
        delivery_period: '',
        feedstock: ''
    }

    const [state, setState] = useState(initialState)

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const resp = await dispatch(apiCreateBiogasMarketBidAsk(state))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetBiogas())
            showToast('success', 'Trade created successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Failed to create trade')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Create New Trade`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box className='flex gap-3'>
                <Box className='flex flex-col gap-3'>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Product</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Product"
                            name='product'
                            onChange={handleChange}
                            value={state.product}
                        >
                            {productOptions?.map((product, index) => (
                                <MenuItem key={index} value={product.value}>{product.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Subsidy</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Subsidy"
                            name='subsidy'
                            onChange={handleChange}
                            value={state.subsidy}
                        >
                            {subsidyOptions?.map((subsidy, index) => (
                                <MenuItem key={index} value={subsidy.value}>{subsidy.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">BioGas Register Delivery</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="BioGas Register Delivery"
                            name='register'
                            onChange={handleChange}
                            value={state.register}
                        >
                            {biogasRegisterOptions?.map((biogas, index) => (
                                <MenuItem key={index} value={biogas.value}>{biogas.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Quality</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Quality"
                            name='quality'
                            onChange={handleChange}
                            value={state.quality}
                        >
                            {qualityOptions?.map((quality, index) => (
                                <MenuItem key={index} value={quality.value}>{quality.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            id="maxVolumeLabel"
                        >
                            Volume [MWh]
                        </InputLabel>
                        <OutlinedInput
                            id="volume"
                            label="Max Volume [MWh]"
                            name='volume_str'
                            type='number'
                            onChange={handleChange}
                            value={state.volume_str}
                        />
                    </FormControl>
                </Box>
                <Box className='flex flex-col gap-3'>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Origin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Origin"
                            name='origin'
                            onChange={handleChange}
                            value={state.origin}
                        >
                            {originOptions?.map((origin, index) => (
                                <MenuItem key={index} value={origin.value}>{origin.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Certification</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Certification"
                            name='certification'
                            onChange={handleChange}
                            value={state.certification}
                        >
                            {certificationOptions?.map((certification, index) => (
                                <MenuItem key={index} value={certification.value}>{certification.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Feedstock</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Feedstock"
                            name='feedstock'
                            onChange={handleChange}
                            value={state.feedstock}
                        >
                            {feedstockOptions?.map((feedstock, index) => (
                                <MenuItem key={index} value={feedstock.value}>{feedstock.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Delivery Period</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Delivery Period"
                            name='delivery_period'
                            onChange={handleChange}
                            value={state.delivery_period}
                        >
                            {deliveryPeriodOptions?.map((deliveryPeriod, index) => (
                                <MenuItem key={index} value={deliveryPeriod.value}>{deliveryPeriod.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            id="maxVolumeLabel"
                        >
                            Price
                        </InputLabel>
                        <OutlinedInput
                            id="price"
                            label="Price"
                            name='price_str'
                            type='number'
                            onChange={handleChange}
                            value={state.price_str}
                        />
                    </FormControl>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default CreateTrade