import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material'


import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDispatch } from 'react-redux';
import { apiCreateAccount, apiGetAccount } from '@store'
import { showToast } from '@utils';

const emailTooltip = `General email where trade confirmations will be sent along with the user who did that trade.`
const marginTooltip = `Margin to add on all products. Negative number will be deduced. Margin will apply to all products.`
const emailFooterTooltip = `Footer that appears on your trade confirmation email.`

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateAccount = forwardRef(({ endpoint, handleClose }, ref) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        company_name: '',
        email: '',
        margin: '',
        country: '',
        address: '',
        email_footer: '',
        endpoints: [],
        starttime: new Date(),
        endtime: new Date(),
        lunchstart: new Date(),
        lunchend: new Date(),
    })

    const [endpoints, setEndpoints] = useState([])

    const handleChangeMultiple = (event) => {
        const { target: { value }, } = event;
        setEndpoints(typeof value === 'string' ? value.split(',') : value)
        let endpoint = value.map((endpoint) => {
            return endpoint
        })
        setState({ ...state, endpoints: endpoint })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const starttime = dayjs(state.starttime).format('HH.mm')
        const endtime = dayjs(state.endtime).format('HH.mm')
        const lunchstart = dayjs(state.lunchstart).format('HH.mm')
        const lunchend = dayjs(state.lunchend).format('HH.mm')
        const margin = parseFloat(state.margin)

        const data = { ...state, starttime: starttime, endtime: endtime, lunchstart: lunchstart, lunchend: lunchend, margin: margin }

        const response = await dispatch(apiCreateAccount(data))
        if (response?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Account Created Successfully')
            dispatch(apiGetAccount())
            handleClose()
        } else if (response?.meta?.requestStatus === 'rejected') {
            showToast('error', response?.payload || 'Failed to create account')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Create New Account`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box className='flex gap-3 flex-col'>
                <Box className='flex gap-3'>
                    <Box className='flex flex-col gap-3'>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="companyNameLabel"
                                sx={{ bgcolor: '#F5F5F5' }}
                            >
                                Company Name
                            </InputLabel>
                            <OutlinedInput
                                id="companyName"
                                label="companyNameLabel"
                                name='company_name'
                                onChange={handleChange}
                                value={state.company_name}
                            />
                        </FormControl>
                        <Tooltip
                            title={marginTooltip}
                            arrow
                        >
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel
                                    id="marginLabel"
                                    sx={{ bgcolor: '#F5F5F5' }}
                                >
                                    Margin
                                </InputLabel>
                                <OutlinedInput
                                    id="margin"
                                    label="marginLabel"
                                    name='margin'
                                    type='number'
                                    onChange={handleChange}
                                    value={state.margin}
                                    inputProps={{ step: 0.01 }}
                                />
                            </FormControl>
                        </Tooltip>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="addressLabel"
                                sx={{ bgcolor: '#F5F5F5' }}
                            >
                                Address
                            </InputLabel>
                            <OutlinedInput
                                id="address"
                                label="addressLabel"
                                name='address'
                                onChange={handleChange}
                                value={state.address}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-multiple-name-label">Endpoints</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={endpoints}
                                onChange={handleChangeMultiple}
                                input={<OutlinedInput id="select-multiple-chip" label="Endpoints" />}
                                MenuProps={MenuProps}
                            >
                                {endpoint?.map((endpoint) => (
                                    <MenuItem key={endpoint.uuid} value={endpoint}>
                                        {endpoint.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='flex flex-col gap-3'>
                        <Tooltip
                            title={emailTooltip}
                            arrow
                        >
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel
                                    id="emailLabel"
                                    sx={{ bgcolor: '#F5F5F5' }}
                                >
                                    Email
                                </InputLabel>
                                <OutlinedInput
                                    id="email"
                                    label="emailLabel"
                                    name='email'
                                    onChange={handleChange}
                                    value={state.email}
                                />
                            </FormControl>
                        </Tooltip>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="countryLabel"
                                sx={{ bgcolor: '#F5F5F5' }}
                            >
                                Country
                            </InputLabel>
                            <OutlinedInput
                                id="country"
                                label="countryLabel"
                                name='country'
                                onChange={handleChange}
                                value={state.country}
                            />
                        </FormControl>
                        <Tooltip
                            title={emailFooterTooltip}
                            arrow
                        >
                            <FormControl
                                fullWidth={true}
                                variant="outlined"
                            >
                                <InputLabel
                                    id="email_footerLabel"
                                    sx={{
                                        bgcolor: '#F5F5F5',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '5px'
                                    }}
                                >
                                    Email Footer
                                </InputLabel>
                                <OutlinedInput
                                    id="email_footer"
                                    label="email_footerLabel"
                                    name='email_footer'
                                    onChange={handleChange}
                                    value={state.email_footer}
                                />
                            </FormControl>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className='flex gap-3'>
                    <Box className='flex flex-col gap-3 w-[230px]'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Trading Start"
                                name='starttime'
                                value={dayjs(state?.starttime)}
                                onChange={(newValue) => setState({ ...state, starttime: newValue })}
                            />
                            <TimePicker
                                label="Break Start"
                                name='lunchstart'
                                value={dayjs(state?.lunchstart)}
                                onChange={(newValue) => setState({ ...state, lunchstart: newValue })}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='flex flex-col gap-3 w-[230px]'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Trading End"
                                name='endtime'
                                value={dayjs(state?.endtime)}
                                onChange={(newValue) => setState({ ...state, endtime: newValue })}
                            />
                            <TimePicker
                                label="Break End"
                                name='lunchend'
                                value={dayjs(state?.lunchend)}
                                onChange={(newValue) => setState({ ...state, lunchend: newValue })}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default CreateAccount