import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { CustomModal, ReactTable } from '@components'
import { Box, Typography } from '@mui/material';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { apiBuySetPriceProfile } from '@store';
import { showToast } from '@utils';
import { useDispatch } from 'react-redux';

const Buy = forwardRef(({ open, handleClose, singlePriceProfile, eur }, ref) => {
    const dispatch = useDispatch();

    const [timer, setTimer] = useState(20);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (open) {
            setTimer(20);
        }
    }, [open]);

    const sortedData = useMemo(() => {
        if (singlePriceProfile?.data) {
            const contract = [];
            eur?.symbols?.forEach((symbol) => {
                singlePriceProfile?.data?.forEach((data) => {
                    if (data.name === symbol) {
                        contract.push(data)
                    }
                })
            })

            return contract;
        }
        return [];
    }, [singlePriceProfile, eur]);


    const columns = useMemo(
        () => [
            {
                header: "Contract",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume [MWh/h]",
                id: "volume",
                accessorFn: ({ volume }) => String(volume)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Total Volume [MWh]",
                id: "mwh",
                accessorFn: ({ mwh }) => String(mwh)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Price",
                id: "askprice",
                accessorFn: ({ askprice }) => askprice?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Total",
                id: "asktotal",
                accessorFn: ({ asktotal }) => asktotal?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    const handleBuyProfile = async (e) => {
        e.preventDefault();
        if (window.confirm(`Are you sure you want to buy ${singlePriceProfile.name} profile?`)) {
            const resp = await dispatch(apiBuySetPriceProfile(singlePriceProfile));
            if (resp?.meta?.requestStatus === 'fulfilled') {
                handleClose();
                showToast('success', 'Profile bought successfully');
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload || 'Failed to buy profile');
            }
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleBuyProfile}
            modalTitle={`Modal will be closed in ${timer} seconds`}
            buttonText={'Buy'}
            ref={ref}
        >
            <Box>
                <ReactTable
                    renderToolbarInternalActions={
                        ({ table }) => (
                            <div onMouseDown={(e) => e.stopPropagation()}>
                                <MRT_ToggleGlobalFilterButton table={table} />
                            </div>
                        )
                    }
                    headerText={'Exchanges'}
                    columns={columns || []}
                    data={sortedData || []}
                />
            </Box>
            <Box className='flex gap-2 mt-4'>
                <Box>
                    <Typography sx={{ color: '#000' }}>Currency</Typography>
                    <Typography sx={{ color: '#000' }}>Total MWh</Typography>
                    <Typography sx={{ color: '#000' }}>Total Price</Typography>
                    <Typography sx={{ color: '#000' }}>Profile Price</Typography>
                </Box>
                <Box>
                    <Typography sx={{ textTransform: 'uppercase' }}>: {singlePriceProfile.currency}</Typography>
                    <Typography>: {singlePriceProfile.totalmwh}</Typography>
                    <Typography>: {(singlePriceProfile.totalprofileprice).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                    <Typography>: {(singlePriceProfile.profileprice).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default Buy