import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    limitOrder: [],
    isGetLimitOrderLoading: false,
    trailingStop: [],
    isGetTrailingStopLoading: false,
    isActivateDeactivateTrailingStopLoading: false,
    isDeleteTrailingStopLoading: false,
    minMaxRaw: [],
    isGetMinMaxRawLoading: false,
    isSetLimitOrderLoading: false,
    isDeleteLimitOrderLoading: false
}

export const apiGetLimitOrder = createAsyncThunk(
    "notification/getLimitOrder",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getlimitorders?provider=set&market=all');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetTrailingStop = createAsyncThunk(
    "notification/getTrailingStop",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getTrailingStop');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteTrailingStop = createAsyncThunk(
    "notification/deleteTrailingStop",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/deleteTrailingStop', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiActivateDeactivateTrailingStop = createAsyncThunk(
    "notification/activateDeactivateTrailingStop",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/updateTrailingStop', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetMinMaxRaw = createAsyncThunk(
    "notification/getMinMaxRaw",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getMinMaxRaw');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteMinMaxOrder = createAsyncThunk(
    "notification/deleteMinMaxOrder",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/deleteMinMaxOrder', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiSetLimitOrder = createAsyncThunk(
    "notification/setLimitOrder",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/setlimitorders', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteLimitOrder = createAsyncThunk(
    "notification/deleteLimitOrder",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/deletelimitorders', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get limit order
        builder
            .addCase(apiGetLimitOrder.pending, (state) => {
                state.isGetLimitOrderLoading = true;
            })
            .addCase(apiGetLimitOrder.fulfilled, (state, action) => {
                state.limitOrder = action.payload.data;
                state.isGetLimitOrderLoading = false;
            })
            .addCase(apiGetLimitOrder.rejected, (state) => {
                state.isGetLimitOrderLoading = false;
            });

        // get trailing stop
        builder
            .addCase(apiGetTrailingStop.pending, (state) => {
                state.isGetTrailingStopLoading = true;
            })
            .addCase(apiGetTrailingStop.fulfilled, (state, action) => {
                state.trailingStop = action.payload.data;
                state.isGetTrailingStopLoading = false;
            })
            .addCase(apiGetTrailingStop.rejected, (state) => {
                state.isGetTrailingStopLoading = false;
            });

        // delete trailing stop
        builder
            .addCase(apiDeleteTrailingStop.pending, (state) => {
                state.isDeleteTrailingStopLoading = true;
            })
            .addCase(apiDeleteTrailingStop.fulfilled, (state) => {
                state.isDeleteTrailingStopLoading = false;
            })
            .addCase(apiDeleteTrailingStop.rejected, (state) => {
                state.isDeleteTrailingStopLoading = false;
            });

        // activate deactivate trailing stop
        builder
            .addCase(apiActivateDeactivateTrailingStop.pending, (state) => {
                state.isActivateDeactivateTrailingStopLoading = true;
            })
            .addCase(apiActivateDeactivateTrailingStop.fulfilled, (state) => {
                state.isActivateDeactivateTrailingStopLoading = false;
            })
            .addCase(apiActivateDeactivateTrailingStop.rejected, (state) => {
                state.isActivateDeactivateTrailingStopLoading = false;
            });

        // get min max raw
        builder
            .addCase(apiGetMinMaxRaw.pending, (state) => {
                state.isGetMinMaxRawLoading = true;
            })
            .addCase(apiGetMinMaxRaw.fulfilled, (state, action) => {
                state.minMaxRaw = action.payload.data;
                state.isGetMinMaxRawLoading = false;
            })
            .addCase(apiGetMinMaxRaw.rejected, (state) => {
                state.isGetMinMaxRawLoading = false;
            });

        // delete min max order
        builder
            .addCase(apiDeleteMinMaxOrder.pending, (state) => {
                state.isDeleteMinMaxOrderLoading = true;
            })
            .addCase(apiDeleteMinMaxOrder.fulfilled, (state) => {
                state.isDeleteMinMaxOrderLoading = false;
            })
            .addCase(apiDeleteMinMaxOrder.rejected, (state) => {
                state.isDeleteMinMaxOrderLoading = false;
            });

        // set limit order
        builder
            .addCase(apiSetLimitOrder.pending, (state) => {
                state.isSetLimitOrderLoading = true;
            })
            .addCase(apiSetLimitOrder.fulfilled, (state) => {
                state.isSetLimitOrderLoading = false;
            })
            .addCase(apiSetLimitOrder.rejected, (state) => {
                state.isSetLimitOrderLoading = false;
            });
        
        // delete limit order
        builder
            .addCase(apiDeleteLimitOrder.pending, (state) => {
                state.isDeleteLimitOrderLoading = true;
            })
            .addCase(apiDeleteLimitOrder.fulfilled, (state) => {
                state.isDeleteLimitOrderLoading = false;
            })
            .addCase(apiDeleteLimitOrder.rejected, (state) => {
                state.isDeleteLimitOrderLoading = false;
            });
    }
});

export default notificationSlice.reducer;