import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    endpoint: [],
    isGetEndpointLoading: false,
    isCreateEndpointLoading: false,
    isDeleteEndpointLoading: false,
};

export const apiGetEndpoint = createAsyncThunk(
    "endpoint/getEndpoint",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/getendpoints');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateEndpoint = createAsyncThunk(
    "endpoint/createEndpoint",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/createendpoint', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteEndpoint = createAsyncThunk(
    "endpoint/deleteEndpoint",
    async (endpoint, thunkAPI) => {
        try {
            const response = await axios.post(`/api/updateendpoint`, endpoint);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const endpointSlice = createSlice({
    name: "endpoint",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Endpoint
        builder
            .addCase(apiGetEndpoint.pending, (state) => {
                state.isGetEndpointLoading = true;
            })
            .addCase(apiGetEndpoint.fulfilled, (state, action) => {
                state.isGetEndpointLoading = false;
                state.endpoint = action.payload.data;
            })
            .addCase(apiGetEndpoint.rejected, (state) => {
                state.isGetEndpointLoading = false;
            });

        // Create Endpoint
        builder
            .addCase(apiCreateEndpoint.pending, (state) => {
                state.isCreateEndpointLoading = true;
            })
            .addCase(apiCreateEndpoint.fulfilled, (state, action) => {
                state.isCreateEndpointLoading = false;
                state.endpoint = action.payload.data;
            })
            .addCase(apiCreateEndpoint.rejected, (state) => {
                state.isCreateEndpointLoading = false;
            });

        // Delete Endpoint
        builder
            .addCase(apiDeleteEndpoint.pending, (state) => {
                state.isDeleteEndpointLoading = true;
            })
            .addCase(apiDeleteEndpoint.fulfilled, (state, action) => {
                state.isDeleteEndpointLoading = false;
                state.endpoint = action.payload.data;
            })
            .addCase(apiDeleteEndpoint.rejected, (state) => {
                state.isDeleteEndpointLoading = false;
            });
    },
});

export default endpointSlice.reducer;