import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';
import { apiUploadRates, apiGetProfilingRates } from '@store';

const UploadRates = forwardRef(({ handleClose, profiling_rates }, ref) => {
    const dispatch = useDispatch();
    const [rates, setRates] = useState(profiling_rates);
    const [newRate, setNewRate] = useState({ year: 0, month: 0, contract: '', volatility_rate: 0, dividend_rate: 0, risk_free_rate: 0 });
    const [editingIndex, setEditingIndex] = useState(-1);
    const [showNewForm, setShowNewForm] = useState(false);

    const handleInputChange = (e, index = null) => {
        const { name, value } = e.target;
        if (index !== null) {
            const updatedRates = rates.map((rate, i) => (i === index ? { ...rate, [name]: value } : rate));
            setRates(updatedRates);
        } else {
            setNewRate({ ...newRate, [name]: value });
        }
    };

    const handleSaveEdit = (index) => {
        setEditingIndex(-1);
    };

    const handleAddRate = () => {
        setRates([...rates, newRate]);
        setNewRate({ year: 0, month: 0, contract: '', volatility_rate: 0, dividend_rate: 0, risk_free_rate: 0 });
        setShowNewForm(false);
    };

    const handleEditRate = (index) => {
        setEditingIndex(index);
    };

    const handleDeleteRate = (index) => {
        const updatedRates = rates.filter((_, i) => i !== index);
        setRates(updatedRates);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = rates.map(rate => ({
            ...rate,
            volatility_rate: parseFloat(rate.volatility_rate),
            dividend_rate: parseFloat(rate.dividend_rate),
            risk_free_rate: parseFloat(rate.risk_free_rate),
        }));

        const resp = await dispatch(apiUploadRates(data));
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Rates uploaded successfully');
            dispatch(apiGetProfilingRates())
            handleClose();
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload);
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle='Upload Rates'
            ref={ref}
        >
            <div>
                {rates?.map((rate, index) => (
                    <Box key={index} display={'flex'} mb={2} alignItems={'center'} gap={2}>
                        <Typography variant='h6'>{index + 1}. </Typography>
                        <Box mb={2} display="flex" alignItems="center">
                            <TextField
                                label="Contract"
                                name="contract"
                                value={rate.contract}
                                onChange={(e) => handleInputChange(e, index)}
                                disabled={editingIndex !== index}
                                style={{ marginRight: 8 }}
                            />
                            <TextField
                                label="Volatility Rate"
                                name="volatility_rate"
                                value={rate.volatility_rate}
                                onChange={(e) => handleInputChange(e, index)}
                                disabled={editingIndex !== index}
                                style={{ marginRight: 8 }}
                            />
                            <TextField
                                label="Dividend Rate"
                                name="dividend_rate"
                                value={rate.dividend_rate}
                                onChange={(e) => handleInputChange(e, index)}
                                disabled={editingIndex !== index}
                                style={{ marginRight: 8 }}
                            />
                            <TextField
                                label="Risk Free Rate"
                                name="risk_free_rate"
                                value={rate.risk_free_rate}
                                onChange={(e) => handleInputChange(e, index)}
                                disabled={editingIndex !== index}
                                style={{ marginRight: 8 }}
                            />
                            {editingIndex === index ? (
                                <IconButton
                                    onClick={() => handleSaveEdit(index)}
                                    sx={{ color: '#27A9FF' }}
                                >
                                    <SaveIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() => handleEditRate(index)}
                                    sx={{ color: '#27A9FF' }}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={() => handleDeleteRate(index)}
                                sx={{ color: 'brown' }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
                <Box mt={2}>
                    {!showNewForm && (
                        <Button
                            onClick={() => setShowNewForm(true)}
                            variant='outlined'
                            color='primary'
                            sx={{
                                borderColor: '1px solid #27A9FF',
                                color: '#27A9FF',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: '1px solid #27A9FF',
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                },
                            }}
                            startIcon={<AddIcon />}
                        >
                            Add New Profiling Rate
                        </Button>
                    )}
                    {showNewForm && (
                        <Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap={2}
                                mb={2}
                            >
                                <TextField
                                    label="Contract"
                                    name="contract"
                                    type='text'
                                    value={newRate.contract}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    label="Volatility Rate"
                                    name="volatility_rate"
                                    type='number'
                                    value={newRate.volatility_rate}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    label="Dividend Rate"
                                    name="dividend_rate"
                                    type='number'
                                    value={newRate.dividend_rate}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    label="Risk Free Rate"
                                    name="risk_free_rate"
                                    type='number'
                                    value={newRate.risk_free_rate}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Box>
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        color: 'white',
                                        backgroundColor: '#27A9FF',
                                    },
                                }}
                                onClick={handleAddRate}
                            >
                                Add Rate
                            </Button>
                        </Box>
                    )}
                </Box>
            </div>
        </CustomModal>
    )
})

export default UploadRates