import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import EditUsers from './EditUsers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { showToast, AppName, UsersTitle } from '@utils';
import { apiGetUsersForAccount, apiUpdateAccountUser } from '@store';
import CreateUser from './CreateUser';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddBoxIcon from '@mui/icons-material/AddBox';

const UserManagement = () => {

    useEffect(() => {
        document.title = `${AppName} | ${UsersTitle}`
    }, [])

    const dispatch = useDispatch()
    const { account_uuid } = useParams()

    const { account } = useSelector((state) => state.account);
    const { usersForAccount } = useSelector((state) => state.user)

    useEffect(() => {
        dispatch(apiGetUsersForAccount(account_uuid))
    }, [account_uuid, dispatch])

    // find account_uuid from account and the get the single account
    const accountData = useMemo(() => {
        return account?.find((account) => account?.uuid === account_uuid)
    }, [account, account_uuid])

    // compare account_uuid in usersForAccount and get the users for that account
    const users = useMemo(() => {
        return usersForAccount?.filter((user) => user?.AccountUUID === account_uuid)
    }, [account_uuid, usersForAccount])

    const [selectedUser, setSelectedUser] = useState(null)

    // edit account modal
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
    const openEditUserModal = (selectedUser) => {
        setSelectedUser(selectedUser)
        setIsEditUserModalOpen(true)
    }
    const closeEditUserModal = () => setIsEditUserModalOpen(false)

    // create User modal
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
    const openCreateUserModal = () => setIsCreateUserModalOpen(true)
    const closeCreateUserModal = () => setIsCreateUserModalOpen(false)

    // delete User
    const handleDelete = async (selectedUser) => {
        window.confirm('Are you sure you want to delete this user?')
        if (true) {
            const data = { ...selectedUser, deleted: 1 }
            const resp = await dispatch(apiUpdateAccountUser(data))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                showToast('info', 'User deleted successfully')
                dispatch(apiGetUsersForAccount(account_uuid))
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Failed to delete user')
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Name",
                id: "fullname",
                accessorFn: ({ fullname }) => fullname || "",
                Cell: ({ renderedCellValue }) =>
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}>
                        {renderedCellValue}
                    </Typography>,
                size: 50
            },
            {
                header: "Username",
                id: "username",
                accessorFn: ({ username }) => username || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Email",
                id: "email",
                accessorFn: ({ email }) => email || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Status",
                id: "status",
                Cell: ({ row }) => <>
                    {
                        !row?.original?.disabled ?
                            <Box className='bg-green-100 text-green-800 text-sm font-medium me-2 py-0.5 text-center rounded'>Active</Box> :
                            <Box className='bg-red-100 text-red-800 text-sm font-medium me-2 py-0.5 text-center rounded'>Inactive</Box>
                    }
                </>,
                size: 50
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key={'buttons'}>
                        <Button
                            variant='outlined'
                            color='primary'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: '1px solid #27A9FF',
                                color: '#27A9FF',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: '1px solid #27A9FF',
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                },
                            }}
                            onClick={() => openEditUserModal(row.original)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='outlined'
                            color='error'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: 'brown',
                                color: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: 'brown',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={() => handleDelete(row.original)}
                        >
                            Delete
                        </Button>
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={isCreateUserModalOpen} onClose={closeCreateUserModal}>
                <CreateUser
                    handleClose={closeCreateUserModal}
                    open={isCreateUserModalOpen}
                    selectedUser={selectedUser}
                />
            </Modal>
            <Modal open={isEditUserModalOpen} onClose={closeEditUserModal}>
                <EditUsers
                    handleClose={closeEditUserModal}
                    open={isEditUserModalOpen}
                    selectedUser={selectedUser}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <Tooltip title="Export Users">
                                <IconButton
                                    onClick={() => {
                                        const header = 'company,fullname,email,phone,temporary\n'
                                        const data = users?.map((user) => {
                                            return `${user?.company},${user?.fullname},${user?.email},${user?.phone},${user?.temporary}\n`
                                        }).join('')
                                        const blob = new Blob([header, data], { type: 'text/csv' })
                                        const url = URL.createObjectURL(blob)
                                        const a = document.createElement('a')
                                        a.href = url
                                        a.download = `${accountData?.short_name}_users.csv`
                                        a.click()
                                    }}
                                >
                                    <FileDownloadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Add User">
                                <IconButton
                                    onClick={openCreateUserModal}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
                headerText={accountData?.short_name}
                columns={columns}
                data={users || []}
                actions={actionsMenu}
            // isLoading={isLoading}
            />
        </Box>
    )
}

export default UserManagement