import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { chatAPI } from '../../../utils/api';

const initialState = {
    profile: {},
    isGetProfileLoading: false,

    users: [],
    isGetUsersLoading: false,

    messages: {},
    isGetMessagesLoading: false,

    newMessages: []
};

export const apiGetChatProfile = createAsyncThunk(
    'chat/apiGetChatProfile',
    async ({ token }, thunkAPI) => {
        try {
            const response = await chatAPI.get('/chat/profile', {
                headers: {
                    Authorization: `${token}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetChatUsers = createAsyncThunk(
    'chat/apiGetChatUsers',
    async ({ token }, thunkAPI) => {
        try {
            const response = await chatAPI.get('/chat/users', {
                headers: {
                    Authorization: `${token}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiSearchChatUsers = createAsyncThunk(
    'chat/apiSearchChatUsers',
    async ({ token, searchText }, thunkAPI) => {
        try {
            const response = await chatAPI.get(`/chat/users?q=${searchText}`, {
                headers: {
                    Authorization: `${token}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetChatMessages = createAsyncThunk(
    'chat/apiGetChatMessages',
    async ({ token, user }, thunkAPI) => {
        try {
            const response = await chatAPI.get(`/chat/messages/${user}`, {
                headers: {
                    Authorization: `${token}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            const { user, message } = action.payload;
            state.messages[user] = [...(state.messages[user] || []), message];
        },
        addNewMessage: (state, action) => {
            // Check if the message is already in the list
            const message = state.newMessages.find(
                (msg) => msg?.uuid === action.payload?.uuid
            );

            if (!message) {
                state.newMessages = [...state.newMessages, action.payload];
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(apiGetChatProfile.pending, (state) => {
                state.isGetProfileLoading = true;
            })
            .addCase(apiGetChatProfile.fulfilled, (state, action) => {
                state.profile = action.payload;
                state.isGetProfileLoading = false;
            })
            .addCase(apiGetChatProfile.rejected, (state) => {
                state.isGetProfileLoading = false;
            })
            .addCase(apiGetChatUsers.pending, (state) => {
                state.isGetUsersLoading = true;
            })
            .addCase(apiGetChatUsers.fulfilled, (state, action) => {
                state.users = action.payload;
                state.isGetUsersLoading = false;
            })
            .addCase(apiGetChatUsers.rejected, (state) => {
                state.isGetUsersLoading = false;
            })
            .addCase(apiSearchChatUsers.pending, (state) => {
                state.isGetUsersLoading = true;
            })
            .addCase(apiSearchChatUsers.fulfilled, (state, action) => {
                state.users = action.payload;
                state.isGetUsersLoading = false;
            })
            .addCase(apiSearchChatUsers.rejected, (state) => {
                state.isGetUsersLoading = false;
            })
            .addCase(apiGetChatMessages.pending, (state) => {
                state.isGetMessagesLoading = true;
            })
            .addCase(apiGetChatMessages.fulfilled, (state, action) => {
                const { user, messages } = action.payload;
                state.messages[user] = messages;
                state.isGetMessagesLoading = false;
            })
            .addCase(apiGetChatMessages.rejected, (state) => {
                state.isGetMessagesLoading = false;
            });
    }
});

export const { addMessage, addNewMessage } = chatSlice.actions;
export default chatSlice.reducer;
