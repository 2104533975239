import React, { useState } from 'react';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import { Box, TextField } from '@mui/material';
import { apiGetForwardCurve } from '@store';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@utils';

const ForwardCurve = () => {
    const dispatch = useDispatch();
    const { forwardCurve } = useSelector((state) => state.overview);
    const transformedMonthHistoricalPrices = transformData(forwardCurve);

    const [date, setDate] = useState('')

    // call the api to get the forward curve data when the date is changed
    const handleChange = async (e) => {
        setDate(e.target.value)
        const resp = await dispatch(apiGetForwardCurve({ date: e.target.value }))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            // showToast('success', 'Data fetched successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload)
        }
    }

    return (
        <>
            {/* add date picker and format the date in yyyy-mm-dd format */}
            <Box>
                <TextField
                    id="date"
                    label="Pick a date"
                    type="date"
                    value={date}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>

            <Box className='mt-3'>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                        width={500}
                        height={400}
                        data={transformedMonthHistoricalPrices}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="labels" minTickGap={20} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="mx1" stroke="#05c46b" />
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
        </>
    )
}

export default ForwardCurve

function transformData(datasets) {
    // transform data to recharts format
    // For keys in the object, time is in labels and data is in key itself

    const transformedData = []

    // Get the length of the first dataset
    const length = datasets?.labels?.length

    for (let i = 0; i < length; i++) {
        // Insert first value of all the keys in the object
        const obj = {}

        Object.keys(datasets).forEach((key) => {
            obj[key] = datasets[key][i]
        })

        transformedData.push(obj)
    }

    return transformedData;
}