import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    watchlist: [],
    isGetWatchlistLoading: false,
    isDeleteWatchlistLoading: false,
};

export const apiGetWatchlist = createAsyncThunk(
    "watchlist/getWatchlist",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_watchlist');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteWatchlist = createAsyncThunk(
    "watchlist/deleteWatchlist",
    async ({ symbol, product }, thunkAPI) => {
        try {
            const response = await axios.post(`/api/remove_from_watchlist?symbol=${symbol}&product=${product}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const watchlistSlice = createSlice({
    name: "watchlist",
    initialState,
    reducers: {
        removeWatchlist: (state, action) => {
            state.watchlist = state.watchlist.filter((item) => item.symbol !== action.payload.symbol);
        },
    },
    extraReducers: (builder) => {
        // Get Watchlist
        builder
            .addCase(apiGetWatchlist.pending, (state) => {
                state.isGetWatchlistLoading = true;
            })
            .addCase(apiGetWatchlist.fulfilled, (state, action) => {
                state.watchlist = action.payload.data;
                state.isGetWatchlistLoading = false;
            })
            .addCase(apiGetWatchlist.rejected, (state) => {
                state.isGetWatchlistLoading = false;
            });

        // Delete Watchlist
        builder
            .addCase(apiDeleteWatchlist.pending, (state) => {
                state.isDeleteWatchlistLoading = true;
            })
            .addCase(apiDeleteWatchlist.fulfilled, (state, action) => {
                state.isDeleteWatchlistLoading = false;
            })
            .addCase(apiDeleteWatchlist.rejected, (state) => {
                state.isDeleteWatchlistLoading = false;
            });
    }
});

export const { removeWatchlist } = watchlistSlice.actions;

export default watchlistSlice.reducer;