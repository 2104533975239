import React, { useMemo } from 'react'
import { Button, Typography } from '@mui/material';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { ReactTable } from '@components';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';
import { apiDeleteMinMaxOrder, apiGetMinMaxRaw, apiGetTheEur, apiGetMinMaxDisplay } from '@store';

const MinMaxOrder = ({ minMaxRaw, isGetMinMaxRawLoading }) => {
    const dispatch = useDispatch();

    const handleDelete = async (row) => {
        if (window.confirm('Are you sure you want to delete this Min Max Order?')) {
            const resp = await dispatch(apiDeleteMinMaxOrder(row))
            if (resp.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Min Max Order deleted successfully');
                dispatch(apiGetMinMaxRaw())
                dispatch(apiGetMinMaxDisplay())
                dispatch(apiGetTheEur())
            } else if (resp.meta.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Failed to delete Min Max Order');
            }
        }
    }

    const minMaxColumn = useMemo(
        () => [
            {
                header: "Contract",
                id: "sym",
                accessorFn: ({ sym }) => sym || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Market",
                id: "market",
                accessorFn: ({ market }) => market || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Type",
                id: "type",
                accessorFn: ({ type }) => type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Rule",
                id: "rule",
                accessorFn: ({ rule }) => "Min Max Order",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Max Price",
                id: "max_price",
                accessorFn: ({ max_price }) => max_price.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Min Price",
                id: "min_price",
                accessorFn: ({ min_price }) => min_price.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume",
                id: "volume",
                accessorFn: ({ volume }) => volume.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    const minMaxActionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Button
                        key={'delete'}
                        variant='outlined'
                        color='error'
                        sx={{
                            p: 0,
                            m: 0,
                            borderColor: 'brown',
                            color: 'brown',
                            fontSize: '13px',
                            '&:hover': {
                                borderColor: 'brown',
                                color: 'white',
                                backgroundColor: 'brown',
                            },
                        }}
                        onClick={() => handleDelete(row?.original)}
                    >
                        Delete
                    </Button>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <ReactTable
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                    </div>
                )
            }
            headerText={'Min Max Orders'}
            columns={minMaxColumn}
            data={minMaxRaw || []}
            actions={minMaxActionsMenu}
            isLoading={isGetMinMaxRawLoading}
        />
    )
}

export default MinMaxOrder