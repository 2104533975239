import React from 'react'
import { CommonContainer } from '@components'
import { Box, Typography } from '@mui/material'

const ProfileInfo = ({ user }) => {
    return (
        <CommonContainer>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography sx={{ fontSize: '24px', mb: '4px' }}>Profile</Typography>
                <Typography sx={{ fontSize: '16px', mb: '4px', color: '#000' }}>{user?.FullName}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography sx={{ fontSize: '24px', mb: '4px' }}>User Name</Typography>
                <Typography sx={{ fontSize: '16px', mb: '4px', color: '#000' }}>{user?.Username}</Typography>
            </Box>
        </CommonContainer>
    )
}

export default ProfileInfo