import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Tooltip,
    Typography
} from '@mui/material'
import { available_contract_options, available_product_options } from '@data'
import { useDispatch } from 'react-redux'
import { showToast } from '@utils'
import { apiCreateAccountUser, apiGetUsersForAccount } from '@store'
import { useParams } from 'react-router-dom'

const emailTooltip = `General email where trade confirmations will be sent along with the user who did that trade.`
const purchaseLimitTooltip = `Soft purchase limit per year in MW.`

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateUser = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const { account_uuid } = useParams()

    const [state, setState] = React.useState({
        fullname: '',
        email: '',
        phone: '',
        AccountUUID: account_uuid,
        orderlimit: 0,
        visibleproducts: [],
        visiblecontracts: [],
        hideflex: false,
        hide_trailing_stop: false,
        hide_buy_button: false,
        hide_sell_button: false,
        temporary: false,
        valid_for: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    // for visible contracts
    const handleChangeMultipleContract = (event) => {
        const { target: { value } } = event;
        setState({
            // On autofill we get a stringified value.
            ...state,
            visiblecontracts: typeof value === 'string' ? value.split(',') : value
        });
    };

    // for visible products
    const handleChangeMultipleProduct = (event) => {
        const { target: { value } } = event;
        setState({
            // On autofill we get a stringified value.
            ...state,
            visibleproducts: typeof value === 'string' ? value.split(',') : value,
        });
    };

    // for checkboxes
    const handleChangeCheckbox = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    // handle toggle change
    const handleToggleChange = (e) => {
        const { name, checked } = e.target
        setState({
            ...state,
            [name]: checked
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            ...state,
            visibleproducts: state.visibleproducts.map(product => ({ id: available_product_options.find(p => p.name === product).id, name: product })),
            visiblecontracts: state.visiblecontracts.map(contract => ({ id: available_contract_options.find(c => c.name === contract).id, name: contract })),
            temporary: state.temporary ? 1 : 0,
            valid_for: state.temporary ? parseInt(state.valid_for) : 0,
            orderlimit: parseFloat(state.orderlimit),
        }

        const resp = await dispatch(apiCreateAccountUser(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetUsersForAccount(account_uuid))
            showToast('success', 'User successfully created')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Error creating user')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Create User`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 2,
                }}
            >
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="nameLabel"
                    >
                        Name
                    </InputLabel>
                    <OutlinedInput
                        id="name"
                        label="nameLabel"
                        name='fullname'
                        onChange={handleChange}
                        value={state.fullname}
                        required
                    />
                </FormControl>
                <Tooltip
                    title={emailTooltip}
                    arrow
                >
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="emailLabel"
                        >
                            Email
                        </InputLabel>
                        <OutlinedInput
                            id="email"
                            label="emailLabel"
                            name='email'
                            type='email'
                            onChange={handleChange}
                            value={state.email}
                            required
                        />
                    </FormControl>
                </Tooltip>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="phoneLabel"
                        sx={{ bgcolor: '#F5F5F5' }}
                    >
                        Phone Number
                    </InputLabel>
                    <OutlinedInput
                        id="phone"
                        label="phoneLabel"
                        name='phone'
                        type='number'
                        onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                        onChange={handleChange}
                        value={state.phone}
                    />
                </FormControl>
                <Tooltip
                    title={purchaseLimitTooltip}
                    arrow
                >
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="purchaseLimitLabel"
                            sx={{ bgcolor: '#F5F5F5' }}
                        >
                            Purchase Limit
                        </InputLabel>
                        <OutlinedInput
                            id="orderlimit"
                            label="purchaseLimitLabel"
                            name='orderlimit'
                            type='number'
                            onChange={handleChange}
                            value={state.orderlimit}
                        />
                    </FormControl>
                </Tooltip>
                <FormControl>
                    <InputLabel id="demo-multiple-checkbox-label">Visible Products</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={state.visibleproducts}
                        onChange={handleChangeMultipleProduct}
                        input={<OutlinedInput label="Visible Products" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {available_product_options?.map((product) => (
                            <MenuItem key={product.id} value={product.name}>
                                <Checkbox checked={state.visibleproducts.indexOf(product.name) > -1} />
                                <ListItemText primary={product.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="demo-multiple-checkbox-label">Visible Contracts</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={state.visiblecontracts}
                        onChange={handleChangeMultipleContract}
                        input={<OutlinedInput label="Visible Contracts" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {available_contract_options?.map((contract) => (
                            <MenuItem key={contract.id} value={contract.name}>
                                <Checkbox checked={state.visiblecontracts.indexOf(contract.name) > -1} />
                                <ListItemText primary={contract.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className='flex flex-col'>
                    <Box className='flex gap-2 items-center'>
                        <Typography sx={{ fontWeight: 'bold' }}>Temporary User:</Typography>
                        <Switch
                            name="temporary"
                            checked={state.temporary}
                            onChange={handleToggleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    {/* show if state.temporary is true */}
                    {state.temporary && (
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="valid_for"
                            value={state.valid_for}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    Duration
                                </InputAdornment>
                            }
                        >
                            <MenuItem value={'1'}>1 hour</MenuItem>
                            <MenuItem value={'2'}>2 hours</MenuItem>
                            <MenuItem value={'6'}>6 hours</MenuItem>
                            <MenuItem value={'24'}>1 day</MenuItem>
                            <MenuItem value={'168'}>7 days</MenuItem>
                        </Select>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    mt: 2
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold'
                    }}
                >
                    Please specify you want to disable
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.hide_buy_button}
                                onChange={handleChangeCheckbox}
                                name="hide_buy_button"
                            />
                        }
                        label="Disable Buy"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.hide_sell_button}
                                onChange={handleChangeCheckbox}
                                name="hide_sell_button"
                            />
                        }
                        label="Disable Sell"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.hide_trailing_stop}
                                onChange={handleChangeCheckbox}
                                name="hide_trailing_stop"
                            />
                        }
                        label="Disable TrailingStop"
                    />
                </Box>
            </Box>
        </CustomModal>
    )
})

export default CreateUser