import React, { useEffect } from 'react';
import {
    Box,
    IconButton,
    InputLabel,
    TextField,
    Typography
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { useCreateGroupMessageMutation } from '@store/feature/chat/chatApi';
import CloseIcon from '@mui/icons-material/Close';

const SendGroupChat = ({ id, groupProfile, userProfile }) => {
    const [createGroupMessage] = useCreateGroupMessageMutation();
    const removeImage = (index) => {
        setMyMessage((prev) => ({
            ...prev,
            files: Array.from(prev.files).filter((_, i) => i !== index)
        }));
    };
    const [myMessage, setMyMessage] = React.useState({
        body: '',
        to: id,
        files: []
    });

    useEffect(() => {
        setMyMessage((prev) => {
            return {
                ...prev,
                to: id
            };
        });
    }, [id]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSendMessage(event);
        }
    };

    const handleChange = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            setMyMessage((prev) => ({
                ...prev,
                body: event.target.value + '\n'
            }));
        } else {
            setMyMessage((prev) => ({ ...prev, body: event.target.value }));
        }
    };

    const handleFileChange = (e) => {
        setMyMessage({
            ...myMessage,
            files: e.target.files
        });
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        try {
            if (!myMessage.body) {
                return;
            }

            const message = {
                ...myMessage,
                body: myMessage.body.trim(),
                to: id,
                files: myMessage.files
            };

            setMyMessage({
                ...myMessage,
                body: '',
                to: id,
                files: []
            });

            const formData = new FormData();
            formData.append('body', message.body);
            formData.append('to', message.to);

            if (message.files) {
                for (let i = 0; i < message.files.length; i++) {
                    formData.append('files', message.files[i]);
                }
            }

            const response = await createGroupMessage({
                body: message.body,
                groupId: id
            });

            if (response.error) {
                console.error('Failed to send message:', response.error);
                return;
            }
        } catch (error) {
            console.log(error, 'Error in handleSendMessage');
        }
    };

    return (
        <Box>
            {userProfile?.blocked === true ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography>You have blocked this user.</Typography>
                </Box>
            ) : userProfile?.blocked_by_user === true ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography>You are blocked by this user.</Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        m: '8px'
                    }}
                    component="form"
                    onSubmit={handleSendMessage}
                >
                    <Box>
                        <TextField
                            fullWidth
                            size="small"
                            name="body"
                            variant="standard"
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            value={myMessage.body}
                            placeholder={`Message ${groupProfile?.name}`}
                            InputProps={{
                                disableUnderline: true, // <== added this
                                style: {
                                    fontSize: '12px'
                                }
                            }}
                            sx={{
                                px: '8px',
                                pt: '8px',
                                wordWrap: 'break-word',
                                maxHeight: '100px',
                                minHeight: '64px',
                                overflowY: 'auto'
                            }}
                            multiline
                        />
                    </Box>
                    {/* end of text field */}
                    {/* selected image */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '8px',
                            flexWrap: 'wrap',
                            maxHeight: '200px',
                            px: '8px',
                            // sticky in the bottom
                            position: 'sticky',
                            bottom: 0
                        }}
                    >
                        {Array.from(myMessage.files).map((file, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    mt: file ? '8px' : '0'
                                }}
                            >
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="preview"
                                    style={{
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '8px',
                                        objectFit: 'cover'
                                    }}
                                />
                                <Box
                                    onClick={() => removeImage(index)}
                                    sx={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        transform: ' translate(50%, -50%)',
                                        backgroundColor: 'rgba(0,0,0,0.8)',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '4px',
                                        width: '15px',
                                        height: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                    }}
                                    title="Remove File"
                                >
                                    <CloseIcon
                                        sx={{
                                            fontSize: '0.8rem'
                                        }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    {/* end of selected image */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        {/* select image */}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center'
                            }}
                        >
                            <input
                                color="primary"
                                type="file"
                                name="files"
                                onChange={handleFileChange}
                                style={{
                                    display: 'none'
                                }}
                                id="file"
                                multiple
                                // {...getInputProps()}
                            />
                            <InputLabel htmlFor="file">
                                <IconButton
                                    color="primary"
                                    component="span"
                                    sx={{
                                        transform: 'rotate(45deg)'
                                    }}
                                >
                                    <AttachFileIcon fontSize="small" />
                                </IconButton>
                            </InputLabel>
                        </Box>
                        {/* end of select image */}
                        {/* send button */}
                        <IconButton color="primary" type="submit">
                            <SendIcon fontSize="small" />
                        </IconButton>
                        {/* end of send button */}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SendGroupChat;
