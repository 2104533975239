import React, { useEffect, useState } from 'react'
import { Box, Button, Modal } from '@mui/material'
import { useSelector } from 'react-redux'
import UploadPost from './UploadPost';
import { PostComponent } from '@components';
import { AppName, NewsFeedTitle } from '@utils';

const Newsfeed = () => {

    useEffect(() => {
        document.title = `${AppName} | ${NewsFeedTitle}`
    }, [])

    const { newsfeed, comments } = useSelector(state => state.newsfeed)
    const { user } = useSelector((state) => state.user);

    // open modal
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <UploadPost
                    handleClose={handleCloseModal}
                />
            </Modal>
            <Box className='w-[1000px] mx-auto'>
                {newsfeed?.length > 0 && (
                    <Box className='flex justify-end'>
                        <Button
                            variant='contained'
                            color='primary'
                            sx={{
                                bgcolor: '#0b9cfd',
                                color: 'white',
                                '&:hover': {
                                    bgcolor: '#0b9cfd',
                                },
                            }}
                            onClick={handleOpenModal}
                        >
                            Create New Post
                        </Button>
                    </Box>
                )}
                {newsfeed?.length > 0 ? newsfeed.map(news => (
                    <PostComponent
                        key={news.uuid}
                        news={news}
                        comments={comments}
                        user={user}
                    />
                )) : (
                    <Box className='text-center mt-5 h-[calc(100vh-200px)] flex flex-col justify-center items-center'>
                        <p className='text-2xl font-bold text-gray-500'>No post found</p>
                        <p>
                            Click <span className='cursor-pointer text-blue-500' onClick={handleOpenModal}>here</span> to create new post
                        </p>
                    </Box>
                )}
            </Box >
        </>
    )
}

export default Newsfeed