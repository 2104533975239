import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Modal, Tooltip } from '@mui/material';
import { apiDeletePriceprofile, apiGetPriceprofile, deletePriceprofile } from '@store';
import { GAS_PATH, showToast, AppName, PriceProfilingTitle } from '@utils';
import { ReactTable } from '@components';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PublishIcon from '@mui/icons-material/Publish';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import CreatePriceProfile from './Create';
import { useNavigate } from 'react-router-dom';
import UploadRates from './UploadRates';

const PriceProfiling = () => {
    useEffect(() => {
        document.title = `${AppName} | ${PriceProfilingTitle}`
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { priceprofile, isGetPriceprofileLoading, profiling_rates } = useSelector((state) => state.priceprofile);
    const { user } = useSelector((state) => state.user);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    // upload rates modal
    const [openUploadRatesModal, setOpenUploadRatesModal] = useState(false);
    const handleOpenUploadRatesModal = () => { setOpenUploadRatesModal(true); };
    const handleCloseUploadRatesModal = () => { setOpenUploadRatesModal(false); };

    const handleDelete = async (price_profile) => {
        if (window.confirm("Are you sure you want to delete this price profile?")) {
            const resp = await dispatch(apiDeletePriceprofile(price_profile))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                dispatch(deletePriceprofile(price_profile))
                dispatch(apiGetPriceprofile())
                showToast('info', 'Price Profile deleted successfully')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload)
            } else {
                showToast('error', 'Something went wrong')
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "UUID",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Product",
                id: "product",
                accessorFn: ({ product }) => product || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Created at",
                id: "created_at",
                accessorFn: ({ created_at }) => created_at || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const actions = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box
                        className='flex gap-4 items-center justify-center'
                        key={'button'}
                    >
                        <Button
                            variant='outlined'
                            color='primary'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: '1px solid #27A9FF',
                                color: '#27A9FF',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: '1px solid #27A9FF',
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                },
                            }}
                            onClick={() => handleGoToResult(row.original)}
                        >
                            Edit
                        </Button>
                        <Button
                            key={'delete'}
                            variant='outlined'
                            color='error'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: 'brown',
                                color: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: 'brown',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={() => handleDelete(row.original?.uuid)}
                        >
                            Delete
                        </Button>
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleGoToResult = (priceprofile) => {
        navigate(`${GAS_PATH.SINGLE_PRICE_PROFILE}/${priceprofile.uuid}`)
    }

    return (
        <Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <CreatePriceProfile
                    handleClose={handleCloseModal}
                />
            </Modal>
            {/* upload rates */}
            <Modal
                open={openUploadRatesModal}
                onClose={handleCloseUploadRatesModal}
            >
                <UploadRates
                    profiling_rates={profiling_rates}
                    handleClose={handleCloseUploadRatesModal}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <Tooltip title="Create Price Profile">
                                <IconButton
                                    onClick={handleOpenModal}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                            {user?.CanUploadRates &&
                                <Tooltip title="Upload Rates">
                                    <IconButton
                                        onClick={handleOpenUploadRatesModal}
                                    >
                                        <PublishIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    )
                }
                headerText={'Price Profiling List'}
                columns={columns}
                data={priceprofile || []}
                actions={actions}
                // handleRowClick={handleGoToResult}
                isLoading={isGetPriceprofileLoading}
            />
        </Box>
    )
}

export default PriceProfiling