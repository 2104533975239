import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const ClosingPrice = () => {
    return (
        <Box
            sx={{
                background: 'white',
                padding: '1rem',
                borderRadius: '5px',
                boxShadow: '0px 0px 4px rgba(0,0,0,0.1)',
                textAlign: 'center',
                marginBottom: '1rem'
            }}
        >
            <Link
                to={'/media/files/csv/closing/'}
                target='_blank'
            >
                <Typography
                    sx={{
                        fontSize: '1.6rem',
                        fontWeight: 'bold',
                        color: '#0B9CFD',
                    }}
                >
                    Get Closing Prices in CSV
                </Typography>
            </Link>
        </Box>
    )
}

export default ClosingPrice