import React, { forwardRef, useRef } from 'react';
import { CustomModal } from '@components';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    useAddGroupMembersMutation,
    useGetOrSearchAllUsersQuery,
    useGetChatProfileQuery
} from '@store/feature/chat/chatApi';
import AddMemberSearch from '../../groups/AddMemberSearch';
import { TickMemberCard } from './TickMemberCard';
import { FiCommand } from 'react-icons/fi';

const AddMemberModal = forwardRef(
    ({ handleClose, groupMembers, isGroupMemberLoading }, ref) => {
        const { id } = useParams();
        const [searchText, setSearchText] = React.useState('');
        const [userIds, setuserIds] = React.useState([]);

        const { data: profile } = useGetChatProfileQuery();
        const [addGroupMembers] = useAddGroupMembersMutation();

        const { data: usersData } = useGetOrSearchAllUsersQuery(searchText);

        // filtering out the users who are already in the group

        const groupMemberUUIDs = new Set(
            groupMembers.map((member) => member.uuid)
        );

        const filteredUsers = usersData?.filter((user) => {
            return !groupMemberUUIDs.has(user?.user?.uuid);
        });

        const handleCheckboxChange = (userId, isChecked) => {
            setuserIds((prevUserIds) => {
                if (isChecked) {
                    return [...prevUserIds, userId];
                } else {
                    return prevUserIds.filter((id) => id !== userId);
                }
            });
        };

        const handleAddMembers = async (e) => {
            e.preventDefault();
            console.log('Clicked on add members');
            const response = await addGroupMembers({
                groupId: id,
                members: userIds
            });
            console.log('response from add member modal:', response);

            if (response) {
                handleClose();
            }
        };

        // Search
        const searchRef = useRef(null);
        const searchTimeout = 500;

        const handleChange = (e) => {
            e.preventDefault();

            const text = e.target.value;

            // If search is already set, clear it
            if (searchRef.current) {
                clearTimeout(searchRef.current);
            }

            // Set a new search timeout
            searchRef.current = setTimeout(() => {
                setSearchText(text);
            }, searchTimeout);
        };

        return (
            <Box>
                {isGroupMemberLoading && (
                    <div className="flex items-center justify-center h-20 gap-4">
                        <span className="text-teal-400 text-xl font-semibold">
                            Loading...
                        </span>
                        <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                    </div>
                )}
                <CustomModal
                    handleClose={handleClose}
                    handleSubmit={handleAddMembers}
                    modalTitle={`Add Members`}
                    buttonText={'Add Members'}
                    modalTitleColor={'teal'}
                    className="modal-button-blue"
                    ref={ref}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: 'black',
                            justifyContent: 'flex-start',
                            width: '100%'
                        }}
                    >
                        <AddMemberSearch handleChange={handleChange} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2px',
                                height: '300px', // Set a fixed height
                                width: '380px',
                                overflowY: 'scroll', // Enable vertical scrolling
                                border: '1px solid #ccc',
                                marginLeft: '16px',
                                alignItems: 'center'
                            }}
                        >
                            {filteredUsers?.map((user, index) => {
                                return (
                                    <div
                                        className="w-[300px] mb-[-10] px-6"
                                        key={index}
                                    >
                                        <TickMemberCard
                                            imageURL={user?.user?.image_url}
                                            name={user?.user?.fullname}
                                            userId={user?.user?.uuid}
                                            isActive={
                                                user?.user?.uuid ===
                                                    profile?.uuid ||
                                                user?.user?.status === 'active'
                                            }
                                            isChecked={userIds.includes(
                                                user?.user?.uuid
                                            )}
                                            onCheckboxChange={
                                                handleCheckboxChange
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </Box>
                    </Box>
                </CustomModal>
            </Box>
        );
    }
);

export default AddMemberModal;
