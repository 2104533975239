import React, { forwardRef, useEffect, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Select, Typography, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiBuyContract, apiBuyTheEurContractMinMax, apiGetMinMaxRaw, apiGetTheEur, apiGetMinMaxDisplay, apiGetOrder } from '@store'
import { showToast } from '@utils'

const Sell = forwardRef(({ handleClose, selectedContract, user }, ref) => {
    const dispatch = useDispatch()
    const [showMinMaxInput, setShowMinMaxInput] = useState(false)

    const [state, setState] = useState({
        sym: selectedContract?.contract,
        starthour: user?.StartTime,
        endhour: user?.EndTime,
        lunchstart: user?.LunchStart,
        lunchend: user?.LunchEnd,
        Hideflex: user?.Hideflex,
        Company: user?.Company,
        CompanyShort: user?.CompanyShort,
        UserCanBuyFrom: user?.CanBuyFrom,
        UserCanSellTo: user?.CanSellTo,
        Endpoints: user?.Endpoints,
        Currency: 'EUR',
        DeliveryPoint: ['THE'],
        market: 'THE',
        type: 'buy',
        price_selected: '',
        buyer: '',
        seller: user?.CompanyShort,
        deliverypoint: 'THE',
        price_percent: '100',
        volume: '',
        exitpoint: '',
        ordertype: 'internal',
        currency: 'EUR',
        max_price: '',
        on_max: false,
        min_price: '',
        on_min: false,
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const [type, setType] = useState('bid')

    const handleTypeChange = (e) => {
        setType(e.target.value)
        if (e.target.value === 'min_max') {
            setShowMinMaxInput(true)
        } else if (e.target.value === 'bid') {
            setShowMinMaxInput(false)
        }
    }

    const handleMinChange = (e) => {
        // If min price is changed, clear max price and disable it
        // If min price is cleared, enable max price
        if (e.target.value === '') {
            setState({
                ...state,
                min_price: e.target.value,
                max_price: '',
                on_min: false,
                on_max: false
            })
        } else {
            setState({
                ...state,
                min_price: e.target.value,
                max_price: '',
                on_min: true,
                on_max: false
            })
        }
    }

    const handleMaxChange = (e) => {
        // If max price is changed, clear min price and disable it
        // If max price is cleared, enable min price
        if (e.target.value === '') {
            setState({
                ...state,
                max_price: e.target.value,
                min_price: '',
                on_max: false,
                on_min: false
            })
        } else {
            setState({
                ...state,
                max_price: e.target.value,
                min_price: '',
                on_max: true,
                on_min: false
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let data;
        if (type === 'bid') {
            // Remove min_max fields
            const { minprice, maxprice, on_min, on_max, ...rest } = state
            const volume = parseFloat(state.volume)
            const bid = parseFloat(selectedContract.bid)
            const ask = parseFloat(selectedContract.ask)
            const updated_at = selectedContract?.updated_at
            const buyback10 = parseFloat(selectedContract.buyback10)
            const buyback20 = parseFloat(selectedContract.buyback20)
            const buyback30 = parseFloat(selectedContract.buyback30)

            const data = {
                ...rest,
                volume,
                price: {
                    ask: ask,
                    bid: bid,
                    updated_at: updated_at,
                    buyback10: buyback10,
                    buyback20: buyback20,
                    buyback30: buyback30
                }
            }

            const resp = await dispatch(apiBuyContract(data))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                handleClose()
                dispatch(apiGetOrder())
                showToast('success', 'Purchase order succesfully saved')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Error buying contract')
            }
        } else if (type === 'min_max') {
            const minprice = parseFloat(state.min_price)
            const maxprice = parseFloat(state.max_price)

            data = {
                ...state,
                min_price: minprice || 0,
                max_price: maxprice || 0,
                on_min: minprice ? true : false,
                on_max: maxprice ? true : false,
            }

            const resp = await dispatch(apiBuyTheEurContractMinMax(data))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                handleClose()
                dispatch(apiGetMinMaxRaw())
                dispatch(apiGetTheEur())
                dispatch(apiGetMinMaxDisplay())
                showToast('success', 'Purchase order succesfully saved')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Error buying contract')
            }
        }
    }

    // timer
    const [timer, setTimer] = useState(25);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (ref.current) {
            setTimer(25);
        }
    }, [ref]);

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            timer={timer}
            modalTitle={`Sell Contract`}
            modalTitleColor={'#EB6969'}
            buttonText={'Sell'}
            className='modal-button-danger'
            disabled={state.volume ? false : true}
            ref={ref}
        >
            <Box className='flex flex-col gap-3'>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Please Select what you would like to sell
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                        name='type'
                        value={type}
                        onChange={handleTypeChange}
                    >
                        <MenuItem value='bid'>Bid Price</MenuItem>
                        <MenuItem value='min_max'>Min/Max Stop</MenuItem>
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Contract:</Typography>
                    <Typography color={'black'}>{selectedContract.contract}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <InputLabel
                        id="contractLabel"
                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                    >
                        Volume
                    </InputLabel>
                    <OutlinedInput
                        id="volume"
                        label="Volume"
                        type="number"
                        name="volume"
                        value={state.volume}
                        onChange={handleChange}
                        inputProps={{ step: 0.1 }}
                        required
                        endAdornment={
                            <InputAdornment position="start">
                                MW
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Endpoint
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Endpoint"
                        name='exitpoint'
                        value={state.exitpoint}
                        onChange={handleChange}
                    >
                        {user?.Endpoints?.map((endpoint, index) => (
                            <MenuItem key={index} value={endpoint}> {endpoint.name} - {endpoint.location}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Sell To
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sell To"
                        name='buyer'
                        value={state?.buyer}
                        onChange={handleChange}
                    >
                        <MenuItem value={'SET'}>SET</MenuItem>
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Price:</Typography>
                    <Typography color={'black'}>{selectedContract?.bid}</Typography>
                </Box>
                {/* <Box className='flex gap-3'>
                    <Typography>Settlement Amount:</Typography>
                    <Typography color={'black'}>{selectedContract.bid * state?.volume || 0} </Typography >
                </Box> */}
                {showMinMaxInput &&
                    <Box className='flex gap-3'>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel
                                id="priceLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                            >
                                Max Price
                            </InputLabel>
                            <OutlinedInput
                                id="price"
                                label="Price"
                                name="maxprice"
                                value={state.maxprice}
                                onChange={handleMaxChange}
                                disabled={state.on_min}
                                required
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel
                                id="priceLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                            >
                                Min Price
                            </InputLabel>
                            <OutlinedInput
                                id="price"
                                label="Price"
                                name="minprice"
                                value={state.minprice}
                                onChange={handleMinChange}
                                disabled={state.on_max}
                                required
                            />
                        </FormControl>
                    </Box>
                }
                <Box className='flex gap-3'>
                    <Typography>Last Updated:</Typography>
                    <Typography color={'black'}>{selectedContract?.updated_at_cet_str}</Typography>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default Sell