import React, { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { useSelector } from 'react-redux';
import CreateTrade from './CreateTrade';
import Bid from './Bid';
import Ask from './Ask';

const Mp = ({ openModal, handleCloseModal }) => {
    const { theeur } = useSelector(state => state.the);
    const { marketplace, isGetMarketplaceLoading } = useSelector(state => state.marketplace);

    const [state, setState] = useState({
        dataForBid: [],
        dataForAsk: []
    })

    useEffect(() => {
        const dataForBid = marketplace.map((item) => {
            return item?.bids
        }).flat()

        const dataForAsk = marketplace.map((item) => {
            return item?.asks
        }).flat()

        setState({
            dataForBid,
            dataForAsk
        })
    }, [marketplace])
    return (
        <Box className='flex gap-2'>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreateTrade
                    handleClose={handleCloseModal}
                    open={openModal}
                    contracts={theeur?.symbols}
                />
            </Modal>
            <Box className='w-full'>
                <Bid data={state.dataForBid} isLoading={isGetMarketplaceLoading} />
            </Box>
            <Box className='w-full'>
                <Ask data={state.dataForAsk} isLoading={isGetMarketplaceLoading} />
            </Box>
        </Box>
    )
}

export default Mp