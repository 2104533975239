import React, { useState } from 'react'
import { Box, Button, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { apiPostComment, apiGetComments, apiDeleteComment } from '@store';
import { useDispatch } from 'react-redux';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { showToast } from '@utils';

const PostComments = ({ comment }) => {
    const dispatch = useDispatch()

    // show reply section
    const [showReplySection, setShowReplySection] = useState(false)
    const handleShowReplySection = () => { setShowReplySection(!showReplySection) }

    // show replies
    const [showReplies, setShowReplies] = useState(false)
    const handleShowReplies = () => { setShowReplies(!showReplies) }

    // reply
    const [replyState, setReplyState] = useState({
        body: "",
        news_uuid: comment?.news_uuid,
        type: "reply",
        parent_uuid: comment?.replies?.length > 0 && comment?.replies[0].parent_uuid
    })

    // handle reply change
    const handleReplyChange = (e, parent_uuid) => {
        setReplyState({
            ...replyState,
            body: e.target.value,
            parent_uuid
        })
    }

    // submit reply
    const handleSubmitReply = async (e) => {
        e.preventDefault()
        const response = await dispatch(apiPostComment(replyState))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetComments(comment.news_uuid))
            setReplyState({
                body: "",
                news_uuid: comment?.news_uuid,
                type: "reply",
                parent_uuid: comment?.replies?.length > 0 && comment?.replies[0].parent_uuid
            })
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        }
    }

    // menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // delete comment
    const deleteComment = async (comment_uuid) => {
        const response = await dispatch(apiDeleteComment(comment_uuid))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetComments(comment.news_uuid))
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        }
    }

    return (
        <Box key={comment.uuid} className='p-2 ml-4'>
            <Box className='flex gap-2'>
                <img src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                    className="w-8 h-8 rounded-circle" alt="" />
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            variant='subtitle2'
                        >
                            {comment.user_fullname}
                        </Typography>
                        {comment?.can_delete && (
                            <Box>
                                <Tooltip title="Menu">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <MoreVertOutlinedIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: { ...styles },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem
                                        sx={{ color: 'red' }}
                                        onClick={() => deleteComment(comment.uuid)}
                                    >
                                        <DeleteOutlineOutlinedIcon className='mr-2' /> Delete
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>
                    <Typography
                        sx={{
                            padding: '0 !important',
                            fontSize: '14px',
                            color: 'black',
                            marginTop: '4px',
                            marginBottom: '8px'
                        }}>
                        {comment.body}
                    </Typography>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                cursor: 'pointer',
                            }}
                            onClick={handleShowReplies}
                        >
                            {comment?.replies?.length} replies
                        </Typography>
                        <FiberManualRecordIcon sx={{ fontSize: '6px', color: 'grey' }} />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                cursor: 'pointer',
                            }}
                            onClick={handleShowReplySection}
                        >
                            Reply
                        </Typography>
                        <FiberManualRecordIcon sx={{ fontSize: '6px', color: 'grey' }} />
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}
                        >
                            {comment.created_at.slice(0, 10)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box ml={5} mt={2}>
                {showReplies && (
                    <Box>
                        {comment?.replies?.map(reply => (
                            <Box key={reply.uuid} className='p-2 ml-4'>
                                <Box className='flex gap-2'>
                                    <img src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                                        className="w-8 h-8 rounded-circle" alt="" />
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography
                                                variant='subtitle2'
                                            >
                                                {reply.user_fullname}
                                            </Typography>
                                            {reply.can_delete && (
                                                <Box>
                                                    <Tooltip title="Menu">
                                                        <IconButton
                                                            onClick={handleClick}
                                                            size="small"
                                                            aria-controls={open ? 'account-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                        >
                                                            <MoreVertOutlinedIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        id="account-menu"
                                                        open={open}
                                                        onClose={handleClose}
                                                        onClick={handleClose}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: { ...styles },
                                                        }}
                                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                    >
                                                        <MenuItem
                                                            sx={{ color: 'red' }}
                                                            onClick={() => deleteComment(reply.uuid)}
                                                        >
                                                            <DeleteOutlineOutlinedIcon className='mr-2' /> Delete
                                                        </MenuItem>
                                                    </Menu>
                                                </Box>
                                            )}
                                        </Box>
                                        <Typography
                                            sx={{
                                                padding: '0 !important',
                                                fontSize: '14px',
                                                color: 'black',
                                                marginTop: '8px',
                                                marginBottom: '8px'
                                            }}>
                                            {reply.body}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
                {showReplySection && (
                    <Box className='p-2'>
                        <Box className='relative'>
                            <TextField
                                id="outlined-basic"
                                label="Reply"
                                variant="outlined"
                                size='small'
                                onChange={(e) => handleReplyChange(e, comment.uuid)}
                                value={replyState.body}
                                fullWidth
                                required
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    position: 'absolute',
                                    bottom: 5,
                                    right: 5,
                                    fontSize: '13px',
                                    borderColor: '1px solid #27A9FF',
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        color: 'white',
                                        backgroundColor: '#27A9FF',
                                    }
                                }}
                                size='small'
                                onClick={handleSubmitReply}
                                disabled={replyState.body === ""}
                            >
                                Reply
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default PostComments

const styles = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
}