import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    newsfeed: [],
    comments: {},
    isGetNewsfeedLoading: false,
    isLikingPostLoading: false,
    isUnlikingPostLoading: false,
    isGetCommentsLoading: false,
    isPostCommentLoading: false,
    isCreateNewsLoading: false,
    isDeleteNewsLoading: false,
    isEditNewsLoading: false,
    isDeleteCommentLoading: false
};

export const apiGetNews = createAsyncThunk(
    "newsfeed/getNewsfeed",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_news');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiLikeNews = createAsyncThunk(
    "newsfeed/news_like",
    async (news_uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/news_like?news_uuid=${news_uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUnlikeNews = createAsyncThunk(
    "newsfeed/news_unlike",
    async (news_uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/news_unlike?news_uuid=${news_uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetComments = createAsyncThunk(
    "newsfeed/getComments",
    async (news_uuid, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_comments?news_uuid=${news_uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiPostComment = createAsyncThunk(
    "newsfeed/postComment",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/post_comment', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateNews = createAsyncThunk(
    "newsfeed/createPost",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/api/post_news', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteNews = createAsyncThunk(
    "newsfeed/deletePost",
    async (news, thunkAPI) => {
        try {
            const response = await axios.post('/api/delete_news', news);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiEditNews = createAsyncThunk(
    "newsfeed/editPost",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/api/update_news', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { data: response.data, status: response.status, statusText: response.message }
        }
        catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteComment = createAsyncThunk(
    "newsfeed/deleteComment",
    async (comment_uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_comment?comment_uuid=${comment_uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const newsfeedSlice = createSlice({
    name: "newsfeed",
    initialState,
    reducers: {
        likeNews: (state, action) => {
            state.newsfeed = state.newsfeed.map(news => {
                if (news.uuid === action.payload) {
                    news.is_liked = !news.is_liked;
                    news.likes = news.is_liked ? news.likes + 1 : news.likes - 1;
                }
                return news;
            });
        },
        unlikeNews: (state, action) => {
            state.newsfeed = state.newsfeed.map(news => {
                if (news.uuid === action.payload) {
                    news.is_liked = !news.is_liked;
                    news.likes = news.is_liked ? news.likes + 1 : news.likes - 1;
                }
                return news;
            });
        }
    },
    extraReducers: (builder) => {
        // get newsfeed
        builder
            .addCase(apiGetNews.pending, (state) => {
                state.isGetNewsfeedLoading = true;
            })
            .addCase(apiGetNews.fulfilled, (state, action) => {
                state.newsfeed = action.payload.data;
                state.isGetNewsfeedLoading = false;
            })
            .addCase(apiGetNews.rejected, (state) => {
                state.isGetNewsfeedLoading = false;
            });

        // like news
        builder
            .addCase(apiLikeNews.pending, (state) => {
                state.isLikingPostLoading = true;
            })
            .addCase(apiLikeNews.fulfilled, (state, action) => {
                state.isLikingPostLoading = false;
            })
            .addCase(apiLikeNews.rejected, (state) => {
                state.isLikingPostLoading = false;
            });

        // unlike news
        builder
            .addCase(apiUnlikeNews.pending, (state) => {
                state.isUnlikingPostLoading = true;
            })
            .addCase(apiUnlikeNews.fulfilled, (state, action) => {
                state.isUnlikingPostLoading = false;
            })
            .addCase(apiUnlikeNews.rejected, (state) => {
                state.isUnlikingPostLoading = false;
            });

        // get comments
        builder
            .addCase(apiGetComments.pending, (state) => {
                state.isGetCommentsLoading = true;
            })
            .addCase(apiGetComments.fulfilled, (state, action) => {
                state.isGetCommentsLoading = false;
                state.comments = action.payload.data;
            })
            .addCase(apiGetComments.rejected, (state) => {
                state.isGetCommentsLoading = false;
            });

        // post comment
        builder
            .addCase(apiPostComment.pending, (state) => {
                state.isPostCommentLoading = true;
            })
            .addCase(apiPostComment.fulfilled, (state, action) => {
                state.isPostCommentLoading = false;
            })
            .addCase(apiPostComment.rejected, (state) => {
                state.isPostCommentLoading = false;
            });

        // create news
        builder
            .addCase(apiCreateNews.pending, (state) => {
                state.isCreateNewsLoading = true;
            })
            .addCase(apiCreateNews.fulfilled, (state, action) => {
                state.isCreateNewsLoading = false;
            })
            .addCase(apiCreateNews.rejected, (state) => {
                state.isCreateNewsLoading = false;
            });

        // delete news
        builder
            .addCase(apiDeleteNews.pending, (state) => {
                state.isDeleteNewsLoading = true;
            })
            .addCase(apiDeleteNews.fulfilled, (state) => {
                state.isDeleteNewsLoading = false;
            })
            .addCase(apiDeleteNews.rejected, (state) => {
                state.isDeleteNewsLoading = false;
            });

        // edit news
        builder
            .addCase(apiEditNews.pending, (state) => {
                state.isEditNewsLoading = true;
            })
            .addCase(apiEditNews.fulfilled, (state) => {
                state.isEditNewsLoading = false;
            })
            .addCase(apiEditNews.rejected, (state) => {
                state.isEditNewsLoading = false;
            });

        // delete comment
        builder
            .addCase(apiDeleteComment.pending, (state) => {
                state.isDeleteCommentLoading = true;
            })
            .addCase(apiDeleteComment.fulfilled, (state) => {
                state.isDeleteCommentLoading = false;
            })
            .addCase(apiDeleteComment.rejected, (state) => {
                state.isDeleteCommentLoading = false;
            });
    }
});

export const { likeNews, unlikeNews } = newsfeedSlice.actions;

export default newsfeedSlice.reducer;