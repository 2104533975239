import React, { forwardRef, useEffect, useState } from 'react'
import { CustomModal } from '@components'
import { apiGenerateQR, apiValidateOTP, apiUpdateOTP, apiGetProfile } from '@store'
import { useDispatch, useSelector } from 'react-redux'
import { Box, FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material'
import { showToast } from '@utils'

const QR = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const { qr, user } = useSelector((state) => state.user)

    useEffect(() => {
        dispatch(apiGenerateQR())
    }, [])

    const [state, setState] = useState({
        username: user?.Username,
        totp: '',
        totp_enabled: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const validateData = {
            totp: state.totp,
            username: state.username
        }

        const resp = await dispatch(apiValidateOTP(validateData))
        if (resp?.meta?.requestStatus === 'fulfilled') {

            const updateData = {
                totp: state.totp,
                totp_enabled: true,
                username: state.username
            }
            const resp = await dispatch(apiUpdateOTP(updateData))
            if (resp.meta.requestStatus === 'fulfilled') {
                showToast('success', '2FA enabled successfully')
                dispatch(apiGetProfile())
                handleClose()
            }
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp?.payload)
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Multi Factor Auth QR Scan`}
            buttonText={'Submit'}
            ref={ref}
        >
            <Box className='flex flex-col gap-3'>
                <img src={qr} alt="qr" />
                <Typography variant='caption' fontStyle={'oblique'}>Please scan the QR code with your authenticator app.</Typography>

                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="accountNameLabel"
                    >
                        Token
                    </InputLabel>
                    <OutlinedInput
                        id="accountName"
                        label="account_name"
                        name="totp"
                        value={state.totp}
                        onChange={handleChange}
                        required
                    />
                </FormControl>

                <Typography variant='caption'>Dismiss modal or click Close to cancel.</Typography>
            </Box>
        </CustomModal>
    )
})

export default QR