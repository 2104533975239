import React, { useEffect } from 'react'
import Accounts from './account/Accounts'
import Endpoint from './endpoint/Endpoint'
import { TabComponent } from '@components'
import { useSelector } from 'react-redux'
import { AppName, SettingsTitle } from '@utils'

const Settings = () => {
    useEffect(() => {
        document.title = `${AppName} | ${SettingsTitle}`
    }, [])

    const { account, isGetAccountLoading } = useSelector((state) => state.account);
    const { endpoint, isGetEndpointLoading } = useSelector((state) => state.endpoint);

    const tabs = [
        {
            name: 'Accounts',
            content: <Accounts endpoint={endpoint} account={account} isLoading={isGetAccountLoading} />,
            // buttons: [
            //     {
            //         text: 'Export Users',
            //         backgroundColor: 'blue',
            //         color: 'white',
            //         onClick: () => {
            //             alert('EUR BUTTON')
            //         }
            //     }
            // ]
        }, {
            name: 'Manage Endpoints',
            content: <Endpoint endpoint={endpoint} isLoading={isGetEndpointLoading} />,
        }
    ]
    return (
        <TabComponent tabs={tabs} />
    )
}

export default Settings