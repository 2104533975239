const available_product_options = [
    { id: 1, name: "THE" },
    { id: 2, name: "THE CHF" },
    { id: 3, name: "TTF" },
    { id: 5, name: "PEG" },
    { id: 6, name: "Power: CH" },
    { id: 8, name: "Orders" },
    { id: 10, name: "Intraday" },
]

const available_contract_options = [
    { id: 1, name: "DA" },
    { id: 2, name: "WDNW" },
    { id: 3, name: "WE" },
    { id: 4, name: "BOW" },
    { id: 5, name: "Months" },
    { id: 6, name: "Years" },
    { id: 7, name: "Quarters" },
    { id: 8, name: "Summers" },
    { id: 9, name: "Winter" },
    { id: 10, name: "GY" },
    { id: 11, name: "BOM" },
]

export {
    available_product_options,
    available_contract_options
}
