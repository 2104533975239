import {
    Box,
    ListItem,
    ListItemButton,
    Typography,
    useMediaQuery
} from '@mui/material';
import UserAvatar from './UserAvatar';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';

export const ChatCard = ({
    imageURL = 'logo192.png',
    userId = 'defaultUserId',
    name = 'Anonymous',
    message = 'No message available',
    time = 'Not specified',
    messageCount = 0,
    isActive = false,
    isGroup = false
}) => {
    if (messageCount > 9) {
        messageCount = '9+';
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab') || 'all';

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={Link}
                sx={{
                    display: 'flex',
                    flexGrow: '1',
                    py: '12px',
                    px: isSmallScreen ? '24px' : '16px'
                }}
                to={`${
                    location.pathname.includes('gas')
                        ? `${GAS_PATH.CHAT}${
                              isGroup ? '/group' : ''
                          }/${userId}?tab=${tab}`
                        : location.pathname.includes('electricity')
                        ? `${ELECTRICITY_PATH.CHAT}${
                              isGroup ? '/group' : ''
                          }/${userId}?tab=${tab}`
                        : `${COMMON_PATH.CHAT}${
                              isGroup ? '/group' : ''
                          }/${userId}?tab=${tab}`
                }`}
            >
                <UserAvatar
                    fullName={name}
                    imgSource={imageURL}
                    isActive={isActive}
                />
                {!isSmallScreen && (
                    <>
                        <Box
                            sx={{
                                flexGrow: 1,
                                ml: '8px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: 'medium',
                                    maxWidth: '120px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {name}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.75rem',
                                    maxWidth: '124px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {message || `Say hi!`}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-end'
                            }}
                        >
                            <Typography sx={{ fontSize: '0.6rem' }}>
                                {/* Unix time to local time */}
                                {time !== 0 && (
                                    <>
                                        {new Date(
                                            parseInt(time) * 1000
                                        ).toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </>
                                )}
                            </Typography>
                            <Box
                                sx={{
                                    height: '18px',
                                    width: messageCount >= 1 ? '24px' : '18px',
                                    bgcolor:
                                        messageCount >= 1
                                            ? 'green'
                                            : 'transparent',
                                    borderRadius: '16px',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {messageCount > 0 && (
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '0.7rem',
                                            lineHeight: '32px',
                                            margin: '0',
                                            marginTop: '1.5px',
                                            marginRight: '1px',
                                            padding: '0'
                                        }}
                                    >
                                        {messageCount}
                                    </Typography>
                                )}
                            </Box>
                        </Box>{' '}
                    </>
                )}
            </ListItemButton>
        </ListItem>
    );
};

ChatCard.propTypes = {
    imageURL: PropTypes.string,
    userId: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
    messageCount: PropTypes.number
};

ChatCard.defaultProps = {
    userId: '',
    name: '',
    message: '',
    time: '',
    messageCount: 0,
    isActive: true
};
