import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    cheChf: [],
    isGetCheChfLoading: false,
}

export const apiGetCheChf = createAsyncThunk(
    "che/getChf",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/ckw/getchdata/chf');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cheSlice = createSlice({
    name: "che",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get TTF EUR
        builder
            .addCase(apiGetCheChf.pending, (state) => {
                state.isGetCheChfLoading = true;
            })
            .addCase(apiGetCheChf.fulfilled, (state, action) => {
                state.cheChf = action.payload.data;
                state.isGetCheChfLoading = false;
            })
            .addCase(apiGetCheChf.rejected, (state) => {
                state.isGetCheChfLoading = false;
            });
    }
});

export default cheSlice.reducer;