import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactTable } from '@components'
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { Box, Button, Modal, Typography } from '@mui/material'
import Buy from './Buy'
import Sell from './Sell'
import { apiDeleteWatchlist, apiGetWatchlist, apiGetTheEur, apiGetChf, apiGetTtfEur, apiGetPegnordEur } from '@store'
import { showToast, AppName, WatchlistTitle } from '@utils'

const Watchlist = () => {
    useEffect(() => {
        document.title = `${AppName} | ${WatchlistTitle}`
    }, [])

    const dispatch = useDispatch()
    const { watchlist, isGetWatchlistLoading } = useSelector((state) => state.watchlist)
    const { user } = useSelector((state) => state.user)
    const { disableTrading } = useSelector(state => state.the);

    const [selectedContract, setSelectedContract] = useState(null)

    // buy modal
    const [openBuyModal, setOpenBuyModal] = useState(false)
    const handleOpenBuyModal = (selectedContract) => {
        setOpenBuyModal(true)
        setSelectedContract(selectedContract)
    }
    const handleCloseBuyModal = () => setOpenBuyModal(false)

    // sell modal
    const [openSellModal, setOpenSellModal] = useState(false)
    const handleOpenSellModal = (selectedContract) => {
        setOpenSellModal(true)
        setSelectedContract(selectedContract)
    }
    const handleCloseSellModal = () => setOpenSellModal(false)


    // define watchlist
    const handleDeleteWatchlist = async (symbol, product) => {
        try {
            const response = await dispatch(apiDeleteWatchlist({ symbol, product }))
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Watchlist removed successfully')
                dispatch(apiGetWatchlist())
                dispatch(apiGetTheEur())
                dispatch(apiGetChf());
                dispatch(apiGetTtfEur());
                dispatch(apiGetPegnordEur());
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response.payload)
            }
        } catch (error) {
            showToast('error', error.message)
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Product",
                id: "Product",
                accessorFn: ({ Product }) => Product || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Contract",
                id: "Symbol",
                accessorFn: ({ Symbol }) => Symbol || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Currency",
                id: "Currency",
                accessorFn: ({ Currency }) => <Typography sx={{ textTransform: 'uppercase' }}>{Currency}</Typography> || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Bid",
                Cell: ({ row }) => <>{row?.original?.Data?.bid}</>,
                size: 50
            },
            {
                header: "Ask",
                Cell: ({ row }) => <>{row?.original?.Data?.ask}</>,
                size: 50
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key={'buttons'}>
                        {disableTrading.disable === false && user?.HideBuyButton === false &&
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        color: 'white',
                                        backgroundColor: '#27A9FF',
                                    },
                                }}
                                onClick={() => handleOpenBuyModal(row?.original)}
                            >
                                Buy
                            </Button>
                        }
                        {disableTrading.disable === false && user?.HideBuyButton === false &&
                            <Button
                                variant='outlined'
                                color='error'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #EB6969',
                                    color: '#EB6969',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #EB6969',
                                        color: 'white',
                                        backgroundColor: '#EB6969',
                                    },
                                }}
                                onClick={() => handleOpenSellModal(row?.original)}
                            >
                                Sell
                            </Button>
                        }
                        <Button
                            variant='outlined'
                            sx={{
                                p: 0,
                                m: 0,
                                color: 'brown',
                                borderColor: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    color: 'white',
                                    borderColor: 'brown !important',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={() => handleDeleteWatchlist(row?.original?.Symbol, row?.original?.Product)}
                        >
                            Delete
                        </Button>
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    return (
        <>
            <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                <Buy
                    handleClose={handleCloseBuyModal}
                    open={openBuyModal}
                    selectedContract={selectedContract}
                    user={user}
                />
            </Modal>
            <Modal open={openSellModal} onClose={handleCloseSellModal}>
                <Sell
                    handleClose={handleCloseSellModal}
                    open={openSellModal}
                    selectedContract={selectedContract}
                    user={user}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                        </div>
                    )
                }
                headerText={'Watchlist'}
                columns={columns}
                data={watchlist || []}
                actions={actionsMenu}
                isLoading={isGetWatchlistLoading}
            />
        </>
    )
}

export default Watchlist