import React from "react";
import { ChatOverlay } from "@components";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { TopbarWithLogo } from "@components";

const LayoutWithoutSidebar = () => {
    return (
        <div className="app">
            <main className="content">
                <Box className='sticky top-0 z-10'>
                    <TopbarWithLogo />
                </Box>
                <Box className='mt-5 mb-3 w-[95%] mx-auto overflow-y-auto'>
                    <Outlet />
                </Box>
                <ChatOverlay />
            </main>
        </div>
    );
};

export default LayoutWithoutSidebar;