import ComputerLogo from '../assets/images/computer.png';
import IdeaLogo from '../assets/images/idea.png';
import NanoLogo from '../assets/images/nano.png';

export const loginPageData = [
    {
        id: 1,
        title: 'Platform',
        description: 'A platform to publish your data and connect with your customers.',
        image: ComputerLogo,
    },
    {
        id: 2,
        title: 'Ideas',
        description: 'Bespoke software service to implement your ideas into reality.',
        image: IdeaLogo,
    },
    {
        id: 3,
        title: 'Technology',
        description: 'Peer to peer publishing and trading technology that focuses on your needs.',
        image: NanoLogo,
    },
];