import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { Box, Button, Typography } from '@mui/material';
import { ReactTable } from '@components';
import { showToast } from '@utils'
import { apiBuyPriceProfile } from '@store';

const YearOne = ({ singlePriceProfile, loading, call, put, volume }) => {
    const dispatch = useDispatch();
    const { uuid } = useParams();
    const [yearOneTotalState, setYearOneTotalState] = useState(0);
    const [yearOneVolState, setYearOneVolState] = useState(0);

    useEffect(() => {
        let tempYearOneTotal = 0;
        let tempYearOneVol = 0;

        for (let i in singlePriceProfile) {
            tempYearOneVol += singlePriceProfile[i]?.Total;
            tempYearOneTotal += singlePriceProfile[i]?.PriceTotal;
        }

        setYearOneTotalState(tempYearOneTotal);
        setYearOneVolState(tempYearOneVol);
    }, [singlePriceProfile]);


    // const [state , setState] = useState({

    const handleBuy = async (e) => {
        e.preventDefault();

        const year = singlePriceProfile ? singlePriceProfile[0].Year : 0;

        if (year === 0) {
            showToast('error', 'Year not found');
            return;
        }

        let tempYearOneTotal = 0;
        let tempYearOneVol = 0;

        for (let i in singlePriceProfile) {
            tempYearOneVol += singlePriceProfile[i]?.Total;
            tempYearOneTotal += singlePriceProfile[i]?.PriceTotal;
        }

        const body = {
            entry: singlePriceProfile,
            total: tempYearOneTotal,
            volume: tempYearOneVol
        }

        const params = {
            uuid: uuid,
            volume: parseFloat(volume),
            call: parseFloat(call),
            put: parseFloat(put),
            year: year
        }

        const resp = await dispatch(apiBuyPriceProfile({ params, body }));
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Successfully bought the profile');
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload);
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Month",
                id: "MonthStr",
                accessorFn: ({ MonthStr }) => MonthStr || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Year",
                id: "Year",
                accessorFn: ({ Year }) => Year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box className='flex flex-col'>
                        <Box>Total Vol</Box>
                        <Box>[MWh]</Box>
                    </Box>
                ),
                id: "TotalStr",
                accessorFn: ({ TotalStr }) => TotalStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Hours/Month",
                id: "MonthHours",
                accessorFn: ({ MonthHours }) => MonthHours || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "MWh/h",
                id: "TotalMWStr",
                accessorFn: ({ TotalMWStr }) => TotalMWStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Price",
                id: "AskPriceStr",
                accessorFn: ({ AskPriceStr }) => AskPriceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Flex Price",
                id: "FlexPriceStr",
                accessorFn: ({ FlexPriceStr }) => FlexPriceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box className='flex flex-col'>
                        <Box>Min Take</Box>
                        <Box>[MWh/h]</Box>
                    </Box>
                ),
                id: "MinTakeStr",
                accessorFn: ({ MinTakeStr }) => MinTakeStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box className='flex flex-col'>
                        <Box>Max Take</Box>
                        <Box>[MWh/h]</Box>
                    </Box>
                ),
                id: "MaxTakeStr",
                accessorFn: ({ MaxTakeStr }) => MaxTakeStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Cost [EUR]",
                id: "PriceTotalStr",
                accessorFn: ({ PriceTotalStr }) => PriceTotalStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50,
                Footer: () =>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1
                        }}
                    >
                        <Box
                            fontSize={18}
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                        >
                            <Typography>Avg Price: </Typography>
                            <span
                                className='text-green-700'
                            >
                                {(yearOneTotalState / yearOneVolState).toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                        </Box>
                        <Button
                            variant='outlined'
                            color='primary'
                            sx={{
                                p: 0,
                                m: 0,
                                backgroundColor: '#27A9FF',
                                border: '1px solid #27A9FF',
                                color: '#fff',
                                fontSize: '13px',
                                '&:hover': {
                                    color: 'white',
                                    backgroundColor: '#27A9FF',
                                    border: '1px solid #27A9FF',
                                },
                            }}
                            onClick={handleBuy}
                        >
                            Buy
                        </Button>
                    </Box>,
            },
        ], [yearOneTotalState, yearOneVolState]
    );

    return (
        <ReactTable
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                    </div>
                )
            }
            headerText={'Year One'}
            columns={columns}
            data={singlePriceProfile || []}
            isLoading={loading}
        />
    )
}

export default YearOne