import React, { useEffect } from 'react';

import { Box, Button } from '@mui/material';
import { useGetChatProfileQuery } from '@store/feature/chat/chatApi';
import { Link, useLocation } from 'react-router-dom';

const ChatSidebarTop = () => {
    const location = useLocation();

    const [tab, setTab] = React.useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');
        setTab(tabFromUrl || 'all');
    }, [location.search]);

    const { data: profile, isLoading: isGetProfileLoading } =
        useGetChatProfileQuery();

    const addQueryParamWithoutRepeating = (param, value) => {
        const searchParams = new URLSearchParams(location.search);

        searchParams.set(param, value);
        const baseUrl = location.pathname;

        return `${
            location.pathname.includes('gas')
                ? `${baseUrl}?tab=${value}`
                : location.pathname.includes('electricity')
                ? `${baseUrl}?tab=${value}`
                : `${baseUrl}?tab=${value}`
        }`;
    };

    return (
        <React.Fragment>
            {/* Add buttons for Single Chats, Group Chats, and All */}

            <Box
                sx={{
                    '& > button': { mx: 1 },
                    backgroundColor: '#f0f0f0',
                    my: 2,
                    mx: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: '4px',
                    py: '3px',
                    mt: '10px',
                    position: 'sticky',
                    top: '0',
                    borderRadius: '5px'
                }}
            >
                <Button
                    component={Link}
                    to={addQueryParamWithoutRepeating('tab', 'all')}
                    sx={{
                        backgroundColor: tab === 'all' ? '#2facfe' : 'initial',
                        color: tab === 'all' ? 'white' : 'initial',
                        fontSize: '11px',
                        padding: '4px 16px', // Increase the length of the button
                        '&:hover': {
                            backgroundColor: '#2facfe',
                            color: 'white'
                        },
                        marginRight: '5px',
                        width: '50%'
                    }}
                >
                    All
                </Button>

                <Button
                    component={Link}
                    to={addQueryParamWithoutRepeating('tab', 'groups')}
                    sx={{
                        backgroundColor:
                            tab === 'groups' ? '#2facfe' : 'initial',
                        color: tab === 'groups' ? 'white' : 'initial',
                        fontSize: '11px',
                        padding: '4px 16px', // Increase the length of the button

                        '&:hover': {
                            backgroundColor: '#2facfe',
                            color: 'white'
                        },
                        width: '50%'
                    }}
                >
                    Groups
                </Button>
            </Box>
        </React.Fragment>
    );
};

export default ChatSidebarTop;
