import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { ReactTable, Filter } from '@components'
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { Link } from 'react-router-dom';
import { GAS_PATH, showToast } from '@utils';
import PriceNotification from './PriceNotification';
import Buy from './Buy';
import Sell from './Sell';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { apiGetWatchlist, apiSaveToWatchlist, apiGetTheEur, apiGetMinMaxDisplay } from '@store';
import SetTrailingStop from './SetTrailingStop';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const EUR =
    ({
        eur,
        isLoading,
        minMaxDisplay,
        trailingStop,
        openModal,
        handleCloseModal,
        openTrailingStopModal,
        handleCloseTrailingStopModal,
        openFilterModal,
        hanldeOpenFilterModal,
        handleCloseFilterModal,
        disableTradingStatus
    }) => {
        const dispatch = useDispatch()
        const { user } = useSelector((state) => state.user);
        const { limitOrder } = useSelector((state) => state.notification);

        const dataWithTS = useMemo(() => {
            const lastPrices = {}

            eur?.symbols?.forEach(symbol => {
                let lastBidPrice = null;
                for (let i = limitOrder?.length - 1; i >= 0; i--) {
                    if (limitOrder[i]?.contract === symbol && limitOrder[i]?.type === 'bid' && limitOrder[i]?.market === 'THE') {
                        lastBidPrice = limitOrder[i]
                        break
                    }
                }

                let lastAskPrice = null;
                for (let i = limitOrder?.length - 1; i >= 0; i--) {
                    if (limitOrder[i]?.contract === symbol && limitOrder[i]?.type === 'ask' && limitOrder[i]?.market === 'THE') {
                        lastAskPrice = limitOrder[i]
                        break
                    }
                }

                lastPrices[symbol] = {
                    lastBidPrice: lastBidPrice?.price_gt || lastBidPrice?.price_lt || 0,
                    lastAskPrice: lastAskPrice?.price_gt || lastAskPrice?.price_lt || 0
                }
            })

            const tempData = eur?.symbols?.map(symbol => {
                try {
                    return {
                        contract: symbol,
                        lastBidPrice: lastPrices[symbol]?.lastBidPrice,
                        lastAskPrice: lastPrices[symbol]?.lastAskPrice,
                        ...eur?.contracts[symbol],
                        ...minMaxDisplay[symbol],
                        ...trailingStop[symbol]
                    }
                } catch (error) {
                    return {
                        contract: symbol,
                        lastBidPrice: 0,
                        lastAskPrice: 0,
                        ...eur?.contracts[symbol],
                        ...minMaxDisplay[symbol],
                        ...trailingStop[symbol]
                    }
                }
            }) || []
            return tempData
        }, [eur, minMaxDisplay, trailingStop, limitOrder])

        const [selectedContract, setSelectedContract] = useState(null)

        // buy modal
        const [openBuyModal, setOpenBuyModal] = useState(false)
        const handleOpenBuyModal = useCallback((selectedContract) => {
            setSelectedContract(selectedContract)
            setOpenBuyModal(true)
        }, [])
        const handleCloseBuyModal = () => setOpenBuyModal(false)

        // sell modal
        const [openSellModal, setOpenSellModal] = useState(false)
        const handleOpenSellModal = useCallback((selectedContract) => {
            setSelectedContract(selectedContract)
            setOpenSellModal(true)
        }, [])
        const handleCloseSellModal = useCallback(() => setOpenSellModal(false), [])

        // add to watchlist
        const handleAddToWatchlist = useCallback(async (symbol, product) => {
            try {
                const response = await dispatch(apiSaveToWatchlist({ symbol, product }))
                if (response.meta.requestStatus === 'fulfilled') {
                    showToast('success', 'Added to watchlist')
                    dispatch(apiGetWatchlist())
                    dispatch(apiGetTheEur())
                    dispatch(apiGetMinMaxDisplay())
                } else if (response.meta.requestStatus === 'rejected') {
                    showToast('error', response.error.message)
                }
            } catch (error) {
                showToast('error', error.message)
            }
        }, [dispatch])

        const columns = useMemo(
            () => [
                {
                    header: "Contract",
                    id: "contract",
                    accessorFn: ({ contract }) => contract || "",
                    Cell: ({ renderedCellValue }) =>
                        <Typography
                            sx={{
                                color: '#3B9D9D',
                                fontSize: 'inherit',
                                fontWeight: 'bold',
                                pl: '8px',
                            }}
                        >
                            {renderedCellValue}
                        </Typography>,
                    size: 50
                },
                {
                    header: "Bid",
                    id: "bid",
                    Cell: ({ row }) =>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Typography>{row.original?.bid}</Typography>
                            <Typography
                                sx={{
                                    opacity: 0.5,
                                    fontSize: '14px',
                                    marginLeft: '4px'
                                }}
                            >
                                {row?.original?.lastBidPrice === 0 ? '' : row?.original?.lastBidPrice}
                            </Typography>
                        </Box>,
                    size: 50
                },
                {
                    header: "Ask",
                    id: "ask",
                    Cell: ({ row }) =>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <Typography>{row.original?.ask}</Typography>
                            <Typography
                                sx={{
                                    opacity: 0.5,
                                    fontSize: '14px',
                                }}
                            >
                                {row?.original?.lastAskPrice === 0 ? '' : row?.original?.lastAskPrice}
                            </Typography>
                        </Box>,
                    size: 50
                },
                {
                    header: "Max",
                    id: "maxbuy",
                    Cell: ({ row }) => <>
                        {
                            row?.original?.maxbuy ?
                                <Link
                                    to={GAS_PATH.NOTIFICATIONS}
                                    className='underline cursor-pointer text-sky-600'
                                >
                                    {`B:${row?.original?.maxbuy?.toFixed(3)}`}
                                </Link> :
                                <Link
                                    to={GAS_PATH.NOTIFICATIONS}
                                    className='underline cursor-pointer text-sky-600'
                                >
                                    {row?.original?.maxsell ? `S:${row?.original?.maxsell?.toFixed(3)}` : ""}
                                </Link>
                        }
                    </>,
                    size: 50
                },
                {
                    header: "Min",
                    id: "minbuy",
                    Cell: ({ row }) => <>
                        {
                            row?.original?.minbuy ?
                                <Link
                                    to={GAS_PATH.NOTIFICATIONS}
                                    className='underline cursor-pointer text-sky-600'
                                >
                                    {`B:${row?.original?.minbuy?.toFixed(3)}`}
                                </Link> :
                                <Link
                                    to={GAS_PATH.NOTIFICATIONS}
                                    className='underline cursor-pointer text-sky-600'
                                >
                                    {row?.original?.minsell ? `S:${row?.original?.minsell?.toFixed(3)}` : ""}
                                </Link>
                        }
                    </>,
                    size: 50
                },
                {
                    header: "Ceiling",
                    id: "ceiling",
                    Cell: ({ row }) =>
                        <Link
                            to={GAS_PATH.NOTIFICATIONS}
                            className='underline cursor-pointer text-sky-600'
                        >
                            {row?.original[0]?.ceiling.toFixed(3)}
                        </Link>,
                    size: 50
                },
                {
                    header: "Floor",
                    id: "floor",
                    Cell: ({ row }) =>
                        <Link
                            to={GAS_PATH.NOTIFICATIONS}
                            className='underline cursor-pointer text-sky-600'
                        >
                            {row?.original[0]?.floor.toFixed(3)}
                        </Link>,
                    size: 50
                },
                {
                    header: "Updated At",
                    id: "updated_at_cet_str",
                    accessorFn: ({ updated_at_cet_str }) => updated_at_cet_str || "",
                    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                    size: 50
                },
            ], []
        );

        const actionsMenu = useMemo(
            () =>
                ({ row }) =>
                    [
                        <Box
                            className='flex gap-4 items-center justify-center'
                            key={'button'}
                            onClick={(e) => { e.stopPropagation() }}
                        >
                            {disableTradingStatus === false && user?.HideBuyButton === false &&
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #27A9FF',
                                        color: '#27A9FF',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #27A9FF',
                                            color: 'white',
                                            backgroundColor: '#27A9FF',
                                        },
                                    }}
                                    onClick={() => handleOpenBuyModal(row.original)}
                                >
                                    Buy
                                </Button>
                            }
                            {disableTradingStatus === false && user?.HideSellButton === false &&
                                <Button
                                    variant='outlined'
                                    color='error'
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #EB6969',
                                        color: '#EB6969',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #EB6969',
                                            color: 'white',
                                            backgroundColor: '#EB6969',
                                        },
                                    }}
                                    onClick={() => handleOpenSellModal(row.original)}
                                >
                                    Sell
                                </Button>
                            }
                            <Tooltip title='Add to watchlist'>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        // backgroundColor: row.original.watchlist ? '#FFC107' : '',
                                        color: row.original.watchlist ? 'white' : '#245372',
                                        borderColor: '#245372',
                                        width: '64px',
                                        height: '24.75px',
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: '#245372',
                                        },
                                    }}
                                    onClick={() => handleAddToWatchlist(row.original.contract, 'THE')}
                                    disabled={row.original.watchlist ? true : false}
                                >
                                    {row.original.watchlist ?
                                        <StarIcon
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                fontSize: '18px',
                                                color: '#FFC107'
                                            }}
                                        /> :
                                        <StarBorderIcon sx={{
                                            p: 0,
                                            m: 0,
                                            fontSize: '18px',
                                        }} />
                                    }
                                </Button>
                            </Tooltip>
                        </Box>
                    ],
            [disableTradingStatus, user, handleOpenBuyModal, handleOpenSellModal, handleAddToWatchlist]
        );

        // filter
        const [filteredData, setFilteredData] = useState([])

        const [filter, setFilter] = useState({
            typeOfContract: '',
            bidPrice: {
                min: '',
                max: ''
            },
            askPrice: {
                min: '',
                max: ''
            }
        })

        useEffect(() => {
            if (dataWithTS?.length > 0) {
                if (filter.typeOfContract && filter.typeOfContract === "yearly") {
                    setFilteredData(dataWithTS?.filter(item => item.contract.includes('CAL')) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "monthly") {
                    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
                    setFilteredData(dataWithTS?.filter(item => months.includes(item.contract.slice(0, 3))) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "quarterly") {
                    setFilteredData(dataWithTS?.filter(item => item.contract.includes('Q')) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "winter") {
                    setFilteredData(dataWithTS?.filter(item => item.contract.includes('WINTER')) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "summer") {
                    setFilteredData(dataWithTS?.filter(item => item.contract.includes('SUMMER')) || [])
                } else {
                    setFilteredData(dataWithTS)
                }
            }
        }, [filter, dataWithTS])

        useEffect(() => {
            if (dataWithTS?.length > 0) {
                if (filter.bidPrice.min && filter.bidPrice.max) {
                    setFilteredData(dataWithTS?.filter(item => item.bid >= filter.bidPrice.min && item.bid <= filter.bidPrice.max) || [])
                } else if (filter.bidPrice.min) {
                    setFilteredData(dataWithTS?.filter(item => item.bid >= filter.bidPrice.min) || [])
                } else if (filter.bidPrice.max) {
                    setFilteredData(dataWithTS?.filter(item => item.bid <= filter.bidPrice.max) || [])
                } else {
                    setFilteredData(dataWithTS)
                }
            }
        }, [filter.bidPrice, dataWithTS])

        useEffect(() => {
            if (dataWithTS?.length > 0) {
                if (filter.askPrice.min && filter.askPrice.max) {
                    setFilteredData(dataWithTS?.filter(item => item.ask >= filter.askPrice.min && item.ask <= filter.askPrice.max) || [])
                } else if (filter.askPrice.min) {
                    setFilteredData(dataWithTS?.filter(item => item.ask >= filter.askPrice.min) || [])
                } else if (filter.askPrice.max) {
                    setFilteredData(dataWithTS?.filter(item => item.ask <= filter.askPrice.max) || [])
                } else {
                    setFilteredData(dataWithTS)
                }
            }
        }, [filter.askPrice, dataWithTS])

        return (
            <Box>
                {/* price notifiction modal */}
                <Modal open={openModal} onClose={handleCloseModal}>
                    <PriceNotification
                        handleClose={handleCloseModal}
                        open={openModal}
                        eur={eur}
                    />
                </Modal>
                {/* set trailing stop modal */}
                <Modal open={openTrailingStopModal} onClose={handleCloseTrailingStopModal}>
                    <SetTrailingStop
                        handleClose={handleCloseTrailingStopModal}
                        open={openTrailingStopModal}
                        eur={eur}
                    />
                </Modal>
                {/* buy modal */}
                <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                    <Buy
                        handleClose={handleCloseBuyModal}
                        open={openBuyModal}
                        eur={eur}
                        selectedContract={selectedContract}
                        user={user}
                    />
                </Modal>
                {/* sell modal */}
                <Modal open={openSellModal} onClose={handleCloseSellModal}>
                    <Sell
                        handleClose={handleCloseSellModal}
                        open={openSellModal}
                        eur={eur}
                        selectedContract={selectedContract}
                        user={user}
                    />
                </Modal>
                {/* filter modal */}
                <Modal open={openFilterModal} onClose={handleCloseFilterModal}>
                    <Filter
                        handleClose={handleCloseFilterModal}
                        open={openFilterModal}
                        filter={filter}
                        setFilter={setFilter}
                    />
                </Modal>
                <ReactTable
                    renderToolbarInternalActions={
                        ({ table }) => (
                            <div onMouseDown={(e) => e.stopPropagation()}>
                                <MRT_ToggleGlobalFilterButton table={table} />
                                <Tooltip title='Filter'>
                                    <IconButton
                                        onClick={hanldeOpenFilterModal}
                                    >
                                        <FilterAltOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    }
                    headerText={'EUR'}
                    columns={columns}
                    data={filteredData || []}
                    actions={actionsMenu}
                // isLoading={isLoading}
                />
            </Box>
        )
    }

export default EUR