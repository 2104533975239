import React, { useEffect } from 'react'
import Edit from './Edit'
import { Box, Grid } from '@mui/material'
import FA from './2FA'
import ProfileInfo from './ProfileInfo'
import Help from './Help'
import { useSelector } from 'react-redux'
import { AppName, ProfileTitle } from '@utils'

const Profile = () => {
    useEffect(() => {
        document.title = `${AppName} | ${ProfileTitle}`
    }, [])

    const { user } = useSelector((state) => state.user);

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={6}>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <FA user={user} />
                    <ProfileInfo user={user} />
                    <Help />
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Edit user={user} />
            </Grid>
        </Grid>
    )
}

export default Profile