import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HelpQA } from '@data';
import { Box } from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
import { AppName, HelpTitle } from '@utils';

const Help = () => {
    useEffect(() => {
        document.title = `${AppName} | ${HelpTitle}`
    }, [])

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Typography sx={{ pb: '10px', fontSize: '18px' }}>Questions and Answers</Typography>
            <Box className='px-4 flex flex-col'>
                <Box className='pb-10'>
                    {HelpQA.map((qa, index) => (
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                                sx={{ bgcolor: '#216583', color: 'white', borderTop: '1px solid white' }}
                            >
                                <Typography sx={{ flexShrink: 0, fontSize: '18px' }}>
                                    {qa.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {qa.answer}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
                <Box className='flex flex-col items-center'>
                    <Typography sx={{ pb: '8px', fontWeight: 'bold', fontSize: '30px' }}>Still have some questions?</Typography>
                    <Typography sx={{ pb: '8px', fontSize: '16px' }}>If you cannot find the answer to your question in our FAQ, you can always contact us.</Typography>
                    <Box className='mt-6'>
                        <Box className='flex items-center gap-4'>
                            <DraftsIcon fontSize='small' />
                            <Typography >
                                <Link to="mailto: info@priceblick.com " className='text-blue-500'>
                                    info@priceblick.com
                                </Link>
                            </Typography>
                        </Box>
                        <Box className='flex items-center gap-4'>
                            <PhoneIcon fontSize='small' />
                            <Typography >
                                <Link to="tel: 090657891234" className='text-blue-500'>
                                    +44 07714138214
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Help