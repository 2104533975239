import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useUnblockChatUserMutation } from '@store/feature/chat/chatApi'

const UnBlockModal = forwardRef(({ handleClose }, ref) => {
    const { id } = useParams()

    const [unblockChatUser] = useUnblockChatUserMutation()

    const handleSubmit = (e) => {
        e.preventDefault()
        unblockChatUser(id)
        handleClose()
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Unblock The Contact`}
            buttonText={'Unblock'}
            modalTitleColor={'#EB6969'}
            className='modal-button-danger'
            ref={ref}
        >
            <Typography
                sx={{
                    fontSize: '14px'
                }}
            >
                Unblock this contact?
            </Typography>
        </CustomModal>
    )
})

export default UnBlockModal