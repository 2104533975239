import React, { useEffect } from 'react';
import {
    Button,
    Grid,
    Box,
    Typography,
    IconButton,
    OutlinedInput,
    InputAdornment,
    FormControl,
    InputLabel
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import KeyIcon from '@mui/icons-material/Key';
import MailIcon from '@mui/icons-material/Mail';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import { chatAPI } from '@utils/api';
import useDebounce from '@hooks/useDebounce';
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH, showToast } from '@utils';
import { AppName, ChatLoginTitle } from '@utils';

export const LoginTemp = () => {

    useEffect(() => {
        document.title = `${AppName} | ${ChatLoginTitle}`
    }, [])

    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        username: '',
        password: '',
        showPassword: false
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleLogin = useDebounce(async () => {
        try {
            const response = await chatAPI.post('/chat/login', {
                username: state.username,
                password: state.password
            });

            if (response?.status === 200) {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem(
                    'refresh_token',
                    response.data.refresh_token
                );
                localStorage.setItem('auth', 'true');
                navigate(location?.pathname?.includes('gas') ?
                    GAS_PATH.CHAT : location?.pathname?.includes('electricity') ?
                        ELECTRICITY_PATH.CHAT : COMMON_PATH.CHAT);
            }
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('auth');
                localStorage.removeItem('refresh_token');
                showToast('error', 'Invalid credentials', 'top-right');
            } else {
                console.log('Error occurred, please try again', error);
            }
        }
    }, 500);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin();
    };

    return (
        <Box sx={styles.root}>
            <Box sx={styles.container}>
                <Box sx={styles.icon}>
                    <LockOutlinedIcon sx={{ fontSize: '30px' }} />
                </Box>
                <Typography component="h1" variant="h5">
                    Log In
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel htmlFor="login-username">
                                    Username
                                </InputLabel>
                                <OutlinedInput
                                    label="Username"
                                    value={state.username}
                                    name="username"
                                    autoComplete="username"
                                    onChange={handleChange}
                                    id="login-username"
                                    required
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel htmlFor="login-password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    label="Password"
                                    value={state.password}
                                    autoComplete="current-password"
                                    onChange={handleChange}
                                    name="password"
                                    type={
                                        state.showPassword ? 'text' : 'password'
                                    }
                                    id="login-password"
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() =>
                                                    setState({
                                                        ...state,
                                                        showPassword:
                                                            !state.showPassword
                                                    })
                                                }
                                            >
                                                {state.showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <KeyIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={styles.button}
                    >
                        Log In
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        border: '1px solid #ccc',
        padding: '40px',
        borderRadius: '3px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff'
    },
    icon: {
        color: '#fff',
        backgroundColor: '#F50057',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
    },
    button: {
        fontWeight: 'bold',
        marginTop: '20px',
        backgroundColor: '#3F51B5',
        height: '50px',
        '&:active': {
            backgroundColor: '#3F51B5'
        },
        '&:hover': {
            backgroundColor: '#3F51B5'
        }
    }
};
