import React from 'react';
import MessageBox from './MessageBox';

const GroupMessageBox = ({ groupMessages, profile }) => {
    if (!groupMessages || groupMessages.length === 0) {
        return null; // or return a placeholder UI
    }

    const firstMessage = groupMessages[0];

    return (
        <div className="flex flex-col pb-10">
            <div
                className={`flex flex-col ${
                    groupMessages[0].from === profile.uuid
                        ? 'items-end'
                        : 'items-start'
                }`}
            >
                <div className="flex gap-0 items-end ml-[26px]">
                    <span
                        className={`text-sm font-normal text-[12px] ${
                            groupMessages[0].from === profile.uuid
                                ? 'text-right'
                                : 'text-left'
                        } mx-2 `}
                    >
                        {firstMessage.from !== profile.uuid && (
                            <span className="px-[8px] text-normal">
                                {groupMessages[0].user.fullname}
                            </span>
                        )}
                    </span>
                </div>
                <div className="flex">
                    <div>
                        {groupMessages.map((message, i) => {
                            return (
                                <div
                                    key={message.id}
                                    className="flex gap-0 items-end mx-2"
                                >
                                    {firstMessage.from !== profile.uuid && (
                                        <img
                                            src={
                                                groupMessages[0].user.image_url
                                            }
                                            alt="group image"
                                            className="w-5 h-5 rounded-full mb-2 ml-2"
                                            style={{
                                                visibility:
                                                    i ===
                                                    groupMessages.length - 1
                                                        ? 'visible'
                                                        : 'hidden'
                                            }}
                                        />
                                    )}

                                    <MessageBox
                                        id={message.uuid}
                                        messageText={message?.body}
                                        timestamp={message?.created_at}
                                        isRight={
                                            message?.from === profile?.uuid
                                        }
                                        status={message.status}
                                        files={message.files || []}
                                        from={message.from}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupMessageBox;
