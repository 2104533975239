import React from 'react';

const useChatAuth = () => {
    const token = localStorage.getItem('token');
    const auth = localStorage.getItem('auth');

    return { auth: token && auth === 'true' };
};

export default useChatAuth;
