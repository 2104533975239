import React, { useState } from 'react'
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Overview = ({ announcement }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box className='px-4'>
            {announcement?.slice(0, 3).map((announce, index) => (
                <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                        sx={{ bgcolor: '#216583', color: 'white', borderTop: '1px solid white' }}
                    >
                        <Typography>{announce.Date}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: '22px 34px', color: '#245372' }}>
                        <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }}>
                            {announce.Headline}
                        </Typography>
                        <Typography>
                            {announce.Display}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}

export default Overview