import React, { useMemo } from 'react'
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { Box, Modal, Typography } from '@mui/material';
import AddEntry from './AddEntry';

const OrderLog = ({ orderLogs, isLoading, openModal, handleCloseModal }) => {
    const columns = useMemo(
        () => [
            {
                header: "Contract",
                id: "contract",
                Cell: ({ row }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{row?.original?.data?.sym}</Typography>,
                size: 50
            },
            {
                header: "Type",
                id: "type",
                Cell: ({ row }) => <>{
                    row?.original?.data?.type === 'sell' ?
                        <Typography sx={{ fontSize: 'inherit', color: 'rgba(235, 105, 105, 1)' }}>Sell</Typography> :
                        <Typography sx={{ fontSize: 'inherit', color: 'rgba(39, 169, 255, 1)' }}>Buy</Typography>
                }</>,
                size: 50
            },
            {
                header: "Status",
                id: "order_status",
                accessorFn: ({ order_status }) => order_status || "Completed",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Price",
                id: "order_price_str",
                accessorFn: ({ order_price_str }) => order_price_str?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume",
                id: "volume_str",
                accessorFn: ({ volume_str }) => volume_str?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Details",
                Cell: ({ row }) => <>{
                    <Box className='flex flex-col'>
                        <Box className='flex gap-1 items-center text-inherit'>
                            <Typography sx={{ fontSize: 'inherit', p: 0, m: 0 }}>Buyer:</Typography>
                            {row.original?.data?.buyer}
                        </Box>
                        <Box className='flex gap-1 items-center text-inherit'>
                            <Typography sx={{ fontSize: 'inherit', p: 0, m: 0 }}>Seller:</Typography>
                            {row.original?.data?.seller}
                        </Box>
                        <Box className='flex gap-1 items-center text-inherit'>
                            <Typography sx={{ fontSize: 'inherit', p: 0, m: 0 }}>Delivery Point:</Typography>
                            {row.original?.data?.deliverypoint}
                        </Box>
                    </Box>
                }</>,
                size: 50
            },
            {
                header: "Account",
                id: "username",
                accessorFn: ({ username }) => username || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Created At",
                id: "created_at",
                accessorFn: ({ created_at }) => created_at || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    return (
        <>
            <Modal open={openModal} onClose={handleCloseModal}>
                <AddEntry
                    handleClose={handleCloseModal}
                    open={openModal}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            {/* <IconButton
                            onClick={handleOpenModal}
                            title="Create Exchange"
                        >
                            <AddBoxIcon />
                        </IconButton> */}
                        </div>
                    )
                }
                headerText={'Order Logs'}
                columns={columns}
                data={orderLogs || []}
                isLoading={isLoading}
            />
        </>
    )
}

export default OrderLog