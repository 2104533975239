import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { apiAddOrderLog } from '@store'
import { showToast } from '@utils'

const AddEntry = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.user);

    const [state, setState] = useState({
        CompanyShort: user?.CompanyShort,
        orderTypes: [
            "Buy",
            "Sell"
        ],
        type: '',
        buyer: user?.CompanyShort,
        seller: '',
        volume: '',
        sym: '',
        price: '',
        price_percent: "100",
        ordertype: "external"
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // convert price and volume to number
        const price = parseFloat(state.price)
        const volume = parseFloat(state.volume)

        const data = {
            ...state,
            price,
            volume
        }

        const resp = await dispatch(apiAddOrderLog(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Order entry added successfully')
            handleClose()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'An error occurred')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            modalTitle={`Add New Order Entry`}
            buttonText={'Add New Entry'}
            handleSubmit={handleSubmit}
            ref={ref}
        >
            <Typography variant='caption'>Add manual orders done outside of the system for record keeping</Typography>
            <Box className='flex gap-3 mt-6'>
                <Box className='flex flex-col gap-3'>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="contractLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Contract
                        </InputLabel>
                        <OutlinedInput
                            id="contract"
                            label="Contract"
                            name="sym"
                            onChange={handleChange}
                            value={state.sym}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Order Type"
                            name='type'
                            onChange={handleChange}
                            value={state.type}
                            required
                        >
                            <MenuItem value='buy'>Buy</MenuItem>
                            <MenuItem value='sell'>Sell</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="transactionWithLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Transaction with
                        </InputLabel>
                        <OutlinedInput
                            id="transaction_with"
                            label="Transaction with"
                            name="seller"
                            onChange={handleChange}
                            value={state.seller}
                            required
                        />
                    </FormControl>
                </Box>
                <Box className='flex flex-col gap-3'>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="priceLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Price
                        </InputLabel>
                        <OutlinedInput
                            id="price"
                            label="Price"
                            name="price"
                            onChange={handleChange}
                            value={state.price}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="contractLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}

                        >
                            Volume
                        </InputLabel>
                        <OutlinedInput
                            id="volume"
                            label="Volume"
                            name="volume"
                            onChange={handleChange}
                            value={state.volume}
                            required
                            endAdornment={
                                <InputAdornment position="start">
                                    MW
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default AddEntry