import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@utils';
import { apiSaveTrailingStop, apiGetTrailingStopForDisplay, apiGetTrailingStop, apiGetTheEur } from '@store';

const SetTrailingStop = forwardRef(({ handleClose, eur }, ref) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [state, setState] = React.useState({
        market: "THE",
        type: "",
        username: "admin",
        email: "sulochan@gmail.com",
        UserId: user?.UserId,
        contract: "",
        ceiling: "",
        floor: "",
        cmargin: 0,
        fmargin: 0,
        auto_trade: "no",
        provider: "SET",
        company: "Test Company",
        companyshort: "TC",
        volume: "",
        buyer: "TC",
        seller: "SET",
        exitpoint: "",
        price: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const handleContractChange = (e) => {
        const contract = e.target.value;

        // get the current price of the selected contract
        const price = eur?.contracts[contract]?.bid;
        const ceiling = (parseFloat(state.cmargin) + parseFloat(eur?.contracts[contract]?.bid)).toFixed(3);
        const floor = (parseFloat(eur?.contracts[contract]?.bid) - parseFloat(state.fmargin)).toFixed(3);

        setState({
            ...state,
            contract,
            price,
            ceiling,
            floor
        });
    }

    const handleCeilingMarginChange = (e) => {
        const cmargin = e.target.value;
        const ceiling = parseFloat(cmargin) + parseFloat(state.price);
        setState({
            ...state,
            cmargin,
            ceiling
        });
    }

    const handleFloorMarginChange = (e) => {
        const fmargin = e.target.value;
        const floor = parseFloat(state.price) - parseFloat(fmargin);
        setState({
            ...state,
            fmargin,
            floor
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // convert cmargin, fmargin , price and volume to float
        state.cmargin = parseFloat(state.cmargin) || 0;
        state.fmargin = parseFloat(state.fmargin) || 0;
        state.price = parseFloat(state.price);
        state.volume = parseFloat(state.volume);
        state.ceiling = parseFloat(state.ceiling);
        state.floor = parseFloat(state.floor);

        const exitpoint = user?.Endpoints.find(endpoint => endpoint.uuid === state.exitpoint)

        const data = {
            ...state,
            exitpoint
        }

        const resp = await dispatch(apiSaveTrailingStop(data));
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(apiGetTrailingStopForDisplay())
            dispatch(apiGetTrailingStop())
            dispatch(apiGetTheEur())
            handleClose();
            showToast('success', 'Trailing Stop set successfully');
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'An error occurred');
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Set Trailing Stop`}
            buttonText={'Set Trailing Stop'}
            disabled={state.volume ? false : true}
            ref={ref}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 2
                    }}
                >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="demo-simple-select-label"
                        >
                            Contract
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Contract"
                            name='contract'
                            value={state.contract}
                            onChange={handleContractChange}
                            required
                        >
                            {eur?.symbols?.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="demo-simple-select-label"
                        // sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Bid/Ask
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Bid/Ask"
                            name='type'
                            value={state.type}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value='ask'>Buy</MenuItem>
                            <MenuItem value='bid'>Sell</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="currentPriceLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Current Price
                        </InputLabel>
                        <OutlinedInput
                            id="Current Price"
                            label="currentPriceLabel"
                            name="price"
                            value={state.price}
                            onChange={handleChange}
                            disabled
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="demo-simple-select-label"
                        // sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Seller
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Seller"
                            name='seller'
                            value={state.seller}
                            onChange={handleChange}
                        >
                            <MenuItem value='SET'>SET</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="ceilingMargin"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Ceiling Margin
                        </InputLabel>
                        <OutlinedInput
                            id="Ceiling Margin"
                            label="ceilingMargin"
                            type='number'
                            name="cmargin"
                            value={state.cmargin}
                            onChange={handleCeilingMarginChange}
                            inputProps={{
                                step: 0.001
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="floorMargin"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Floor Margin
                        </InputLabel>
                        <OutlinedInput
                            id="Floor Margin"
                            label="floorMargin"
                            type='number'
                            name="fmargin"
                            value={state.fmargin}
                            onChange={handleFloorMarginChange}
                            inputProps={{
                                step: 0.001
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="ceilingLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Ceiling
                        </InputLabel>
                        <OutlinedInput
                            id="Ceiling"
                            label="ceilingLabel"
                            name="ceiling"
                            value={state.ceiling}
                            onChange={handleChange}
                            disabled
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="floorLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Floor
                        </InputLabel>
                        <OutlinedInput
                            id="Floor"
                            label="floorLabel"
                            name="floor"
                            value={state.floor}
                            onChange={handleChange}
                            disabled
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="demo-simple-select-label"
                        // sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Auto Trade
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Auto Trade"
                            name='auto_trade'
                            value={state.auto_trade}
                            onChange={handleChange}
                        >
                            <MenuItem value='yes'>Yes</MenuItem>
                            <MenuItem value='no'>No</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="volumeLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                            shrink
                        >
                            Volume
                        </InputLabel>
                        <OutlinedInput
                            id="Volume"
                            label="volumeLabel"
                            placeholder='Auto Trade Volume'
                            type='number'
                            inputProps={{
                                step: 0.01
                            }}
                            name="volume"
                            value={state.volume}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="demo-simple-select-label"
                        >
                            Endpoint
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Endpoint"
                            name='exitpoint'
                            value={state.exitpoint}
                            onChange={handleChange}
                        >
                            {user?.Endpoints?.map((endpoint, index) => (
                                <MenuItem key={index} value={endpoint.uuid}>{endpoint.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}
                >
                    <Typography variant='caption'>
                        If you select to auto trade, we will execute a trade when the bid/ask number matches your requested buy/sell ceiling/floor.
                    </Typography>
                    <Typography variant='caption'>
                        You will get notified by email when the contract you set for goes higher than or less than the prices you have set.
                    </Typography>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default SetTrailingStop