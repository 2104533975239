import { Avatar, Badge, styled } from '@mui/material';

function stringToColor(string) {
    if (!string) return "";

    let hash = 0;

    /* eslint-disable no-bitwise */
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name, sx) {
    if (!name) return { sx: { bgcolor: "" }, children: "" };

    const nameParts = name.split(' ');

    if (nameParts.length < 2) return { sx: { bgcolor: stringToColor(name) }, children: nameParts[0][0] };

    return {
        sx: {
            ...sx,
            bgcolor: stringToColor(name)
        },
        children: `${nameParts[0][0]}${nameParts[1][0]}`
    };
}


const StyledBadge = styled(Badge)(({ theme, isActive }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: isActive ? '#44b700' : '#c0c0c0',
        color: isActive ? '#44b700' : '#c0c0c0',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""'
        }
    }
}));

const UserAvatar = ({ imgSource, fullName, isActive, sx }) => {
    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            variant="dot"
            isActive={isActive}
        >
            <Avatar
                alt={`${fullName}'s image`}
                src={imgSource}
                {...stringAvatar(fullName, sx)}
            />
        </StyledBadge>
    );
};

export default UserAvatar;

UserAvatar.defaultProps = {
    isActive: false,
    imgSource: null
};
