import { useState, useRef, useCallback } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
// import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {
    useGetChatProfileQuery,
    useGetChatUserQuery,
    useGetChatMessagesQuery
} from '@store/feature/chat/chatApi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import TopBar from './TopBar';
import { FaArrowDown } from 'react-icons/fa';

import React, { useEffect } from 'react';
import MessageBox from '../MessageBox';
import SendChat from './SendChat';
import { Link, useLocation } from 'react-router-dom';
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';
import { useReadChatMessageMutation } from '../../../store/feature/chat/chatApi';

const SingleChat = ({
    handleGoBack,
    handleToggleShowChat,
    navigationStack
}) => {
    const location = useLocation();

    const id = navigationStack[navigationStack.length - 1];
    const { data: profile } = useGetChatProfileQuery();
    const { data: userProfile } = useGetChatUserQuery(id);
    const { data: messages } = useGetChatMessagesQuery(id);
    const [readMessage] = useReadChatMessageMutation();

    const bottomRef = useRef(null);
    const [scrollToLatestMessage, setScrollToLatestMessage] = useState(true);
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'auto' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 100);
    }, [messages]);

    // Mark messages as read when the user visits the page (only once)
    useEffect(() => {
        if (id && profile?.uuid !== id) {
            if (messages?.length > 0) {
                const lastMessage = messages[messages.length - 1];
                if (
                    lastMessage?.from === id &&
                    (lastMessage?.status === 'sent' ||
                        lastMessage?.status === 'delivered')
                ) {
                    readMessage(lastMessage?.uuid);
                }
            }
        }
    }, [id, profile, messages, readMessage]);

    const handleNotInView = useCallback(() => {
        setScrollToLatestMessage(false);
    }, []);

    const handleInView = useCallback(() => {
        setScrollToLatestMessage(true);
    }, []);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    handleNotInView();
                } else {
                    handleInView();
                }
            });
        };

        const observerOptions = {
            root: null, // Use the viewport as the root
            threshold: 0 // Trigger when the element is not visible at all
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        if (bottomRef.current) {
            observer.observe(bottomRef.current);
        }

        return () => {
            if (bottomRef.current) {
                observer.unobserve(bottomRef.current);
            }
        };
    }, [messages, handleNotInView, handleInView]);

    const handleScrollDown = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 500);
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    backgroundColor: '#27485D',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 10px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center'
                    }}
                    onClick={handleGoBack}
                >
                    <IconButton
                        sx={{
                            padding: '0',
                            margin: '0'
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                color: '#fff',
                                fontSize: '1.5rem',
                                padding: '0',
                                margin: '0'
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'white',
                            fontWeight: 'medium'
                        }}
                    >
                        Go to Chats
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        component={Link}
                        to={`${
                            location.pathname.includes('gas')
                                ? `${GAS_PATH.CHAT}/${id}`
                                : location.pathname.includes('electricity')
                                ? `${ELECTRICITY_PATH.CHAT}/${id}`
                                : `${COMMON_PATH.CHAT}/${id}`
                        }`}
                    >
                        <IconButton>
                            <OpenInFullIcon
                                sx={{
                                    color: '#fff',
                                    fontSize: '1.2rem'
                                }}
                            />
                        </IconButton>
                    </Box>
                    <IconButton onClick={handleToggleShowChat}>
                        <CloseIcon
                            sx={{
                                color: '#fff',
                                fontSize: '1.5rem'
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <TopBar profile={userProfile?.user} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: 'calc(100% - 100px)'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        width: '100%',
                        px: '10px',
                        pt: '10px',
                        overflowY: 'scroll',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            mt: 'auto'
                        }}
                    >
                        {messages?.map((text, index) => {
                            return (
                                <MessageBox
                                    id={text?.uuid}
                                    messageText={text?.body}
                                    timestamp={text?.created_at}
                                    isRight={text?.from === profile?.uuid}
                                    key={index}
                                    status={text?.status}
                                    files={text?.files}
                                />
                            );
                        })}

                        <div id="bottom" ref={bottomRef} />
                    </Box>
                </Box>
                {!scrollToLatestMessage && (
                    <div className="relative w-full">
                        <span className="absolute left-1/2 transform -translate-x-1/2 bottom-2">
                            <button
                                className="z-100 rounded-full flex justify-center items-center bg-[#2FACFE] border-1 border-gray-300 w-6 h-6 p-1"
                                onClick={handleScrollDown}
                            >
                                <FaArrowDown color="white" />
                            </button>
                        </span>
                    </div>
                )}
                <SendChat
                    id={userProfile?.user?.uuid}
                    userProfile={userProfile}
                />
            </Box>
        </Box>
    );
};

export default SingleChat;
