import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    eur: [],
    isGetEurLoading: false
};
export const apiGetTtfEur = createAsyncThunk(
    "ttf/getEur",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getttfdata');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const ttfSlice = createSlice({
    name: "ttf",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get TTF EUR
        builder
            .addCase(apiGetTtfEur.pending, (state) => {
                state.isGetEurLoading = true;
            })
            .addCase(apiGetTtfEur.fulfilled, (state, action) => {
                state.eur = action.payload.data;
                state.isGetEurLoading = false;
            })
            .addCase(apiGetTtfEur.rejected, (state) => {
                state.isGetEurLoading = false;
            });
    }
});

export default ttfSlice.reducer;