import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    forwardCurve: [],
    isGetForwardCurveLoading: false,
    monthHistoricalPrices: [],
    isGetMonthHistoricalPricesLoading: false,
    quarterHistoricalPrices: [],
    isGetQuarterHistoricalPricesLoading: false,
    seasonHistoricalPrices: [],
    isGetSeasonHistoricalPricesLoading: false,
};

export const apiGetForwardCurve = createAsyncThunk(
    "overview/getForwardCurve",
    async ({ date }, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_forward_curve_data?date=${date}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetMonthHistoricalPrices = createAsyncThunk(
    "overview/getMonthHistoricalPrices",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_historical_curve_data');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetQuarterHistoricalPrices = createAsyncThunk(
    "overview/getQuarterHistoricalPrices",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_quarterly_historical_curve_data');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetSeasonHistoricalPrices = createAsyncThunk(
    "overview/getSeasonHistoricalPrices",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_seasons_historical_curve_data');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const overviewSlice = createSlice({
    name: "overview",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Forward Curve
            .addCase(apiGetForwardCurve.pending, (state) => {
                state.isGetForwardCurveLoading = true;
            })
            .addCase(apiGetForwardCurve.fulfilled, (state, action) => {
                state.isGetForwardCurveLoading = false;
                state.forwardCurve = action.payload.data;
            })
            .addCase(apiGetForwardCurve.rejected, (state) => {
                state.isGetForwardCurveLoading = false;
            })

            // Month Historical Prices
            .addCase(apiGetMonthHistoricalPrices.pending, (state) => {
                state.isGetMonthHistoricalPricesLoading = true;
            })
            .addCase(apiGetMonthHistoricalPrices.fulfilled, (state, action) => {
                state.isGetMonthHistoricalPricesLoading = false;
                state.monthHistoricalPrices = action.payload.data;
            })
            .addCase(apiGetMonthHistoricalPrices.rejected, (state) => {
                state.isGetMonthHistoricalPricesLoading = false;
            })

            // Quarter Historical Prices
            .addCase(apiGetQuarterHistoricalPrices.pending, (state) => {
                state.isGetQuarterHistoricalPricesLoading = true;
            })
            .addCase(apiGetQuarterHistoricalPrices.fulfilled, (state, action) => {
                state.isGetQuarterHistoricalPricesLoading = false;
                state.quarterHistoricalPrices = action.payload.data;
            })
            .addCase(apiGetQuarterHistoricalPrices.rejected, (state) => {
                state.isGetQuarterHistoricalPricesLoading = false;
            })

            // Season Historical Prices
            .addCase(apiGetSeasonHistoricalPrices.pending, (state) => {
                state.isGetSeasonHistoricalPricesLoading = true;
            })
            .addCase(apiGetSeasonHistoricalPrices.fulfilled, (state, action) => {
                state.isGetSeasonHistoricalPricesLoading = false;
                state.seasonHistoricalPrices = action.payload.data;
            })
            .addCase(apiGetSeasonHistoricalPrices.rejected, (state) => {
                state.isGetSeasonHistoricalPricesLoading = false;
            })
    }
});

export default overviewSlice.reducer;