import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import { Box, Typography } from '@mui/material'
import { useDeleteGroupMessagesMutation } from '@store/feature/chat/chatApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GAS_PATH, ELECTRICITY_PATH, showToast } from '@utils';

const DeleteGroupModal = forwardRef(({ handleClose }, ref) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [deleteGroupMessages] = useDeleteGroupMessagesMutation()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const resp = await deleteGroupMessages({groupId:id})
        if (resp?.error?.originalStatus) {
            showToast('error', 'Something went wrong')
            return
        }
        navigate(`${location.pathname.includes("gas") ? GAS_PATH.CHAT : ELECTRICITY_PATH.CHAT}?tab=groups`)
        handleClose()
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Delete Group`}
            buttonText={'Delete Group'}
            modalTitleColor={'#EB6969'}
            className='modal-button-danger'
            ref={ref}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    color: 'black',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '14px'
                        }}
                    >
                        Are you sure you want to delete this group?
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px'
                        }}
                    >
                        By deleting the chat you are deleting all the messages and media shared in this group chat
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontSize: '14px'
                    }}
                >
                    Please note that by deleting the chat, other person can still see all the messages
                </Typography>
            </Box>
        </CustomModal>
    )
})

export default DeleteGroupModal