import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    market: [],
    isGetMarketLoading: false,
}

export const apiGetMarket = createAsyncThunk(
    "market/getMarket",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/getmarketdata');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const marketSlice = createSlice({
    name: "market",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get market
        builder
            .addCase(apiGetMarket.pending, (state) => {
                state.isGetMarketLoading = true;
            })
            .addCase(apiGetMarket.fulfilled, (state, action) => {
                state.market = action.payload.data;
                state.isGetMarketLoading = false;
            })
            .addCase(apiGetMarket.rejected, (state) => {
                state.isGetMarketLoading = false;
            });
    }
});

export default marketSlice.reducer;