import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    order: [],
    isGetOrderLoading: false,
    ordersInfo: {},
    isGetOrdersInfoLoading: false,
    isAddingNewOrderLog: false,
};

export const apiGetOrder = createAsyncThunk(
    "order/getOrder",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/ckw/get_orders');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetOrdersInfo = createAsyncThunk(
    "order/getOrdersInfo",
    async (month, thunkAPI) => {
        try {
            let query = '/api/ckw/getordersinfo';
            if (month) query += `?month=${month}`;
            const response = await axios.get(query);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiAddOrderLog = createAsyncThunk(
    "order/addOrderLog",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/saveorder', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Order
        builder
            .addCase(apiGetOrder.pending, (state) => {
                state.isGetOrderLoading = true;
            })
            .addCase(apiGetOrder.fulfilled, (state, action) => {
                state.order = action.payload.data;
                state.isGetOrderLoading = false;
            })
            .addCase(apiGetOrder.rejected, (state) => {
                state.isGetOrderLoading = false;
            });
        
        // Get Orders Info
        builder
            .addCase(apiGetOrdersInfo.pending, (state) => {
                state.isGetOrdersInfoLoading = true;
            })
            .addCase(apiGetOrdersInfo.fulfilled, (state, action) => {
                state.ordersInfo = action.payload.data;
                state.isGetOrdersInfoLoading = false;
            })
            .addCase(apiGetOrdersInfo.rejected, (state) => {
                state.isGetOrdersInfoLoading = false;
            });

        // Add Order Log
        builder
            .addCase(apiAddOrderLog.pending, (state) => {
                state.isAddingNewOrderLog = true;
            })
            .addCase(apiAddOrderLog.fulfilled, (state) => {
                state.isAddingNewOrderLog = false;
            })
            .addCase(apiAddOrderLog.rejected, (state) => {
                state.isAddingNewOrderLog = false;
            });
    }
});

export default orderSlice.reducer;