import { Box, Button } from '@mui/material';
import React, { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const CustomModal = forwardRef(
    (
        {
            handleClose,
            handleSubmit,
            modalTitle,
            modalTitleColor,
            children,
            buttonText,
            disabled,
            className,
            timer,
            filterReset
        },
        ref
    ) => {
        return (
            <Box
                className="modal-container"
                component="form"
                onSubmit={handleSubmit}
            >
                <Box className="flex justify-between modal-header">
                    <h2
                        className="modal-title"
                        style={{ color: modalTitleColor || '#245372' }}
                    >
                        {modalTitle}
                    </h2>
                    <Box className="flex gap-4">
                        {timer && (
                            <p className="timer">
                                {' '}
                                <span
                                    className={
                                        timer < 6
                                            ? 'text-red-500 animate-pulse'
                                            : ''
                                    }
                                >
                                    {timer}
                                </span>{' '}
                                seconds
                            </p>
                        )}
                        <CloseIcon
                            className="cursor-pointer"
                            onClick={handleClose}
                        />
                    </Box>
                </Box>
                <div className="modal-body">{children}</div>
                <div className="modal-footer">
                    <Button
                        className="modal-button-secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    {filterReset && (
                        <Button
                            className="modal-button-secondary"
                            onClick={filterReset}
                        >
                            Reset
                        </Button>
                    )}
                    <Button
                        type="submit"
                        className={
                            className
                                ? `modal-button-primary ${className}`
                                : 'modal-button-primary'
                        }
                        disabled={disabled || false}
                    >
                        {buttonText || 'Submit'}
                    </Button>
                </div>
            </Box>
        );
    }
);

export default CustomModal;
