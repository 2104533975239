import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    user: {},
    users: [],
    isLoginLoading: false,
    isGetProfileLoading: false,
    isChangePasswordLoading: false,
    isLoggingOut: false,
    usersForAccount: [],
    isGetUsersForAccountLoading: false,
    isUpdateUserProfileLoading: false,
    qr : '',
    isGeneratingQRLoading: false,
    isValidateOTPLoading: false,
    isUpdateOTPLoading: false,
    isUpdateUserLoading: false,
    isForgotPasswordLoading: false,
    isUpdatePasswordLoading: false,
};

export const apiLogin = createAsyncThunk(
    "user/login",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/uilogin', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiLogout = createAsyncThunk(
    "user/logout",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/logout');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetProfile = createAsyncThunk(
    "user/getUserInfo",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/getUserInfo');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiChangePassword = createAsyncThunk(
    "user/changePassword",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/update_password', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetUsersForAccount = createAsyncThunk(
    "user/getUsersForAccount",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.get('/api/getusers/' + uuid);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdateUserProfile = createAsyncThunk(
    "user/updateUserProfile",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/updateuserprofile', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGenerateQR = createAsyncThunk(
    "user/generateQR",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.get('/api/genQR');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiValidateOTP = createAsyncThunk(
    "user/validateOTP",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/validate_totp', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdateOTP = createAsyncThunk(
    "user/updateOTP",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/update_totp', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdateUser = createAsyncThunk(
    "user/updateUser",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/updateuser', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiForgotPassword = createAsyncThunk(
    "user/forgotPassword",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/reset_password', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdatePassword = createAsyncThunk(
    "user/updatePassword",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/reset_update_password', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        deleteUser: (state, action) => {
            state.users = state.users.filter((user) => user.uuid !== action.payload);
        }
    },
    extraReducers: (builder) => {
        // login
        builder
            .addCase(apiLogin.pending, (state, action) => {
                state.isLoginLoading = true;
            })
            .addCase(apiLogin.fulfilled, (state, action) => {
                state.isLoginLoading = false;
            })
            .addCase(apiLogin.rejected, (state, action) => {
                state.isLoginLoading = false;
            })

        // get user
        builder
            .addCase(apiGetProfile.pending, (state, action) => {
                state.isGetProfileLoading = true;
            })
            .addCase(apiGetProfile.fulfilled, (state, action) => {
                state.isGetProfileLoading = false;
                state.user = action.payload.data;
            })
            .addCase(apiGetProfile.rejected, (state, action) => {
                state.isGetProfileLoading = false;
            })

        // change password
        builder
            .addCase(apiChangePassword.pending, (state, action) => {
                state.isChangePasswordLoading = true;
            })
            .addCase(apiChangePassword.fulfilled, (state, action) => {
                state.isChangePasswordLoading = false;
            })
            .addCase(apiChangePassword.rejected, (state, action) => {
                state.isChangePasswordLoading = false;
            })

        // logout
        builder
            .addCase(apiLogout.pending, (state, action) => {
                state.isLoggingOut = true;
            })
            .addCase(apiLogout.fulfilled, (state, action) => {
                state.isLoggingOut = false;
            })
            .addCase(apiLogout.rejected, (state, action) => {
                state.isLoggingOut = false;
            })

        // get users for account
        builder
            .addCase(apiGetUsersForAccount.pending, (state, action) => {
                state.isGetUsersForAccountLoading = true;
            })
            .addCase(apiGetUsersForAccount.fulfilled, (state, action) => {
                state.isGetUsersForAccountLoading = false;
                state.usersForAccount = action.payload.data;
            })
            .addCase(apiGetUsersForAccount.rejected, (state, action) => {
                state.isGetUsersForAccountLoading = false;
            })

        // update user profile
        builder
            .addCase(apiUpdateUserProfile.pending, (state, action) => {
                state.isUpdateUserProfileLoading = true;
            })
            .addCase(apiUpdateUserProfile.fulfilled, (state, action) => {
                state.isUpdateUserProfileLoading = false;
            })
            .addCase(apiUpdateUserProfile.rejected, (state, action) => {
                state.isUpdateUserProfileLoading = false;
            })
        
        // generate QR
        builder
            .addCase(apiGenerateQR.pending, (state, action) => {
                state.isGeneratingQRLoading = true;
            })
            .addCase(apiGenerateQR.fulfilled, (state, action) => {
                state.isGeneratingQRLoading = false;
                state.qr = action.payload.data;
            })
            .addCase(apiGenerateQR.rejected, (state, action) => {
                state.isGeneratingQRLoading = false;
            })
        
        // validate OTP
        builder
            .addCase(apiValidateOTP.pending, (state, action) => {
                state.isValidateOTPLoading = true;
            })
            .addCase(apiValidateOTP.fulfilled, (state, action) => {
                state.isValidateOTPLoading = false;
            })
            .addCase(apiValidateOTP.rejected, (state, action) => {
                state.isValidateOTPLoading = false;
            })
        
        // update OTP
        builder
            .addCase(apiUpdateOTP.pending, (state, action) => {
                state.isUpdateOTPLoading = true;
            })
            .addCase(apiUpdateOTP.fulfilled, (state, action) => {
                state.isUpdateOTPLoading = false;
            })
            .addCase(apiUpdateOTP.rejected, (state, action) => {
                state.isUpdateOTPLoading = false;
            })

        // update user
        builder
            .addCase(apiUpdateUser.pending, (state, action) => {
                state.isUpdateUserLoading = true;
            })
            .addCase(apiUpdateUser.fulfilled, (state, action) => {
                state.isUpdateUserLoading = false;
            })
            .addCase(apiUpdateUser.rejected, (state, action) => {
                state.isUpdateUserLoading = false;
            })

        // forgot password
        builder
            .addCase(apiForgotPassword.pending, (state, action) => {
                state.isForgotPasswordLoading = true;
            })
            .addCase(apiForgotPassword.fulfilled, (state, action) => {
                state.isForgotPasswordLoading = false;
            })
            .addCase(apiForgotPassword.rejected, (state, action) => {
                state.isForgotPasswordLoading = false;
            })

        // update password
        builder
            .addCase(apiUpdatePassword.pending, (state, action) => {
                state.isUpdatePasswordLoading = true;
            })
            .addCase(apiUpdatePassword.fulfilled, (state, action) => {
                state.isUpdatePasswordLoading = false;
            })
            .addCase(apiUpdatePassword.rejected, (state, action) => {
                state.isUpdatePasswordLoading = false;
            })
    },
});

export const { deleteUser } = userSlice.actions;

export default userSlice.reducer;