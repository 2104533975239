import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useMuteChatUserMutation } from '@store/feature/chat/chatApi'

const MuteModal = forwardRef(({ handleClose }, ref) => {
    const { id } = useParams()

    const [muteChat] = useMuteChatUserMutation()

    const handleSubmit = (e) => {
        e.preventDefault()
        muteChat(id)
        handleClose()
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Mute Notification`}
            buttonText={'Mute Notification'}
            ref={ref}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    color: 'black',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '14px'
                        }}
                    >
                        By muting notifications from this chat, you will not receive any notification about incoming messages and voice/video calls.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                    >
                        No-one in this chat will see that you muted it.
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontSize: '14px'
                    }}
                >
                    You can always enable notifications back, in the same control center.
                </Typography>
            </Box>
        </CustomModal>
    )
})

export default MuteModal