import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiSaveBidOrder } from '@store'
import { showToast } from '@utils'

const Buy = forwardRef(({ handleCloseModal, selectedRow }, ref) => {
    const dispatch = useDispatch()

    const state = {
        uuid: selectedRow?.uuid || "",
        contract: selectedRow?.contract || "",
        owner: selectedRow?.owner || "",
        price: selectedRow?.price || "",
        volume: selectedRow?.quantity || 0,
        buyer: "",
        seller: selectedRow?.owner || "",
        location: selectedRow?.location || "",
        currency: selectedRow?.currency || "",
        ttl: selectedRow?.ttl || "",
        type: "sell",
        option: selectedRow?.option || "",
        step: 0.1,
        minVolume: 0,
        maxVolume: selectedRow?.quantity || 0,
        valid_until: selectedRow?.valid_until || "",
    }

    const handleBuy = async (e) => {
        e.preventDefault()
        const resp = await dispatch(apiSaveBidOrder(state))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Contract Sold Successfully')
            handleCloseModal()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Error Selling Contract')
        }
    }

    return (
        <CustomModal
            handleClose={handleCloseModal}
            handleSubmit={handleBuy}
            modalTitle={`Buy Contract`}
            buttonText={'Buy'}
            ref={ref}
        >
            <Box className='flex flex-col gap-3'>
                <FormControl>
                    <InputLabel
                        id="contractLabel"
                    >
                        Contract
                    </InputLabel>
                    <OutlinedInput
                        id="contract"
                        label="contract"
                        name='contract'
                        value={selectedRow?.contract}
                        disabled
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                    <InputLabel
                        id="contractLabel"
                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                    >
                        Volume
                    </InputLabel>
                    <OutlinedInput
                        id="volume"
                        label="Volume"
                        name="quantity"
                        type="number"
                        value={selectedRow?.quantity}
                        inputProps={{ step: 0.01 }}
                        required
                        disabled
                        endAdornment={
                            <InputAdornment position="start">
                                MW
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="priceLabel"
                    >
                        Price
                    </InputLabel>
                    <OutlinedInput
                        id="price"
                        label="price"
                        name='price'
                        value={selectedRow?.price}
                        disabled
                    />
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="sellerLabel"
                    >
                        Buyer
                    </InputLabel>
                    <OutlinedInput
                        id="seller"
                        label="seller"
                        name='seller'
                        value='SET'
                        disabled
                    />
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="locationLabel"
                    >
                        Location
                    </InputLabel>
                    <OutlinedInput
                        id="location"
                        label="location"
                        name='location'
                        value={selectedRow?.location}
                        disabled
                    />
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="currencyLabel"
                    >
                        Currency
                    </InputLabel>
                    <OutlinedInput
                        id="currency"
                        label="currency"
                        name='currency'
                        value={selectedRow?.currency}
                        disabled
                    />
                </FormControl>
                <FormControl>
                    <InputLabel
                        id="validUntilLabel"
                    >
                        Valid Until
                    </InputLabel>
                    <OutlinedInput
                        id="validUntil"
                        label="validUntil"
                        name='valid_until'
                        value={selectedRow?.valid_until}
                        disabled
                    />
                </FormControl>
            </Box>
        </CustomModal>
    )
})

export default Buy