import { Box, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLocation } from 'react-router-dom';

const MessageBox = ({
    messageText,
    timestamp,
    isRight,
    status,
    id,
    files,
    dateIndex
}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab') || 'all';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isRight ? 'end' : 'start',
                gap: '2px',
                py: '3px',
                px: '8px',
                width: '100%'
            }}
        >
            <Box
                className={isRight ? 'bg-slate-200' : 'bg-sky-200'}
                sx={{
                    minWidth: 'min-content',
                    maxWidth: '412px',
                    px: '16px',
                    pt: '8px',
                    pb: '2px',
                    borderRadius: isRight
                        ? '8px 0px 8px 8px'
                        : '0px 8px 8px 8px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Typography
                        variant="body2"
                        component="pre"
                        sx={{
                            whiteSpace: 'pre-wrap', // Ensures that the text will wrap inside the pre element
                            wordWrap: 'break-word', // Ensures long words will break and wrap onto the next line
                            textAlign: 'start',
                            marginBottom: '4px',
                            width: '100%',
                            overflow: 'hidden', // Hides any overflowing content
                            textOverflow: 'ellipsis' // Adds ellipsis if the text is overflowing
                        }}
                    >
                        {/* {messageText || `Say hi to ${isRight ? 'me' : 'you'}`} */}
                        {!messageText && !files
                            ? `Say hi to ${isRight ? 'me' : 'you'}`
                            : messageText}{' '}
                    </Typography>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns:
                                files && files.length > 1
                                    ? 'repeat(2, 1fr)'
                                    : '1fr',
                            gap: '10px'
                        }}
                    >
                        {files &&
                            files.map((file, index) => {
                                if (
                                    file.file_type === 'image/jpeg' ||
                                    file.file_type === 'image/png'
                                ) {
                                    return (
                                        <Box key={index}>
                                            <img
                                                key={index}
                                                src={`/chat-media/${file.file_url}`}
                                                alt="file"
                                                style={{
                                                    width: '150px',
                                                    height: '150px',
                                                    borderRadius: '8px',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </Box>
                                    );
                                }
                                return <></>;
                            })}
                    </Box>
                </Box>

                <Box
                    sx={{
                        textAlign: 'end',
                        display: 'flex',
                        gap: '4px',
                        justifyContent: 'end',
                        alignItems: 'end'
                    }}
                >
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontSize: '0.73rem'
                        }}
                    >
                        {formatTime(timestamp)}
                    </Typography>
                    {isRight && tab === 'all' && (
                        <Typography
                            sx={{
                                color: 'text.secondary'
                            }}
                        >
                            {status === 'sent' ? (
                                <DoneIcon fontSize="0.7rem" />
                            ) : status === 'delivered' ? (
                                <DoneAllIcon fontSize="0.7rem" />
                            ) : status === 'read' ? (
                                <DoneAllIcon
                                    className="text-green-600"
                                    fontSize="0.7rem"
                                />
                            ) : (
                                <AccessTimeIcon fontSize="0.6rem" />
                            )}
                        </Typography>
                    )}
                    {isRight && tab === 'groups' && (
                        <Typography
                            sx={{
                                color: 'text.secondary'
                            }}
                        >
                            {status === 'sent' ? (
                                <DoneIcon fontSize="0.7rem" />
                            ) : status === 'delivered' ? (
                                <DoneAllIcon fontSize="0.7rem" />
                            ) : status === 'read' ? (
                                <DoneAllIcon fontSize="0.7rem" />
                            ) : null}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

// Function to format date to HH:MM AM/PM in user timezone
const formatTime = (dateString) => {
    const timestamp = Number.parseInt(dateString) * 1000;

    const date = new Date(timestamp);

    return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
    });
};

export default MessageBox;
