import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Grid,
    Box,
    IconButton,
    OutlinedInput,
    InputAdornment,
    FormControl,
    InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiLogin } from "@store";
import { COMMON_PATH } from "@utils";
import { useAuth } from "../hooks";

import HeroBackgroudImage from '../assets/images/background.png';

import KeyIcon from "@mui/icons-material/Key";
import MailIcon from "@mui/icons-material/Mail";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
    const dispatch = useDispatch();
    const [showPass, setShowPass] = useState(false);
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const { isLoginLoading } = useSelector((state) => state.user);

    const [state, setState] = useState({
        username: "",
        password: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await dispatch(apiLogin(state));
        if (response?.meta?.requestStatus === 'fulfilled') {
            setAuth({
                session: true,
            });
            localStorage.setItem("loginState", "true");
            navigate(COMMON_PATH.HOME);
        } else if (response?.meta?.requestStatus === 'rejected') {
            alert("Invalid username or password");
        } else {
            alert("Something went wrong");
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            htmlFor="login-email"
                        >
                            Email
                        </InputLabel>
                        <OutlinedInput
                            label="Email"
                            value={state.username}
                            autoComplete="email"
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    username: e.target.value,
                                })
                            }
                            id="login-email"
                            required
                            startAdornment={
                                <InputAdornment position="start">
                                    <MailIcon />
                                </InputAdornment>
                            }
                            autoFocus
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel htmlFor="login-password">
                            Password
                        </InputLabel>
                        <OutlinedInput
                            label="Password"
                            value={state.password}
                            autoComplete="current-password"
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    password: e.target.value,
                                })
                            }
                            id="login-password"
                            required
                            type={showPass ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setShowPass(!showPass)
                                        }
                                        onMouseDown={(e) =>
                                            e.preventDefault()
                                        }
                                        edge="end"
                                    >
                                        {showPass ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    <KeyIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={styles.button}
            >
                {isLoginLoading ? <CircularProgress size={24} color="inherit" /> : "Log In"}
            </Button>
        </Box>
    )
}

export default Login

const styles = {
    root: {
        height: '100%',
    },
    topSection: {
        backgroundImage: `url(${HeroBackgroudImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        px: '70px',
        py: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        fontSize: "1rem",
        fontWeight: "bold",
        color: "white",
        px: '150px',
    },
    logo: {
        borderRight: '3px solid #fff',
        fontSize: "2rem",
        fontWeight: "bold",
        color: "white",
        textDecoration: "none",
        corsor: "pointer",
        paddingRight: '20px',
    },
    headerLinks: {
        display: 'flex',
        gap: '20px',
    },
    hero: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100% - 250px)',
        px: '150px'
    },
    heroText: {
        color: '#fff',
        fontSize: '50px',
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '66px',
        width: '35%',
    },
    loginContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "400px",
        border: "1px solid #ccc",
        padding: "40px",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
    },
    icon: {
        color: "#fff",
        backgroundColor: "#F50057",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
    },
    button: {
        fontWeight: "bold",
        marginTop: "20px",
        backgroundColor: "light-blue",
        height: "50px",
        '&:active': {
            backgroundColor: 'light-blue'
        },
        '&:hover': {
            backgroundColor: 'light-blue'
        }
    },
    midSection: {
        padding: '0 70px 50px',
        px: '150px'
    },
    midSectionHeading: {
        fontSize: '48px',
        textAlign: 'center',
        marginBottom: '70px',
    },
    midSectionBox: {
        display: 'flex',
        justifyContent: 'space-around',
        gap: '20px',
    },
    bottomSection: {
        background: 'linear-gradient(88.89deg, #3B9D9D 0%, #27485D 83.49%)',
        px: '150px',
        py: '50px',
        color: '#fff',
    },
    bottomSectionContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    }
}