import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { apiGetSinglePriceprofile } from '@store';
import { useParams } from 'react-router-dom';
import { TabComponent, CommonContainer } from "@components"
import YearOne from './YearOne';
import YearTwo from './YearTwo';
import YearThree from './YearThree';
import { Box, Button, TextField } from '@mui/material';
import { AppName, PriceProfilingTitle } from '@utils';

const SinglePriceProfile = () => {
    useEffect(() => {
        document.title = `${AppName} | ${PriceProfilingTitle}`
    }, [])

    const dispatch = useDispatch();
    const { uuid } = useParams();
    const { singlePriceprofile, isGetSinglePriceprofileLoading } = useSelector(state => state.priceprofile);

    const [state, setState] = useState({
        uuid: uuid,
        volume: "",
        call: "",
        put: ""
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleCalculate = async (e) => {
        e.preventDefault();

        const data = {
            ...state,
            volume: parseFloat(state.volume),
            call: parseFloat(state.call),
            put: parseFloat(state.put)
        }

        const resp = await dispatch(apiGetSinglePriceprofile(data));
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(apiGetSinglePriceprofile(uuid));
        } else if (resp?.meta?.requestStatus === 'rejected') {
            console.log(resp.payload)
        }
    }

    useEffect(() => {
        dispatch(apiGetSinglePriceprofile({ uuid }));
    }, [uuid]);

    const tabs = [
        {
            name: 'Year 1',
            content:
                <YearOne
                    singlePriceProfile={singlePriceprofile[0]}
                    loading={isGetSinglePriceprofileLoading}
                    call={state.call}
                    put={state.put}
                    volume={state.volume}
                />,
        }, {
            name: 'Year 2',
            content:
                <YearTwo
                    singlePriceProfile={singlePriceprofile[1]}
                    loading={isGetSinglePriceprofileLoading}
                    call={state.call}
                    put={state.put}
                    volume={state.volume}
                />
        }, {
            name: 'Year 3',
            content:
                <YearThree
                    singlePriceProfile={singlePriceprofile[2]}
                    loading={isGetSinglePriceprofileLoading}
                    call={state.call}
                    put={state.put}
                    volume={state.volume}
                />
        }
    ]

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CommonContainer
                sx={{
                    display: 'flex',
                    gap: '10px',
                    mb: '8px',
                    px: '10px',
                }}
                padding={'p-2'}
            >
                <TextField
                    id="outlined-basic"
                    label="Change Volume (%)"
                    variant="outlined"
                    name="volume"
                    type="number"
                    onChange={handleChange}
                    value={state.volume}
                    sx={{
                        height: 50,
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    }}
                    fullWidth
                />
                <TextField
                    id="outlined-basic"
                    label="Upside Flex (%)"
                    variant="outlined"
                    name="call"
                    type="number"
                    onChange={handleChange}
                    value={state.call}
                    sx={{
                        height: 50,
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    }}
                    fullWidth
                />
                <TextField
                    id="outlined-basic"
                    label="Downside Flex (%)"
                    variant="outlined"
                    name="put"
                    type="number"
                    onChange={handleChange}
                    value={state.put}
                    sx={{
                        height: 50,
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    }}
                    fullWidth
                />
                <Button
                    variant='outlined'
                    color='primary'
                    sx={{
                        p: 0,
                        m: 0,
                        backgroundColor: '#27A9FF',
                        border: '1px solid #27A9FF',
                        color: '#fff',
                        fontSize: '13px',
                        width: '500px',
                        '&:hover': {
                            color: 'white',
                            backgroundColor: '#27A9FF',
                            border: '1px solid #27A9FF'
                        },
                    }}
                    onClick={handleCalculate}
                >
                    Calculate
                </Button>
            </CommonContainer>
            <TabComponent tabs={tabs} />
        </Box>
    )
}

export default SinglePriceProfile