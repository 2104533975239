import React, { forwardRef, useState } from 'react'
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import { useDispatch } from 'react-redux';
import { apiCreatePriceprofile, apiGetPriceprofile } from '@store';
import { showToast } from '@utils'
import { CustomModal } from '@components';

const product_option = [
    { value: 'THE', label: 'THE' },
    { value: 'PEG', label: 'PEG' },
]

const CreatePriceProfile = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        name: '',
        product: '',
        myFile: null
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleFileChange = (e) => {
        setState({
            ...state,
            myFile: e.target.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('name', state.name)
        formData.append('product', state.product)
        formData.append('myFile', state.myFile)

        const response = await dispatch(apiCreatePriceprofile(formData))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetPriceprofile())
            handleClose()
            showToast('success', 'Price Profile created successfully')
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload || 'An error occurred')
        } else {
            showToast('error', 'An error occurred')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle='Create Price Profile'
        >
            <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                <InputLabel
                    id="nameLabel"
                >
                    Name
                </InputLabel>
                <OutlinedInput
                    id="name"
                    label="Name"
                    name='name'
                    value={state.name}
                    onChange={handleChange}
                    required
                />
            </FormControl>
            <FormControl fullWidth>
                <InputLabel
                    id="demo-simple-select-label"
                >
                    Product
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Product"
                    name='product'
                    value={state.product}
                    onChange={handleChange}
                    required
                >
                    {product_option.map((product, index) => (
                        <MenuItem key={index} value={product.value}>{product.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth={true} variant="outlined" sx={{ mt: 2 }}>
                <InputLabel
                    id="fileLabel"
                    sx={{ bgcolor: '#F5F5F5', px: 1 }}
                    shrink
                >
                    File
                </InputLabel>
                <OutlinedInput
                    id="myFile"
                    type="file"
                    label="File"
                    name='myFile'
                    onChange={handleFileChange}
                    required
                />
            </FormControl>
        </CustomModal>
    )
})

export default CreatePriceProfile