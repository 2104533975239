import React, { useState } from 'react'
import { Box, Button, IconButton, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import MessageIcon from '@mui/icons-material/Message';
import { apiDeleteNews, apiGetComments, apiGetNews, apiLikeNews, apiPostComment, apiUnlikeNews, likeNews, unlikeNews } from '@store';
import { showToast } from '@utils';
import { useDispatch } from 'react-redux';
import { PostComments } from '.';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@pages/common/newsfeed/Edit';

const PostComponent = ({ news, comments, user }) => {
    const dispatch = useDispatch()
    // show comment section
    const [showCommentSection, setShowCommentSection] = useState(false)
    const handleShowCommentSection = () => { setShowCommentSection(!showCommentSection) }

    const handleLikeNews = async (news) => {
        const response = await dispatch(apiLikeNews(news?.uuid))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(likeNews(news?.uuid))
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }

    const handleUnlikeNews = async (news) => {
        const response = await dispatch(apiUnlikeNews(news?.uuid))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(unlikeNews(news?.uuid))
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }

    const handleGetComments = async (news) => {
        const response = await dispatch(apiGetComments(news?.uuid))
        if (response.meta.requestStatus === 'fulfilled') {
            // console.log(response.payload, 'response')
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }

    // comment 
    const [commentState, setCommentState] = useState({
        body: "",
        news_uuid: news?.uuid,
        type: "post"
    })

    const handleCommentChange = (e) => {
        setCommentState({ ...commentState, body: e.target.value })
    }

    const handleSubmitComment = async (e) => {
        e.preventDefault()
        const response = await dispatch(apiPostComment(commentState))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetComments(news?.uuid))
            // showToast('success', 'Comment posted successfully')
            setCommentState({ ...commentState, body: "" })
        } else if (response.meta.requestStatus === 'rejected') {
            // showToast('error', response.payload)
        }
    }

    // menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // delete News
    const handleDeleteNews = async (e) => {
        e.preventDefault()
        const response = await dispatch(apiDeleteNews(news))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetNews())
            showToast('info', 'News deleted successfully')
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }

    const [selectedSingleNews, setSelectedSingleNews] = useState(null)

    // open edit modal
    const [openEditModal, setOpenEditModal] = useState(false);
    const handleOpenEditModal = () => {
        setOpenEditModal(true);
        setSelectedSingleNews(news);
    };
    const handleCloseEditModal = () => { setOpenEditModal(false); };

    // show news text
    const [showNewsText, setShowNewsText] = useState(false)
    const handleShowNewsText = () => { setShowNewsText(!showNewsText) }

    return (
        <>
            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
            >
                <Edit
                    handleClose={handleCloseEditModal}
                    singleNews={selectedSingleNews}
                />
            </Modal>
            <Box key={news?.uuid}>
                <Box className='border m-4 rounded-md shadow-md bg-white pt-4'>
                    <Box className='p-1 px-3 flex flex-col' >
                        <Box className='flex justify-between'>
                            <Box display={'flex'} gap={2} alignItems={'center'}>
                                <img src="https://www.citypng.com/public/uploads/preview/flat-newspaper-news-round-icon-11637079201xklmcapuv9.png"
                                    className="w-8 h-8 rounded-circle" alt="" />
                                <Box>
                                    <Typography sx={{ padding: '0 !important', margin: '0 !important', lineHeight: '1', fontWeight: 'bold' }}>{news?.headline}</Typography>
                                    <Typography sx={{ padding: '0 !important', margin: '0 !important', fontSize: '10px' }} variant='caption'>{news?.created_at.slice(0, 10)}</Typography>
                                </Box>
                            </Box>
                            {user?.UUID === news?.user_uuid && (
                                <Box>
                                    <Tooltip title="Menu">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: { ...styles },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={handleOpenEditModal}>
                                            <EditIcon className='mr-2' />
                                            Edit
                                        </MenuItem>
                                        <MenuItem sx={{ color: 'brown' }} onClick={handleDeleteNews}>
                                            <DeleteIcon className='mr-2' />
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}
                        </Box>
                        {news?.text &&
                            <Typography
                                sx={{
                                    mt: 2,
                                    fontSize: '14px',
                                    color: 'black',
                                }}
                            >
                                {news?.text.length > 200 && !showNewsText ?
                                    <>
                                        {news?.text.slice(0, 200)}...
                                        <span
                                            onClick={handleShowNewsText}
                                            style={{
                                                fontSize: '12px',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                color: '#245372',
                                                marginLeft: '3px'
                                            }}
                                        >
                                            Read more
                                        </span>
                                    </> : news?.text
                                }
                            </Typography>
                        }
                    </Box>
                    {news?.location &&
                        <img src={`/media/${news?.location}`} alt={news?.title} className='object-fill mt-2 w-full' />
                    }
                    <Box className='border p-1'>
                        <Box className='flex p-2 gap-3'>
                            <Box className='flex items-center gap-1'>
                                {news?.is_liked ?
                                    <ThumbUpAltIcon
                                        className='cursor-pointer'
                                        onClick={() => handleUnlikeNews(news)}
                                    /> :
                                    <ThumbUpOffAltIcon
                                        className='cursor-pointer'
                                        onClick={() => handleLikeNews(news)}
                                    />
                                }
                                <Typography variant='body2'>{news?.likes}</Typography>
                            </Box>
                            <Box className='flex gap-2'>
                                <MessageIcon className='cursor-pointer'
                                    onClick={() => {
                                        handleShowCommentSection()
                                        handleGetComments(news)
                                    }}
                                />
                                <Typography variant='body2'>{news?.replies}</Typography>
                            </Box>
                        </Box>
                        {showCommentSection && (
                            <Box>
                                <Box className='p-2'>
                                    <Box component={'form'} className='flex gap-2 relative'>
                                        <TextField
                                            id="outlined-basic"
                                            label="Comment"
                                            variant="outlined"
                                            size='small'
                                            name='body'
                                            value={commentState.body}
                                            onChange={handleCommentChange}
                                            fullWidth
                                            required
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                position: 'absolute',
                                                bottom: 5,
                                                right: 5,
                                                fontSize: '13px',
                                                borderColor: '1px solid #27A9FF',
                                                color: 'white',
                                                backgroundColor: '#27A9FF',
                                                '&:hover': {
                                                    borderColor: '1px solid #27A9FF',
                                                    color: 'white',
                                                    backgroundColor: '#27A9FF',
                                                }
                                            }}
                                            size='small'
                                            onClick={handleSubmitComment}
                                            disabled={commentState.body === ""}
                                        >
                                            Post
                                        </Button>
                                    </Box>
                                </Box>
                                {comments?.comments?.map(comment => (
                                    <PostComments comment={comment} key={comment.uuid} />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default PostComponent

const styles = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
}