import React, { forwardRef, useEffect, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiBuyContract, apiBuyTheEurContractMinMax, apiGetMinMaxDisplay, apiGetMinMaxRaw, apiGetTheEur } from '@store'
import { showToast } from '@utils'

const DeliveryPoint = [
    'THE'
]

const Buy = forwardRef(({ handleClose, selectedContract, user }, ref) => {
    const dispatch = useDispatch()

    const [showMinMaxInput, setShowMinMaxInput] = useState(false)

    const [state, setState] = useState({
        market: 'THE',
        type: 'buy',
        price_selected: '',
        seller: '',
        buyer: user?.CompanyShort,
        deliverypoint: 'THE',
        volume: '',
        exitpoint: '',
        price_percent: '100',
        ordertype: 'internal',
        currency: 'EUR',
        max_price: '',
        on_max: false,
        min_price: '',
        on_min: false,
        sym: selectedContract?.Symbol
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const [type, setType] = useState('ask')

    const handleTypeChange = (e) => {
        setType(e.target.value)
        if (e.target.value === 'min_max') {
            setShowMinMaxInput(true)
        } else if (e.target.value === 'ask') {
            setShowMinMaxInput(false)
        }
    }

    const handleMinChange = (e) => {
        // If min price is changed, clear max price and disable it
        // If min price is cleared, enable max price
        if (e.target.value === '') {
            setState({
                ...state,
                min_price: e.target.value,
                max_price: '',
                on_min: false,
                on_max: false
            })
        } else {
            setState({
                ...state,
                min_price: e.target.value,
                max_price: '',
                on_min: true,
                on_max: false
            })
        }
    }

    const handleMaxChange = (e) => {
        // If max price is changed, clear min price and disable it
        // If max price is cleared, enable min price
        if (e.target.value === '') {
            setState({
                ...state,
                max_price: e.target.value,
                min_price: '',
                on_max: false,
                on_min: false
            })
        } else {
            setState({
                ...state,
                max_price: e.target.value,
                min_price: '',
                on_max: true,
                on_min: false
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let data;
        if (type === 'ask') {
            // Remove min_max fields
            const { min_price, max_price, on_min, on_max, ...rest } = state
            const volume = parseFloat(state.volume)
            const ask = parseFloat(selectedContract.Data.ask)

            const data = {
                ...rest,
                volume,
                price: { ask: ask }
            }

            const resp = await dispatch(apiBuyContract(data))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                handleClose()
                showToast('success', 'Purchase order succesfully saved')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Error buying contract')
            }
        } else if (type === 'min_max') {
            const minprice = parseFloat(state.min_price)
            const maxprice = parseFloat(state.max_price)

            data = {
                ...state,
                min_price: minprice || 0,
                max_price: maxprice || 0,
                on_min: state.min_price ? true : false,
                on_max: state.max_price ? true : false,
            }

            const resp = await dispatch(apiBuyTheEurContractMinMax(data))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                handleClose()
                dispatch(apiGetMinMaxRaw())
                dispatch(apiGetMinMaxDisplay())
                dispatch(apiGetTheEur())
                showToast('success', 'Purchase order succesfully saved')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Error buying contract')
            }
        }
    }

    // timer
    const [timer, setTimer] = useState(25);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (ref.current) {
            setTimer(25);
        }
    }, [ref]);

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            timer={timer}
            modalTitle={`Buy Contract`}
            buttonText={'Buy'}
            disabled={state.volume ? false : true}
            ref={ref}
        >
            <Box className='flex flex-col gap-3'>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Please Select what you would like to buy
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                        name='type'
                        value={type}
                        onChange={handleTypeChange}
                    >
                        <MenuItem value='ask'>Ask Price</MenuItem>
                        <MenuItem value='min_max'>Min/Max Stop</MenuItem>
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Contract:</Typography>
                    <Typography color={'black'}>{selectedContract.Symbol}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <InputLabel
                        id="contractLabel"
                        sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                    >
                        Volume
                    </InputLabel>
                    <OutlinedInput
                        id="volume"
                        label="Volume"
                        name="volume"
                        onChange={handleChange}
                        value={state.volume}
                        type="number"
                        inputProps={{ step: 0.01 }}
                        required
                        endAdornment={
                            <InputAdornment position="start" sx={{ ml: 2 }}>
                                MW
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Endpoint
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Endpoint"
                        name='exitpoint'
                        value={state.exitpoint}
                        onChange={handleChange}
                    >
                        {user?.Endpoints?.map((endpoint, index) => (
                            <MenuItem key={index} value={endpoint}> {endpoint.name} - {endpoint.location}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Seller
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Seller"
                        name='seller'
                        value={state.seller}
                        onChange={handleChange}
                    >
                        {user?.CanBuyFrom?.map((seller, index) => (
                            <MenuItem key={index} value={seller}>{seller}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                    >
                        Delivery Point
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Delivery Point"
                        name='deliverypoint'
                        value={state.deliverypoint}
                        onChange={handleChange}
                    >
                        {DeliveryPoint?.map((delivery_point, index) => (
                            <MenuItem key={index} value={delivery_point}>{delivery_point}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className='flex gap-3'>
                    <Typography>Price:</Typography>
                    <Typography color={'black'}>{selectedContract.Data.ask}</Typography>
                </Box>
                {/* <Box className='flex gap-3'>
                    <Typography>Settlement Amount:</Typography>
                    <Typography color={'black'}>{selectedContract.Data.ask * state?.volume || 0} </Typography >
                </Box> */}
                {showMinMaxInput &&
                    <Box className='flex gap-3'>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel
                                id="priceLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                            >
                                Max Price
                            </InputLabel>
                            <OutlinedInput
                                id="price"
                                label="Price"
                                type="number"
                                name="maxprice"
                                value={state.maxprice}
                                onChange={handleMaxChange}
                                disabled={state.on_min}
                                required
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel
                                id="priceLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                            >
                                Min Price
                            </InputLabel>
                            <OutlinedInput
                                id="price"
                                label="Price"
                                type="number"
                                name="minprice"
                                value={state.minprice}
                                onChange={handleMinChange}
                                disabled={state.on_max}
                                required
                            />
                        </FormControl>
                    </Box>
                }
                <Box className='flex gap-3'>
                    <Typography>Last Updated:</Typography>
                    <Typography color={'black'}>{ }</Typography>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default Buy