import { Box } from '@mui/material'
import React from 'react'

const CommonContainer = ({ children, sx, padding }) => {
    return (
        <Box className={`rounded-md shadow-md bg-white ${padding ? padding : 'p-6'}`} sx={sx}>
            {children}
        </Box>
    )
}

export default CommonContainer