import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MonthHistoricalPrices from "./MonthHistoricalPrices";
import QuarterHistoricalPrice from "./QuarterHistoricalPrice";
import SeasonsHistoricalPrice from "./SeasonsHistoricalPrice";
import ForwardCurve from "./ForwardCurve";
import { useEffect } from "react";
import { AppName, OverviewTitle } from "@utils";

const Overview = () => {

    useEffect(() => {
        document.title = `${AppName} | ${OverviewTitle}`
    }, [])

    const { monthHistoricalPrices, quarterHistoricalPrices, seasonHistoricalPrices } = useSelector((state) => state.overview);

    return (
        <Box className='flex flex-col gap-8'>
            <Box className='shadow-md border flex justify-center flex-col p-4 bg-white'>
                <Typography variant="h5" style={{ textAlign: 'center' }}>Forward Curve</Typography>
                <ForwardCurve monthHistoricalPrices={monthHistoricalPrices} />
            </Box>
            <Box className='shadow-md border flex justify-center flex-col p-4 bg-white'>
                <Typography variant="h5" style={{ textAlign: 'center' }}>Month Historical Prices</Typography>
                <MonthHistoricalPrices monthHistoricalPrices={monthHistoricalPrices} />
            </Box>
            <Box className='shadow-md border flex justify-center flex-col p-4 bg-white'>
                <Typography variant="h5" style={{ textAlign: 'center' }}>Quarter Historical Prices</Typography>
                <QuarterHistoricalPrice quarterHistoricalPrices={quarterHistoricalPrices} />
            </Box>
            <Box className='shadow-md border flex justify-center flex-col p-4 bg-white'>
                <Typography variant="h5" style={{ textAlign: 'center' }}>Seasons Historical Prices</Typography>
                <SeasonsHistoricalPrice seasonHistoricalPrices={seasonHistoricalPrices} />
            </Box>
        </Box>
    )
}

export default Overview