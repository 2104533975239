import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, List, ListItem } from '@mui/material';

export const HelpQA = [
    {
        question: "What is trailing stop?",
        answer:
            <>
                <Box className='flex gap-1'>
                    <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Trailing stop:</Typography>
                    <Typography>The user must enter contract, bid/ask, ceiling margin, floor margin and volume.</Typography>
                </Box>
                <Box className='flex gap-1'>
                    <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Ceiling margin:</Typography>
                    <Typography>
                        Establishes price level higher than the current price. If the ceiling margin is too close to zero then the user is likely to receive email very frequently.
                    </Typography>
                </Box>
                <Box className='flex gap-1'>
                    <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Floor margin:</Typography>
                    <Typography>
                        Establishes price level lower than the current price. If the floor margin is too close to zero then the user is likely to receive email very frequently.
                    </Typography>
                </Box>
                <Box className='flex gap-1'>
                    <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Trailing stop buy:</Typography>
                    <Typography>Stop loss buy/ buy signal whilst riding the down trend in price.</Typography>
                </Box>
                <Box className='flex gap-1'>
                    <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Trailing stop sell:</Typography>
                    <Typography>Stop loss sell/ sell signal whilst riding the up trend in price.</Typography>
                </Box>
            </>
    },
    {
        question: "What is purchase limit, and how to use it?",
        answer: <Typography>
            Purchase limit is a limit provided on a per user basis, that sets a soft limit on MWh that a user can trade. This is a soft limit, meaning if you set purchase limit of 100, and at the time of purchase the user has a buy or sell sum of 99, the order regardless of the volume will be allowed. However, if the limit is 10 and the user already has bought 11 MWh then the order is not allowed. Purchase limit of 0 or a negative number menas no limit.
        </Typography>
    },
    {
        question: "How to set up notification for the limit orders?",
        answer: <Box className='flex flex-col gap-6'>
            <Typography>
                Price Notifications allows you to set email notification when the contract reaches a certain price. You can set notification rule per contract per market. For example you can set notification for THE or TTF or Biogas on per contract basis.
            </Typography>
            <Typography>
                To set price notification, click on the Set Price Notification button on top of the price table. It will pop up a notification rule window. You can set rule per contract, but only set either higher than or less than on per rule but not both. Once you set the rule, simply save it and the price you set will appear on the main price window. You can hover over the rule and it will tell if you what the notification rule is. When the price hits higher/lower price than what you have set for you will receive email notification.
            </Typography>
            <Typography>
                Note: notification are one time rule, once the notification is sent the rule is removed. If you want to set a rule on the contract, you will have to do it again.
            </Typography>
        </Box>
    },
    {
        question: "Why does my page not automatically display latest values?",
        answer: <>
            <Typography>
                If you are using Microsoft Internet Explorer, then it is possible
                that your browser is caching the request and not displaying
                the correct page. To fix this issue:
            </Typography>
            <List>
                <ListItem>1. Click on the small settings cog at the top right hand corner of the page.</ListItem>
                <ListItem>2. Click on Internet Options</ListItem>
                <ListItem>3. Under General settings find the "Browser history" section and click on Settings</ListItem>
                <ListItem>4. Under Temporary Internet Files ensure "Every Time I visit the webpage" is selected.</ListItem>
                <ListItem>5. Press OK to ensure setting.</ListItem>
            </List>
        </>
    },
    {
        question: "What is trailing FX?",
        answer: <>
            <Box className='flex gap-1'>
                <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Trailing stop:</Typography>
                <Typography>The user must enter contract, bid/ask, ceiling margin, floor margin and volume.</Typography>
            </Box>
            <Box className='flex gap-1'>
                <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Ceiling margin:</Typography>
                <Typography>
                    Establishes price level higher than the current price. If the ceiling margin is too close to zero then the user is likely to receive email very frequently.
                </Typography>
            </Box>
            <Box className='flex gap-1'>
                <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Floor margin:</Typography>
                <Typography>
                    Establishes price level lower than the current price. If the floor margin is too close to zero then the user is likely to receive email very frequently.
                </Typography>
            </Box>
            <Box className='flex gap-1'>
                <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Trailing stop buy:</Typography>
                <Typography>Stop loss buy/ buy signal whilst riding the down trend in price.</Typography>
            </Box>
            <Box className='flex gap-1'>
                <Typography sx={{ fontWeight: 'bold', color: '#27485D' }}>Trailing stop sell:</Typography>
                <Typography>Stop loss sell/ sell signal whilst riding the up trend in price.</Typography>
            </Box>
        </>
    },
    {
        question: "How do I use Profiling?",
        answer: <>
            <Typography>
                Functionality:
            </Typography>
            <List>
                <ListItem>1. Upload a hourly demand profile on a csv</ListItem>
                <ListItem>2. Shift up or down the demand by % for the next 3 years</ListItem>
                <ListItem>3. Add volume flexibility in %</ListItem>
                <ListItem>4. Get one price for the product</ListItem>
            </List>
        </>
    },
    {
        question: "What is profiling CSV format?",
        answer: <>
            <Typography>
                Please see the example below:
            </Typography>
            <Typography>
                No Heading on csv. The first column is the date and the second column is the value.
            </Typography>
            <List>
                <ListItem>01.01.2023 07:00; 0.1</ListItem>
                <ListItem>01.01.2023 07:00; 0.2</ListItem>
                <ListItem>01.01.2023 07:00; 0.3</ListItem>
            </List>
        </>
    },
]