import React, { useMemo, useState } from 'react'
import { Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import AddBoxIcon from '@mui/icons-material/AddBox';
import CreateEndpoint from './CreateEndpoint';
import { apiDeleteEndpoint, apiGetEndpoint } from '@store';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';

const Endpoint = ({ endpoint, isLoading }) => {
    const dispatch = useDispatch()

    const handleDeleteEndpoint = async (endpointToBeDeleted) => {
        const resp = await dispatch(apiDeleteEndpoint({ ...endpointToBeDeleted, deleted: 1 }))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(apiGetEndpoint())
            showToast('info', 'Endpoint Deleted Successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp)
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Endpoint",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Location",
                id: "location",
                accessorFn: ({ location }) => location || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Button
                        key={'btn'}
                        variant='outlined'
                        color='error'
                        sx={{
                            p: 0,
                            m: 0,
                            borderColor: 'brown',
                            color: 'brown',
                            fontSize: '13px',
                            '&:hover': {
                                borderColor: 'brown',
                                color: 'white',
                                backgroundColor: 'brown',
                            },
                        }}
                        onClick={() => { handleDeleteEndpoint(row.original) }}
                    >
                        Delete
                    </Button>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // create endpoint modal
    const [isCreateEndpointModalOpen, setIsCreateEndpointModalOpen] = useState(false)
    const openCreateEndpointModal = () => setIsCreateEndpointModalOpen(true)
    const closeCreateEndpointModal = () => setIsCreateEndpointModalOpen(false)

    return (
        <>
            <Modal open={isCreateEndpointModalOpen} onClose={closeCreateEndpointModal}>
                <CreateEndpoint
                    handleClose={closeCreateEndpointModal}
                    open={isCreateEndpointModalOpen}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <Tooltip title="Create Endpoint">
                                <IconButton
                                    onClick={openCreateEndpointModal}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }
                headerText={'Accounts'}
                columns={columns}
                data={endpoint || []}
                actions={actionsMenu}
                isLoading={isLoading}
            />
        </>
    )
}

export default Endpoint