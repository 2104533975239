import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useChatAuth from "@hooks/useChatAuth";
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from "@utils";

const ChatAuthProvider = () => {
    const { auth } = useChatAuth();
    const location = useLocation()

    return (
        auth
            ? <Outlet />
            : <Navigate
                to={location?.pathname?.includes('gas') ? GAS_PATH.CHAT_LOGIN : location?.pathname?.includes('electricity')? ELECTRICITY_PATH.CHAT_LOGIN : COMMON_PATH.CHAT_LOGIN}
                state={{ from: location }}
                replace
            />
    )
}

export default ChatAuthProvider;