import React, { useEffect, useState } from 'react'
import { CommonContainer } from '@components'
import { Box, Modal, Switch, Typography } from '@mui/material'
import QR from './QR'
import { useDispatch } from 'react-redux'
import { apiGetProfile, apiUpdateOTP } from '@store'
import { showToast } from '@utils'

const FA = ({ user }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        TOTPEnabled: user?.TOTPEnabled || false,
    })

    useEffect(() => {
        setState({
            TOTPEnabled: user?.TOTPEnabled || false,
        })
    }, [user])

    // modal open/close
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        dispatch(apiGetProfile())
        setOpen(false)
    }

    // open modal and call the api when the switch is turned on
    const handleChangeActive = (e) => {
        setState({ ...state, [e.target.name]: e.target.checked })
        if (e.target.checked) {
            handleOpen()
        } else {
            const resp = window.confirm('Are you sure you want to disable 2FA?')
            if (resp) {
                handledisableTOTP()
            } else {
                setState({ ...state, TOTPEnabled: true })
            }
        }
    }

    const handledisableTOTP = async () => {
        const resp = await dispatch(apiUpdateOTP({ TOTPEnabled: false, username: user?.Username }))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(apiGetProfile())
            showToast('success', '2FA disabled successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Something went wrong')
        }
    }

    return (
        <CommonContainer>
            <Modal open={open} onClose={handleClose}>
                <QR handleClose={handleClose} />
            </Modal>
            <Typography sx={{ fontSize: '24px', mb: '4px' }}>2 Factor Authentication</Typography>
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold' }}>Enable</Typography>
                    <Switch
                        checked={state.TOTPEnabled}
                        onChange={handleChangeActive}
                        color="primary"
                        name="TOTPEnabled"
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        Your 2FA is currenty :
                    </Typography>
                    <Typography sx={{ color: '#000' }}>{state.TOTPEnabled ? 'Enabled' : 'Disabled'}</Typography>
                </Box>
            </Box>
        </CommonContainer>
    )
}

export default FA