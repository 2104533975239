import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material'

const Filter = forwardRef(({ handleClose, filter, setFilter }, ref) => {

    const [state, setState] = React.useState({
        ...filter
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const handleBidPriceChange = (event) => {
        setState({
            ...state,
            bidPrice: {
                ...state.bidPrice,
                [event.target.name]: event.target.value
            }
        });
    };

    const handleAskPriceChange = (event) => {
        setState({
            ...state,
            askPrice: {
                ...state.askPrice,
                [event.target.name]: event.target.value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setFilter(state)
        handleClose()
    }

    const resetFilter = () => {
        setFilter({
            typeOfContract: '',
            bidPrice: {
                min: '',
                max: ''
            },
            askPrice: {
                min: '',
                max: ''
            }
        })
        handleClose()
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            filterReset={resetFilter}
            modalTitle={`Filter`}
            buttonText={'Filter'}
            ref={ref}
        >
            <Box>
                <Box>
                    <FormLabel
                        id="demo-radio-buttons-group-label"
                        sx={{
                            fontWeight: 'bold',
                            color: '#245372'
                        }}
                    >
                        Type of Contract
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="typeOfContract"
                        value={state.typeOfContract || ''}
                        onChange={handleChange}
                        sx={{
                            justifyContent: 'space-between'
                        }}
                    >
                        <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                        <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                        <FormControlLabel value="quarterly" control={<Radio />} label="Quarterly" />
                        <FormControlLabel value="summer" control={<Radio />} label="Summer" />
                        <FormControlLabel value="winter" control={<Radio />} label="Winter" />
                    </RadioGroup>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 2
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Bid Price
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 1
                            }}
                        >
                            <FormControl
                                variant="outlined"
                                sx={{
                                    width: '130px'
                                }}
                            >
                                <InputLabel
                                    id="bidPriceMinLabel"
                                >
                                    Min
                                </InputLabel>
                                <OutlinedInput
                                    id="bidPriceMin"
                                    label="Min"
                                    name="min"
                                    onChange={handleBidPriceChange}
                                    value={state.bidPrice?.min || ''}
                                    type="number"
                                />
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                sx={{
                                    width: '130px'
                                }}
                            >
                                <InputLabel
                                    id="bidPriceMaxLabel"
                                >
                                    Max
                                </InputLabel>
                                <OutlinedInput
                                    id="bidPriceMax"
                                    label="Max"
                                    name="max"
                                    onChange={handleBidPriceChange}
                                    value={state.bidPrice?.max || ''}
                                    type="number"
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Ask Price
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                mt: 1
                            }}
                        >
                            <FormControl
                                variant="outlined"
                                sx={{
                                    width: '130px'
                                }}
                            >
                                <InputLabel
                                    id="askPriceMinLabel"
                                >
                                    Min
                                </InputLabel>
                                <OutlinedInput
                                    id="askPriceMin"
                                    label="Min"
                                    name="min"
                                    onChange={handleAskPriceChange}
                                    value={state.askPrice?.min || ''}
                                    type="number"
                                />
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                sx={{
                                    width: '130px'
                                }}
                            >
                                <InputLabel
                                    id="askPriceMaxLabel"
                                >
                                    Max
                                </InputLabel>
                                <OutlinedInput
                                    id="askPriceMax"
                                    label="Max"
                                    name="max"
                                    onChange={handleAskPriceChange}
                                    value={state.askPrice?.max || ''}
                                    type="number"
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    )
})

export default Filter