import React, { forwardRef } from 'react'
import { CustomModal } from '@components'
import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useUnmuteChatUserMutation } from '@store/feature/chat/chatApi'

const UnmuteModal = forwardRef(({ handleClose }, ref) => {
    const { id } = useParams()

    const [unmuteChat] = useUnmuteChatUserMutation()

    const handleSubmit = (e) => {
        e.preventDefault()
        unmuteChat(id)
        handleClose()
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Unmute Notification`}
            buttonText={'Unmute Notification'}
            ref={ref}
        >
            <Typography
                sx={{
                    fontSize: '14px',
                    color: 'black'
                }}
            >
                Unmute notification for this chat?
            </Typography>
        </CustomModal>
    )
})

export default UnmuteModal