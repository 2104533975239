import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { ReactTable, Filter } from '@components'
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Buy from './Buy';
import Sell from './Sell';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetWatchlist, apiSaveToWatchlist, apiGetChf } from '@store';
import { showToast } from '@utils';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const CHF =
    ({
        chf,
        isLoading,
        openFilterModal,
        hanldeOpenFilterModal,
        handleCloseFilterModal,
        disableTradingStatus
    }) => {
        const dispatch = useDispatch()
        const { user } = useSelector((state) => state.user);

        const data = useMemo(() => {
            let tempData = []
            if (chf) {
                tempData = chf?.symbols?.map(symbol => {
                    return {
                        contract: symbol,
                        ...chf?.contracts[symbol]
                    }
                }) || []
            }
            return tempData
        }, [chf])

        const [selectedContract, setSelectedContract] = useState(null)

        // buy modal
        const [openBuyModal, setOpenBuyModal] = useState(false)
        const handleOpenBuyModal = useCallback((selectedContract) => {
            setOpenBuyModal(true)
            setSelectedContract(selectedContract)
        }, [])
        const handleCloseBuyModal = () => setOpenBuyModal(false)

        // sell modal
        const [openSellModal, setOpenSellModal] = useState(false)
        const handleOpenSellModal = useCallback((selectedContract) => {
            setSelectedContract(selectedContract)
            setOpenSellModal(true)
        }, [])
        const handleCloseSellModal = () => setOpenSellModal(false)

        // add to watchlist
        const handleAddToWatchlist = useCallback(async (symbol, product) => {
            try {
                const response = await dispatch(apiSaveToWatchlist({ symbol, product, currency: 'chf' }))
                if (response.meta.requestStatus === 'fulfilled') {
                    showToast('success', 'Added to watchlist')
                    dispatch(apiGetWatchlist())
                    dispatch(apiGetChf())
                } else if (response.meta.requestStatus === 'rejected') {
                    showToast('error', response.error.message)
                }
            } catch (error) {
                showToast('error', error.message)
            }
        }, [dispatch])

        const columns = useMemo(
            () => [
                {
                    header: "Contract",
                    id: "contract",
                    accessorFn: ({ contract }) => contract || "",
                    Cell: ({ renderedCellValue }) =>
                        <Typography
                            sx={{
                                color: '#3B9D9D',
                                fontSize: 'inherit',
                                fontWeight: 'bold',
                                pl: '8px'
                            }}
                        >
                            {renderedCellValue}
                        </Typography>,
                    size: 50
                },
                {
                    header: "Bid",
                    id: "bid",
                    accessorFn: ({ bid }) => bid || "",
                    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                    size: 50
                },
                {
                    header: "Ask",
                    id: "ask",
                    accessorFn: ({ ask }) => ask || "",
                    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                    size: 50
                },
                {
                    header: "Updated At",
                    id: "updated_at_cet_str",
                    accessorFn: ({ updated_at_cet_str }) => updated_at_cet_str || "",
                    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                    size: 50
                },
            ], []
        );

        const actionsMenu = useMemo(
            () =>
                ({ row }) =>
                    [
                        <Box
                            className='flex gap-4 items-center justify-center'
                            key='buttons'
                            onClick={(e) => { e.stopPropagation() }}
                        >
                            {disableTradingStatus === false && user?.HideBuyButton === false &&
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #27A9FF',
                                        color: '#27A9FF',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #27A9FF',
                                            color: 'white',
                                            backgroundColor: '#27A9FF',
                                        },
                                    }}
                                    onClick={() => handleOpenBuyModal(row?.original)}
                                >
                                    Buy
                                </Button>
                            }
                            {disableTradingStatus === false && user?.HideSellButton === false &&
                                <Button
                                    variant='outlined'
                                    color='error'
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #EB6969',
                                        color: '#EB6969',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #EB6969',
                                            color: 'white',
                                            backgroundColor: '#EB6969',
                                        },
                                    }}
                                    onClick={() => handleOpenSellModal(row?.original)}
                                >
                                    Sell
                                </Button>
                            }
                            <Tooltip title='Add to watchlist'>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        color: '#245372',
                                        borderColor: '#245372',
                                        width: '64px',
                                        height: '24.75px',
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: '#245372',
                                        },
                                    }}
                                    onClick={() => handleAddToWatchlist(row.original.contract, 'THE')}
                                    disabled={row.original.watchlist ? true : false}
                                >
                                    {row.original.watchlist ?
                                        <StarIcon
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                fontSize: '18px',
                                                color: '#FFC107'
                                            }}
                                        /> :
                                        <StarBorderIcon sx={{
                                            p: 0,
                                            m: 0,
                                            fontSize: '18px',
                                        }} />
                                    }
                                </Button>
                            </Tooltip>
                        </Box>
                    ],
            [disableTradingStatus, user, handleOpenBuyModal, handleOpenSellModal, handleAddToWatchlist]
        );

        // filter 
        const [filteredData, setFilteredData] = useState([])

        const [filter, setFilter] = useState({
            typeOfContract: '',
            bidPrice: {
                min: '',
                max: ''
            },
            askPrice: {
                min: '',
                max: ''
            }
        })

        useEffect(() => {
            if (data?.length > 0) {
                if (filter.typeOfContract && filter.typeOfContract === "yearly") {
                    setFilteredData(data?.filter(item => item.contract.includes('CAL')) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "monthly") {
                    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
                    setFilteredData(data?.filter(item => months.includes(item.contract.slice(0, 3))) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "quarterly") {
                    setFilteredData(data?.filter(item => item.contract.includes('Q')) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "winter") {
                    setFilteredData(data?.filter(item => item.contract.includes('WINTER')) || [])
                } else if (filter.typeOfContract && filter.typeOfContract === "summer") {
                    setFilteredData(data?.filter(item => item.contract.includes('SUMMER')) || [])
                } else {
                    setFilteredData(data)
                }
            }
        }, [filter, data])

        useEffect(() => {
            if (data?.length > 0) {
                if (filter.bidPrice.min && filter.bidPrice.max) {
                    setFilteredData(data?.filter(item => item.bid >= filter.bidPrice.min && item.bid <= filter.bidPrice.max) || [])
                } else if (filter.bidPrice.min) {
                    setFilteredData(data?.filter(item => item.bid >= filter.bidPrice.min) || [])
                } else if (filter.bidPrice.max) {
                    setFilteredData(data?.filter(item => item.bid <= filter.bidPrice.max) || [])
                } else {
                    setFilteredData(data)
                }
            }
        }, [filter.bidPrice, data])

        useEffect(() => {
            if (data?.length > 0) {
                if (filter.askPrice.min && filter.askPrice.max) {
                    setFilteredData(data?.filter(item => item.ask >= filter.askPrice.min && item.ask <= filter.askPrice.max) || [])
                } else if (filter.askPrice.min) {
                    setFilteredData(data?.filter(item => item.ask >= filter.askPrice.min) || [])
                } else if (filter.askPrice.max) {
                    setFilteredData(data?.filter(item => item.ask <= filter.askPrice.max) || [])
                } else {
                    setFilteredData(data)
                }
            }
        }, [filter.askPrice, data])

        return (
            <>
                {/* buy modal */}
                <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                    <Buy
                        handleClose={handleCloseBuyModal}
                        open={openBuyModal}
                        chf={chf}
                        selectedContract={selectedContract}
                        user={user}
                    />
                </Modal>
                {/* sell modal */}
                <Modal open={openSellModal} onClose={handleCloseSellModal}>
                    <Sell
                        handleClose={handleCloseSellModal}
                        open={openSellModal}
                        chf={chf}
                        selectedContract={selectedContract}
                        user={user}
                    />
                </Modal>
                {/* filter modal */}
                <Modal open={openFilterModal} onClose={handleCloseFilterModal}>
                    <Filter
                        handleClose={handleCloseFilterModal}
                        open={openFilterModal}
                        filter={filter}
                        setFilter={setFilter}
                    />
                </Modal>
                <ReactTable
                    renderToolbarInternalActions={
                        ({ table }) => (
                            <div onMouseDown={(e) => e.stopPropagation()}>
                                <MRT_ToggleGlobalFilterButton table={table} />
                                <Tooltip title='Filter'>
                                    <IconButton
                                        onClick={hanldeOpenFilterModal}
                                    >
                                        <FilterAltOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    }
                    headerText={'CHF'}
                    columns={columns}
                    data={filteredData || []}
                    actions={actionsMenu}
                // isLoading={isLoading}
                />
            </>
        )
    }

export default CHF