import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { ReactTable } from '@components';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import Buy from './Buy';
import { apiDeleteMarketBidAsk, apiGetMarketplace } from '@store';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';

const Ask = ({ data, isLoading }) => {
    const dispatch = useDispatch()
    const [selectedRow, setSelectedRow] = useState(null);

    // open modal
    const [openBuyModal, setOpenBuyModal] = useState(false);
    const handleOpenBuyModal = (selectedRow) => () => {
        setSelectedRow(selectedRow);
        setOpenBuyModal(true);
    }
    const handleCloseModal = () => setOpenBuyModal(false);

    const handleDelete = async (uuid) => {
        const resp = await dispatch(apiDeleteMarketBidAsk(uuid))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('info', 'Ask Deleted Successfully')
            dispatch(apiGetMarketplace())
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Error Deleting Ask')
        }
    }

    // ask
    const columnsForAsk = useMemo(
        () => [
            {
                header: "Contract",
                id: "contract",
                accessorFn: ({ contract }) => contract || "",
                Cell: ({ renderedCellValue }) => <Typography sx={{ color: '#3B9D9D', fontSize: 'inherit', fontWeight: 'bold', pl: '8px' }}>{renderedCellValue}</Typography>,
                size: 50
            },
            {
                header: "Ask",
                id: "price",
                accessorFn: ({ price }) => price || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Currency",
                id: "currency",
                accessorFn: ({ currency }) => currency || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Vol(MW)",
                id: "quantity",
                accessorFn: ({ quantity }) => quantity || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Location",
                id: "location",
                accessorFn: ({ location }) => location || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Type",
                id: "option",
                accessorFn: ({ option }) => option || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    const actionsMenuForAsk = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key='btns'>
                        {row?.original?.uuid !== '' ?
                            <>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: '1px solid #27A9FF',
                                        color: '#27A9FF',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: '1px solid #27A9FF',
                                            color: 'white',
                                            backgroundColor: '#27A9FF',
                                        },
                                    }}
                                    onClick={handleOpenBuyModal(row?.original)}
                                >
                                    Buy
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='error'
                                    sx={{
                                        p: 0,
                                        m: 0,
                                        borderColor: 'brown',
                                        color: 'brown',
                                        fontSize: '13px',
                                        '&:hover': {
                                            borderColor: 'brown',
                                            color: 'white',
                                            backgroundColor: 'brown',
                                        },
                                    }}
                                    onClick={() => handleDelete(row?.original?.uuid)}
                                >
                                    Delete
                                </Button>
                            </> : <Box sx={{ height: '41px' }}></Box>
                        }
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <>
            <Modal
                open={openBuyModal}
                onClose={handleCloseModal}
            >
                <Buy
                    selectedRow={selectedRow}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>
            <ReactTable
                data={data}
                columns={columnsForAsk}
                actions={actionsMenuForAsk}
                headerText={'Ask'}
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                        </div>
                    )
                }
            />
        </>
    )
}

export default Ask