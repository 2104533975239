import React from 'react'
import ChatIcon from '@mui/icons-material/Chat';
import { IconButton } from '@mui/material';

const ChatOverlayButton = ({ handleToggleShowChat }) => {
    return (
        <IconButton
            sx={{
                fontSize: '3rem',
                color: '#fff',
                backgroundColor: '#fff',
                borderRadius: '50%',
                cursor: 'pointer',
                width: '60px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
            }}
            onClick={handleToggleShowChat}
        >
            <ChatIcon
                sx={{
                    color: '#2768C5',
                    fontSize: '1.8rem',
                }}
            />
        </IconButton>
    )
}

export default ChatOverlayButton