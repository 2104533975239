import React from 'react';
import { List } from '@mui/material';
import {
    useGetChatProfileQuery,
    useGetOrSearchChatUsersQuery
} from '@store/feature/chat/chatApi';
import { ChatCard } from './components/ChatCard';
import { FiCommand } from 'react-icons/fi';
  

const ChatSidebarMessages = ({ searchText }) => {
    const { data: profile } = useGetChatProfileQuery();
    const { data: usersData, isLoading  } = useGetOrSearchChatUsersQuery(searchText);

    return (
        <React.Fragment>
             {isLoading && (
                <div className="flex items-center justify-center h-20 gap-4">
                    <span className="text-teal-400 text-xl font-semibold">
                        Loading...
                    </span>
                    <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                </div>
            )}
            <List
                sx={{
                    display: 'flex',
                    pt: '12px',
                    flexDirection: 'column',
                    maxHeight:
                        'calc(100dvh - (101px + 64px + 16px + 40px + 8px))',
                    overflowY: 'scroll',
                    zIndex: 1
                }}
            >
                {usersData?.map((u, index) => {
                    return (
                        <ChatCard
                            imageURL={u?.user?.image_url}
                            name={u?.user?.fullname}
                            key={index}
                            userId={u?.user?.uuid}
                            messageCount={u?.unread_messages}
                            message={u?.latest_message?.body}
                            time={u?.latest_message?.created_at}
                            isActive={
                                u?.user?.uuid === profile?.uuid ||
                                u?.user?.status === 'active'
                            }
                        />
                    );
                })}
            </List>
        </React.Fragment>
    );
};

export default ChatSidebarMessages;
