import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import UserAvatar from '@pages/common/chat/components/UserAvatar';
import CloseIcon from '@mui/icons-material/Close';
import { useGetChatProfileQuery } from '@store/feature/chat/chatApi';
import ChatList from './ChatList';
import SingleChat from './singleChat/SingleChat';
import ChatOverlayTop from './ChatOverlayTop';
import { useLocation } from 'react-router-dom';
import GroupList from './GroupList';
import GroupChat from './singleChat/GroupChat';

const ChatOverlayBox = ({ handleToggleShowChat }) => {
    const { data: profile } = useGetChatProfileQuery();

    const [navigationStack, setNavigationStack] = React.useState([]);

    const location = useLocation();

    const [tab, setTab] = React.useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');
        setTab(tabFromUrl || 'all');
    }, [location.search]);

    const goToSingleChat = (userId) => {
        setNavigationStack([userId]);
    };

    const goBack = () => {
        const stack = [...navigationStack];
        stack.pop();
        setNavigationStack(stack);
    };

    const handleGoToSingleChat = (userId) => {
        goToSingleChat(userId);
    };

    return (
        <Box
            sx={{
                height: '400px',
                width: '300px',
                backgroundColor: '#fff',
                borderRadius: '5px',
                boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)'
            }}
        >
            {navigationStack.length === 0 ? (
                <>
                    <Box
                        sx={{
                            height: '50px',
                            backgroundColor: '#27485D',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 10px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center'
                            }}
                        >
                            <UserAvatar
                                imgSource={profile?.image_url}
                                fullName={profile?.fullname}
                                isActive={profile?.status === 'active'}
                            />
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'white',
                                    fontWeight: 'medium'
                                }}
                            >
                                {profile?.fullname}
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={handleToggleShowChat}
                            handleToggleShowChat={handleToggleShowChat}
                        >
                            <CloseIcon
                                sx={{
                                    color: '#fff',
                                    fontSize: '1.5rem'
                                }}
                            />
                        </IconButton>
                    </Box>
                    <ChatOverlayTop />
                    {tab === 'all' ? (
                        <ChatList
                            handleGoToSingleChat={handleGoToSingleChat}
                            navigationStack={navigationStack}
                        />
                    ) : (
                        <GroupList
                            handleGoToSingleChat={handleGoToSingleChat}
                            navigationStack={navigationStack}
                        />
                    )}
                </>
            ) : tab === 'all' ? (
                <SingleChat
                    navigationStack={navigationStack}
                    handleGoBack={goBack}
                    handleToggleShowChat={handleToggleShowChat}
                />
            ) : (
                <GroupChat
                    navigationStack={navigationStack}
                    handleGoBack={goBack}
                    handleToggleShowChat={handleToggleShowChat}
                />
            )}
        </Box>
    );
};

export default ChatOverlayBox;
