import React, { useEffect, useState } from 'react'
import { CommonContainer } from '@components'
import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiUpdateUserProfile, apiGetProfile } from '@store'
import { showToast } from '@utils'

const Edit = ({ user }) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        fullname: user?.FullName || '',
        account_name: user?.AccountName || '',
        email: user?.Email || '',
        phone: user?.Phone || '',
        username: user?.Username,
        country_code: '+33'
    })

    useEffect(() => {
        setState({
            fullname: user?.FullName || '',
            account_name: user?.AccountName || '',
            email: user?.Email || '',
            phone: user?.Phone?.substring(3) || '',
            username: user?.Username,
            country_code: user?.Phone?.substring(0, 3) || '+33'
        })
    }, [user])

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // send only email, fullname, phone  and username
        const data = {
            email: state.email,
            fullname: state.fullname,
            phone: state.country_code + state.phone,
            username: state.username
        }

        const resp = await dispatch(apiUpdateUserProfile(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(apiGetProfile())
            showToast('success', 'Profile updated successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Failed to update profile')
        }
    }


    return (
        <CommonContainer>
            <Typography sx={{ fontSize: '24px', mb: '12px' }}>Details</Typography>
            <Box component={'form'} display={'flex'} flexDirection={'column'} gap={2} onSubmit={handleSubmit}>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="nameLabel"
                        sx={{ bgcolor: '#FFFFFF' }}
                    >
                        Name
                    </InputLabel>
                    <OutlinedInput
                        id="name"
                        label="name"
                        name="fullname"
                        value={state.fullname}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="accountNameLabel"
                        sx={{ bgcolor: '#FFFFFF' }}
                    >
                        Account name
                    </InputLabel>
                    <OutlinedInput
                        id="accountName"
                        label="account_name"
                        name="account_name"
                        value={state.account_name}
                        onChange={handleChange}
                        disabled
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="emailLabel"
                        sx={{ bgcolor: '#FFFFFF' }}
                    >
                        Email address
                    </InputLabel>
                    <OutlinedInput
                        id="email"
                        label="email"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                    />
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <FormControl sx={{ width: '90px' }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='country_code'
                            value={state.country_code}
                            onChange={handleChange}
                        >
                            <MenuItem value='+33'>+33</MenuItem>
                            <MenuItem value='+41'>+41</MenuItem>
                            <MenuItem value='+44'>+44</MenuItem>
                            <MenuItem value='+49'>+49</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="phoneLabel"
                            sx={{ bgcolor: '#FFFFFF' }}
                        >
                            Phone number
                        </InputLabel>
                        <OutlinedInput
                            id="phone"
                            label="phone"
                            name="phone"
                            value={state.phone}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Box>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        bgcolor: '#27A9FF',
                        color: '#FFFFFF',
                        '&:hover': {
                            bgcolor: '#27A9FF'
                        }
                    }}
                >
                    Save Profile
                </Button>
            </Box>
        </CommonContainer >
    )
}

export default Edit