import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { useDispatch } from 'react-redux'
import { apiCreateEndpoint, apiGetEndpoint } from '@store'
import { showToast } from '@utils'

const CreateEndpoint = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        name: '',
        location: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const resp = await dispatch(apiCreateEndpoint(state))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetEndpoint())
            showToast('success', 'Endpoint Created Successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp)
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Creeate New Endpoint`}
            buttonText={'Submit'}
            ref={ref}
        >
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel
                    id="priceGtLabel"
                    sx={{ bgcolor: '#F5F5F5' }}
                >
                    Name
                </InputLabel>
                <OutlinedInput
                    id="name"
                    label="nameLabel"
                    name='name'
                    placeholder='Endpoint Name'
                    onChange={handleChange}
                    value={state.name}
                />
            </FormControl>
            <FormControl fullWidth={true} variant="outlined" sx={{ mt: 2 }}>
                <InputLabel
                    id="priceGtLabel"
                    sx={{ bgcolor: '#F5F5F5' }}
                >
                    Location
                </InputLabel>
                <OutlinedInput
                    id="location"
                    label="locationLabel"
                    name='location'
                    placeholder='Endpoint Location'
                    onChange={handleChange}
                    value={state.location}
                />
            </FormControl>
        </CustomModal>
    )
})

export default CreateEndpoint