import { Box, IconButton, Typography } from '@mui/material';
// import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {
    useGetChatProfileQuery,
    useGetGroupMessagesQuery,
    // useReadGroupMessageMutation,
    useGetChatGroupQuery
} from '@store/feature/chat/chatApi';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FaArrowDown } from 'react-icons/fa';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import React, { useState, useRef, useCallback, useEffect } from 'react';
import MessageBox from '../GroupMessageBox';
import SendGroupChat from './SendGroupChat';
import { Link, useLocation } from 'react-router-dom';
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';
import GroupTopBar from './GroupTopBar';

const GroupChat = ({ handleGoBack, handleToggleShowChat, navigationStack }) => {
    const location = useLocation();

    const id = navigationStack[navigationStack.length - 1];
    const { data: profile } = useGetChatProfileQuery();
    const { data: groupProfile } = useGetChatGroupQuery(id);
    const { data: groupMessages } = useGetGroupMessagesQuery(id);

    const bottomRef = useRef(null);
    const [scrollToLatestMessage, setScrollToLatestMessage] = useState(true);
    console.log('scroll single chat status', scrollToLatestMessage);
    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'auto' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 100);
    }, [groupMessages]);

    const groupMessagesById = (group) => {
        if (!group) return [];

        const groupedMessages = [];
        let currentGroup = [];

        group.forEach((message, index) => {
            if (
                currentGroup.length === 0 ||
                currentGroup[0].from === message.from
            ) {
                currentGroup.push(message);
            } else {
                groupedMessages.push(currentGroup);
                currentGroup = [message];
            }
        });

        if (currentGroup.length > 0) {
            groupedMessages.push(currentGroup);
        }

        return groupedMessages;
    };

    const groupedMessages = groupMessagesById(groupMessages);
    console.log('groupedMessages', groupedMessages);

    const handleNotInView = useCallback(() => {
        setScrollToLatestMessage(false);
    }, []);

    const handleInView = useCallback(() => {
        setScrollToLatestMessage(true);
    }, []);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    handleNotInView();
                } else {
                    handleInView();
                }
            });
        };

        const observerOptions = {
            root: null, // Use the viewport as the root
            threshold: 0 // Trigger when the element is not visible at all
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        if (bottomRef.current) {
            observer.observe(bottomRef.current);
        }

        return () => {
            if (bottomRef.current) {
                observer.unobserve(bottomRef.current);
            }
        };
    }, [groupMessages, handleNotInView, handleInView]);

    const handleScrollDown = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 500);
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    backgroundColor: '#27485D',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 10px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center'
                    }}
                    onClick={handleGoBack}
                >
                    <IconButton
                        sx={{
                            padding: '0',
                            margin: '0'
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                color: '#fff',
                                fontSize: '1.5rem',
                                padding: '0',
                                margin: '0'
                            }}
                        />
                    </IconButton>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'white',
                            fontWeight: 'medium'
                        }}
                    >
                        Go to Chats
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        component={Link}
                        to={`${
                            location.pathname.includes('gas')
                                ? `${GAS_PATH.CHAT}/group/${id}?tab=groups`
                                : location.pathname.includes('electricity')
                                ? `${ELECTRICITY_PATH.CHAT}/group/${id}?tab=groups`
                                : `${COMMON_PATH.CHAT}/group/${id}?tab=groups`
                        }`}
                    >
                        <IconButton>
                            <OpenInFullIcon
                                sx={{
                                    color: '#fff',
                                    fontSize: '1.2rem'
                                }}
                            />
                        </IconButton>
                    </Box>
                    <IconButton onClick={handleToggleShowChat}>
                        <CloseIcon
                            sx={{
                                color: '#fff',
                                fontSize: '1.5rem'
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <GroupTopBar groupProfile={groupProfile} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: 'calc(100% - 100px)'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        width: '100%',
                        px: '10px',
                        pt: '10px',
                        overflowY: 'scroll',
                        height: '100%'
                    }}
                >
                    <Box sx={{ mt: 'auto' }}>
                        {groupedMessages?.map((group, groupIndex) => (
                            <div
                                key={groupIndex}
                                className="flex flex-col pb-10"
                            >
                                <div
                                    className={`flex flex-col ${
                                        group[0].from === profile.uuid
                                            ? 'items-end'
                                            : 'items-start'
                                    }`}
                                >
                                    <div className="flex gap-1 items-end ml-[10px] mt-4">
                                        <span
                                            className={`text-sm font-normal text-[12px] ${
                                                group[0].from === profile.uuid
                                                    ? 'text-right'
                                                    : 'text-left'
                                            } mx-2 `}
                                        >
                                            {group[0].from !== profile.uuid && (
                                                <span className="px-[8px] text-[12px]">
                                                    {group[0].user.fullname}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <Box key={groupIndex}>
                                        {group.map((message, index) => (
                                            <div
                                                key={message.id}
                                                className={`flex gap-1 items-end mx-0 ${
                                                    message?.from ===
                                                    profile?.uuid
                                                        ? 'justify-end'
                                                        : 'justify-start'
                                                }`}
                                            >
                                                {message.from !==
                                                    profile.uuid && (
                                                    <img
                                                        src={
                                                            message.user
                                                                .image_url
                                                        }
                                                        alt="group image"
                                                        className="w-4 h-4 rounded-full mb-2 ml-0"
                                                        style={{
                                                            visibility:
                                                                index ===
                                                                group.length - 1
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                        }}
                                                    />
                                                )}
                                                <MessageBox
                                                    id={message?.uuid}
                                                    messageText={message?.body}
                                                    timestamp={
                                                        message?.created_at
                                                    }
                                                    isRight={
                                                        message?.from ===
                                                        profile?.uuid
                                                    }
                                                    key={index}
                                                    status={message?.status}
                                                    files={message?.files}
                                                />
                                            </div>
                                        ))}
                                    </Box>
                                </div>
                            </div>
                        ))}
                        <div id="bottom" ref={bottomRef} />
                    </Box>
                </Box>
                {!scrollToLatestMessage && (
                    <div className="relative w-full">
                        <span className="absolute left-1/2 transform -translate-x-1/2 bottom-2">
                            <button
                                className="z-100 rounded-full flex justify-center items-center bg-[#2FACFE] border-1 border-gray-300 w-6 h-6 p-1"
                                onClick={handleScrollDown}
                            >
                                <FaArrowDown color="white" />
                            </button>
                        </span>
                    </div>
                )}
                <SendGroupChat
                    id={groupProfile?.uuid}
                    groupProfile={groupProfile}
                    userProfile={profile}
                />
            </Box>
        </Box>
    );
};

export default GroupChat;
