import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { CommonContainer } from '@components'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import KeyIcon from "@mui/icons-material/Key";
import { useDispatch } from 'react-redux';
import { apiChangePassword } from '@store';
import { showToast, AppName, ChangePasswordTitle } from '@utils';

const ChangePassword = () => {
    useEffect(() => {
        document.title = `${AppName} | ${ChangePasswordTitle}`
    }, [])

    const dispatch = useDispatch();
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const [state, setState] = useState({
        password: '',
        new_password_repeat: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resp = await dispatch(apiChangePassword(state));
        if (resp.meta.requestStatus === 'fulfilled') {
            showToast('success', 'Password changed successfully');
            setState({
                password: '',
                new_password_repeat: '',
            });
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', 'Password change failed');
        } else {
            showToast('error', 'Something went wrong');
        }
    }

    return (
        <CommonContainer>
            <Box component={'form'} display={'flex'} flexDirection={'column'} gap={2} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel htmlFor="login-password" sx={{ bgcolor: 'white', p: '0px 10px 0px 0px ' }}>
                                New Password
                            </InputLabel>
                            <OutlinedInput
                                label="Password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                name='password'
                                value={state.password}
                                required
                                type={showPass ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowPass(!showPass)
                                            }
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            }
                                            edge="end"
                                        >
                                            {showPass ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel htmlFor="login-password">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                label="Confirm Password"
                                value={state.new_password_repeat}
                                autoComplete="current-password"
                                onChange={handleChange}
                                name='new_password_repeat'
                                required
                                type={showConfirmPass ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowConfirmPass(!showConfirmPass)
                                            }
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            }
                                            edge="end"
                                        >
                                            {showConfirmPass ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={state.password === '' || state.new_password_repeat === '' || state.password !== state.new_password_repeat}
                >
                    Change Password
                </Button>
            </Box>
        </CommonContainer>
    )
}

export default ChangePassword