import { forwardRef, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ELECTRICITY_PATH, GAS_PATH, AppName, HomeTitle } from "@utils"
import { CustomModal } from "@components"
import { Modal } from "@mui/material"

const Home = () => {
    // open modal
    const [isOpen, setIsOpen] = useState(false)
    const handleOpenModal = () => setIsOpen(true)
    const handleCloseModal = () => setIsOpen(false)

    useEffect(() => {
        document.title = `${AppName} | ${HomeTitle}`
    }, [])

    return (
        <>
            <Modal open={isOpen} handleClose={handleCloseModal}>
                <ConfirmModal handleClose={handleCloseModal} />
            </Modal>
            <div className="flex justify-center items-center gap-7 h-[calc(100vh-110px)]">
                <div className="flex flex-col gap-20 items-center">
                    <p className='text-3xl'>
                        Please select an option
                    </p>
                    <div className="flex gap-3">
                        <Link to={GAS_PATH.NEWS_FEED} className="hover:bg-blue-700 text-white text-2xl py-6 px-10 w-60 text-center rounded bg-gradient-to-r from-[#27485D] to-[#27A9FF] hover:shadow-xl">
                            Gas
                        </Link>
                        <Link onClick={handleOpenModal} className="hover:bg-blue-700 text-white text-2xl py-6 px-10 w-60 text-center rounded bg-gradient-to-r from-[#27485D] to-[#3B9D9D] hover:shadow-xl">
                            Electricity
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home

const ConfirmModal = forwardRef(({ handleClose }, ref) => {
    const navigate = useNavigate()

    const handleSubmit = () => {
        navigate(ELECTRICITY_PATH.NEWS_FEED)
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Confirm`}
            modalTitleColor={'#EB6969'}
            className='modal-button-danger'
            buttonText={'Yes'}
            ref={ref}
        >
            Are you sure you have the trading right to trade with CKW. Please do not click buy or sell buttons if you do not have the right to trade.
        </CustomModal>
    )
})