import { Box, Typography, useMediaQuery } from '@mui/material';

import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ChatSidebarMessages from './ChatSidebarMessages';
import ChatSidebarTop from './ChatSidebarTop';
import ChatSidebarGroups from './ChatSidebarGroups';

import { useLocation } from 'react-router-dom';

const Chat = () => {
    const { id } = useParams();
    const [tab, setTab] = React.useState('all');
    const [showGroupForm, setShowGroupForm] = React.useState(false);
    const [clickNext, setClickNext] = React.useState(false);
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');

        if (tab !== tabFromUrl) {
            setTab(tabFromUrl || 'all');
        }
    }, [location.search, tab]);

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');
    const isSmallerScreen = useMediaQuery('(max-width: 900px)');

    // Search

    const searchRef = useRef(null);
    const searchTimeout = 500;

    const [searchText, setSearchText] = React.useState('');

    const handleChange = (e) => {
        e.preventDefault();

        const text = e.target.value;

        // If search is already set, clear it
        if (searchRef.current) {
            clearTimeout(searchRef.current);
        }

        // Set a new search timeout
        searchRef.current = setTimeout(() => {
            setSearchText(text);
        }, searchTimeout);
    };

    const hideForm = () => {
        setShowGroupForm(!showGroupForm);
    };

    const nextButton = () => {
        setClickNext(!clickNext);
    };

    return (
        <Box
            sx={{
                height: 'calc(100dvh - 95px)',
                width: '100%',
                maxHeight: '100dvh',
                display: 'flex',
                gap: '4px'
            }}
        >
            {!isSmallerScreen && (
                <Box
                    sx={{
                        width: isSmallScreen ? '15%' : '100%',
                        maxWidth: '256px',
                        overflow: 'auto',
                        backgroundColor: 'white',
                        borderRadius: '4px'
                    }}
                >
                    <style>
                        {`
            div::-webkit-scrollbar {
                display: none;
            }
        `}
                    </style>
                    <ChatSidebarTop
                        handleChange={handleChange}
                        hideForm={hideForm}
                        showGroupForm={showGroupForm}
                        clickNext={clickNext}
                    />
                    {tab === 'all' ? (
                        <ChatSidebarMessages
                            searchText={searchText}
                            handleChange={handleChange}
                        />
                    ) : tab === 'groups' ? (
                        <ChatSidebarGroups
                            searchText={searchText}
                            hideForm={hideForm}
                            showGroupForm={showGroupForm}
                            nextButton={nextButton}
                            clickNext={clickNext}
                            setClickNext={setClickNext}
                            setShowGroupForm={setShowGroupForm}
                            handleChange={handleChange}
                        />
                    ) : null}
                </Box>
            )}
            <Box
                sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexFlow: 'column',
                    overflow: 'hidden'
                }}
            >
                <Outlet />

                {!id && (
                    <Box
                        sx={{
                            flex: '1 0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography>Welcome to Priceblick Chat</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Chat;
