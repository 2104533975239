import React, { forwardRef } from 'react'
import { CustomModal } from '@components'

const TermsAndCondition = forwardRef(({ handleClose }, ref) => {
    return (
        <CustomModal
            handleClose={handleClose}
            // handleSubmit={handleBuy}
            modalTitle={`Terms and Condition`}
            buttonText={'Agree'}
            ref={ref}
        >
            Terms and Condition
        </CustomModal>
    )
})

export default TermsAndCondition