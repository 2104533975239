import { refreshAccessToken } from '@store/feature/chat/chatApi';

let webSocket = null;

export const connectWebSocket = (url) => {
    const token = localStorage.getItem('token');

    if (token) {
        webSocket = new WebSocket(`${url}?token=${token}`);

        webSocket.onopen = () => {
            console.log('WebSocket connected');
        };

        webSocket.onclose = () => {
            // try to reconnect using exponential backoff
            console.log('WebSocket disconnected');

            let timeout = 1000;
            const maxTimeout = 60000;
            let keepRetrying = true;

            const reconnect = () => {
                setTimeout(async () => {
                    if (keepRetrying) {
                        try {
                            const tokenResp = await refreshAccessToken();

                            if (
                                tokenResp?.error === 'Invalid refresh token' ||
                                tokenResp?.error === 'No refresh token found'
                            ) {
                                keepRetrying = false;
                            } else if (tokenResp?.data) {
                                webSocket = new WebSocket(
                                    `${url}?token=${tokenResp?.data}`
                                );

                                webSocket.onopen = () => {
                                    console.log('WebSocket reconnected');
                                };

                                webSocket.onclose = () => {
                                    reconnect();
                                };

                                webSocket.onerror = (error) => {
                                    console.log('WebSocket error', error);
                                };
                            }
                        } catch (error) {}
                    }
                }, timeout);

                timeout = Math.min(timeout * 2, maxTimeout);
            };

            reconnect();
        };

        webSocket.onerror = (error) => {
            console.log('WebSocket error', error);
        };
    }

    return webSocket;
};

export const disconnectWebSocket = () => {
    if (webSocket) {
        webSocket.close();
    }

    webSocket = null;
};

export const statusWebSocket = () => {
    if (webSocket) {
        return webSocket.readyState;
    }

    return null;
};

export const setupMessageListener = (callback) => {
    if (webSocket) {
        webSocket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            callback(data);
        };
    }
};

export default webSocket;
