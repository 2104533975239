import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    theeur: [],
    isGetTheEurLoading: false,
    chf: [],
    isGetChfLoading: false,
    setPriceProfile: [],
    isGetSetPriceProfileLoading: false,
    isCreatePriceProfileLoading: false,
    isBuySetPriceProfileLoading: false,
    isDeletePriceProfileLoading: false,
    minMaxDisplay: [],
    isGetMinMaxDisplayLoading: false,
    trailingStopForDisplay: [],
    isGetTrailingStopLoading: false,
    minMaxOrders: [],
    disableTrading: {},
    isGetMinMaxOrdersLoading: false,
    isBuyLoading: false,
    isSavetoWatchlistLoading: false,
    isSaveTrailingStop: false,
    isSetDisableTradingLoading: false,
    isGetDisableTradingLoading: false,
};

export const apiGetTheEur = createAsyncThunk(
    "the/getEur",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/getdata');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetChf = createAsyncThunk(
    "chf/getChf",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getdata/chf');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetSetPriceProfile = createAsyncThunk(
    "priceprofile/getSetPriceProfile",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`/api/set/getpriceprofile`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiSavePriceProfile = createAsyncThunk(
    "priceprofile/savePriceProfile",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/savepriceprofile', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiBuySetPriceProfile = createAsyncThunk(
    "priceprofile/buySetPriceProfile",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/buypriceprofile', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteTheProfile = createAsyncThunk(
    "priceprofile/deletePriceProfile",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/deletepriceprofile', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetMinMaxDisplay = createAsyncThunk(
    "minmax/getMinMaxDisplay",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`/api/set/getMinMaxForDisplay`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetTrailingStopForDisplay = createAsyncThunk(
    "trailingstop/getTrailingStop",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`/api/set/getTrailingStopForDisplay`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetMinMaxOrders = createAsyncThunk(
    "minmax/getMinMaxOrders",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`/api/set/getMinMaxOrders`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// buy contract \ ask price
export const apiBuyContract = createAsyncThunk(
    "the/buyTheEurContract",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/saveorder', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// buy contract \ min max price
export const apiBuyTheEurContractMinMax = createAsyncThunk(
    "the/buyTheEurContractMinMax",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/saveMinMaxOrder', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// save to watchlist
export const apiSaveToWatchlist = createAsyncThunk(
    "the/saveToWatchlist",
    async ({ symbol, product, currency }, thunkAPI) => {
        console.log(currency)
        try {
            const response = await axios.post(`/api/save_to_watchlist?symbol=${symbol}&product=${product}&currency=${currency || ''}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// save trailing stop
export const apiSaveTrailingStop = createAsyncThunk(
    "the/saveTrailingStop",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/saveTrailingStop', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

// set disable trading
export const apiSetDisableTrading = createAsyncThunk(
    "the/setDisableTrading",
    async (disableTrading, thunkAPI) => {
        try {
            const response = await axios.post(`/api/disable_trading?disable=${disableTrading.disable}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

// get disable trading
export const apiGetDisableTrading = createAsyncThunk(
    "the/getDisableTrading",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get(`/api/disable_trading`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

// export const apiBuy
export const theSlice = createSlice({
    name: "the",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get TTF EUR
        builder
            .addCase(apiGetTheEur.pending, (state) => {
                state.isGetTheEurLoading = true;
            })
            .addCase(apiGetTheEur.fulfilled, (state, action) => {
                state.theeur = action.payload.data;
                state.isGetTheEurLoading = false;
            })
            .addCase(apiGetTheEur.rejected, (state) => {
                state.isGetTheEurLoading = false;
            });

        // Get CHF
        builder
            .addCase(apiGetChf.pending, (state) => {
                state.isGetChfLoading = true;
            })
            .addCase(apiGetChf.fulfilled, (state, action) => {
                state.chf = action.payload.data;
                state.isGetChfLoading = false;
            })
            .addCase(apiGetChf.rejected, (state) => {
                state.isGetChfLoading = false;
            });

        // Get Set Priceprofile
        builder
            .addCase(apiGetSetPriceProfile.pending, (state) => {
                state.isGetSetPriceProfileLoading = true;
            })
            .addCase(apiGetSetPriceProfile.fulfilled, (state, action) => {
                state.isGetSetPriceProfileLoading = false;
                state.setPriceProfile = action.payload.data;
            })
            .addCase(apiGetSetPriceProfile.rejected, (state) => {
                state.isGetSetPriceProfileLoading = false;
            });

        // Save Price Profile
        builder
            .addCase(apiSavePriceProfile.pending, (state) => {
                state.isCreatePriceProfileLoading = true;
            })
            .addCase(apiSavePriceProfile.fulfilled, (state) => {
                state.isCreatePriceProfileLoading = false;
            })
            .addCase(apiSavePriceProfile.rejected, (state) => {
                state.isCreatePriceProfileLoading = false;
            });

        // Buy Price Profile
        builder
            .addCase(apiBuySetPriceProfile.pending, (state) => {
                state.isBuySetPriceProfileLoading = true;
            })
            .addCase(apiBuySetPriceProfile.fulfilled, (state) => {
                state.isBuySetPriceProfileLoading = false;
            })
            .addCase(apiBuySetPriceProfile.rejected, (state) => {
                state.isBuySetPriceProfileLoading = false;
            });

        // Delete Price Profile
        builder
            .addCase(apiDeleteTheProfile.pending, (state) => {
                state.isDeletePriceProfileLoading = true;
            })
            .addCase(apiDeleteTheProfile.fulfilled, (state) => {
                state.isDeletePriceProfileLoading = false;
            })
            .addCase(apiDeleteTheProfile.rejected, (state) => {
                state.isDeletePriceProfileLoading = false;
            });

        // Get Min Max Display
        builder
            .addCase(apiGetMinMaxDisplay.pending, (state) => {
                state.isGetMinMaxDisplayLoading = true;
            })
            .addCase(apiGetMinMaxDisplay.fulfilled, (state, action) => {
                state.isGetMinMaxDisplayLoading = false;
                state.minMaxDisplay = action.payload.data;
            })
            .addCase(apiGetMinMaxDisplay.rejected, (state) => {
                state.isGetMinMaxDisplayLoading = false;
            });

        // Get Trailing Stop
        builder
            .addCase(apiGetTrailingStopForDisplay.pending, (state) => {
                state.isGetTrailingStopLoading = true;
            })
            .addCase(apiGetTrailingStopForDisplay.fulfilled, (state, action) => {
                state.isGetTrailingStopLoading = false;
                state.trailingStopForDisplay = action.payload.data;
            })
            .addCase(apiGetTrailingStopForDisplay.rejected, (state) => {
                state.isGetTrailingStopLoading = false;
            });

        // Get Min Max Orders
        builder
            .addCase(apiGetMinMaxOrders.pending, (state) => {
                state.isGetMinMaxOrdersLoading = true;
            })
            .addCase(apiGetMinMaxOrders.fulfilled, (state, action) => {
                state.isGetMinMaxOrdersLoading = false;
                state.minMaxOrders = action.payload.data;
            })
            .addCase(apiGetMinMaxOrders.rejected, (state) => {
                state.isGetMinMaxOrdersLoading = false;
            });

        // Buy Contract
        builder
            .addCase(apiBuyContract.pending, (state) => {
                state.isBuyLoading = true;
            })
            .addCase(apiBuyContract.fulfilled, (state) => {
                state.isBuyLoading = false;
            })
            .addCase(apiBuyContract.rejected, (state) => {
                state.isBuyLoading = false;
            });

        // Buy Contract Min Max
        builder
            .addCase(apiBuyTheEurContractMinMax.pending, (state) => {
                state.isBuyLoading = true;
            })
            .addCase(apiBuyTheEurContractMinMax.fulfilled, (state) => {
                state.isBuyLoading = false;
            })
            .addCase(apiBuyTheEurContractMinMax.rejected, (state) => {
                state.isBuyLoading = false;
            });

        // Save to Watchlist
        builder
            .addCase(apiSaveToWatchlist.pending, (state) => {
                state.isSavetoWatchlistLoading = true;
            })
            .addCase(apiSaveToWatchlist.fulfilled, (state) => {
                state.isSavetoWatchlistLoading = false;
            })
            .addCase(apiSaveToWatchlist.rejected, (state) => {
                state.isSavetoWatchlistLoading = false;
            });

        // save trailing stop
        builder
            .addCase(apiSaveTrailingStop.pending, (state) => {
                state.isSaveTrailingStop = true;
            })
            .addCase(apiSaveTrailingStop.fulfilled, (state) => {
                state.isSaveTrailingStop = false;
            })
            .addCase(apiSaveTrailingStop.rejected, (state) => {
                state.isSaveTrailingStop = false;
            })

        // set disable trading
        builder
            .addCase(apiSetDisableTrading.pending, (state) => {
                state.isSetDisableTradingLoading = true;
            })
            .addCase(apiSetDisableTrading.fulfilled, (state) => {
                state.isSetDisableTradingLoading = false;
            })
            .addCase(apiSetDisableTrading.rejected, (state) => {
                state.isSetDisableTradingLoading = false;
            })

        // get disable trading
        builder
            .addCase(apiGetDisableTrading.pending, (state) => {
                state.isGetDisableTradingLoading = true;
            })
            .addCase(apiGetDisableTrading.fulfilled, (state, action) => {
                state.isGetDisableTradingLoading = false;
                state.disableTrading = action.payload.data;
            })
            .addCase(apiGetDisableTrading.rejected, (state) => {
                state.isGetDisableTradingLoading = false;
            })
    }
});

export default theSlice.reducer;