import React, { useMemo } from 'react'
import { Button, Typography } from '@mui/material';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { ReactTable } from '@components';
import { useDispatch } from 'react-redux';
import { apiDeleteLimitOrder, apiGetLimitOrder } from '@store';
import { showToast } from '@utils';

const LimitOrder = ({ limitOrder, isGetLimitOrderLoading }) => {
    const dispatch = useDispatch()

    const handleDelete = async (row) => {
        if (window.confirm('Are you sure you want to delete this Limit Order?')) {
            const resp = await dispatch(apiDeleteLimitOrder(row))
            if (resp.meta.requestStatus === 'fulfilled') {
                dispatch(apiGetLimitOrder())
                showToast('info', 'Limit Order deleted successfully');
            } else if (resp.meta.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Failed to delete Limit Order');
            }
        }
    }

    // limit order
    const limitOrderColumn = useMemo(
        () => [
            {
                header: "Contract",
                id: "contract",
                accessorFn: ({ contract }) => contract || "",
                Cell: ({ renderedCellValue }) =>
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}>
                        {renderedCellValue}
                    </Typography>,
                size: 50
            },
            {
                header: "Provider",
                id: "provider",
                accessorFn: ({ provider }) => provider || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Market",
                id: "market",
                accessorFn: ({ market }) => market || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Type",
                id: "type",
                accessorFn: ({ type }) => type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Rule",
                id: "rule",
                Cell: ({ row }) =>
                    <>
                        {
                            row?.original?.price_gt !== 0 ?
                                "If greater than" :
                                row?.original?.price_lt !== 0 ?
                                    "If less than" :
                                    ""
                        }
                    </>,
                size: 50
            },
            {
                header: "Price",
                id: "price_gt",
                Cell: ({ row }) =>
                    <>
                        {
                            row?.original?.price_gt !== 0 ?
                                row?.original?.price_gt?.toFixed(3) :
                                row?.original?.price_lt !== 0 ?
                                    row?.original?.price_lt?.toFixed(3) : ""
                        }
                    </>,
                size: 50
            },
        ], []
    );

    const limitOrderActionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Button
                        key={'delete'}
                        variant='outlined'
                        color='error'
                        sx={{
                            p: 0,
                            m: 0,
                            borderColor: 'brown',
                            color: 'brown',
                            fontSize: '13px',
                            '&:hover': {
                                borderColor: 'brown',
                                color: 'white',
                                backgroundColor: 'brown',
                            },
                        }}
                        onClick={() => handleDelete(row?.original)}
                    >
                        Delete
                    </Button>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <ReactTable
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                    </div>
                )
            }
            headerText={'Limit Order'}
            columns={limitOrderColumn}
            data={limitOrder || []}
            actions={limitOrderActionsMenu}
            isLoading={isGetLimitOrderLoading}
        />
    )
}

export default LimitOrder