import { Box, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import UserAvatar from '../components/UserAvatar';
import { useLocation, useParams } from 'react-router-dom';
import MessageBox from '../components/MessageBox';
import ChatDetails from '../components/ChatDetails';
import {
    useGetChatProfileQuery,
    useGetChatUserQuery,
    useGetChatMessagesQuery,
    useReadChatMessageMutation
} from '@store/feature/chat/chatApi';
import SendChat from '../components/SendChat';
import { FiCommand } from 'react-icons/fi';

import {
    useGetChatGroupQuery,
    useGetGroupMessagesQuery
} from '../../../../store/feature/chat/chatApi';
import SendGroupChat from '../components/SendGroupChat';
import GroupMessageBox from '../components/GroupMessageBox';
import GroupChatDetails from '../components/GroupChatDetails';

export const MainChatContent = () => {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab') || 'all';

    const { data: profile } = useGetChatProfileQuery();
    let { data: userProfile, isLoading, error } = useGetChatUserQuery(id);
    let { data: groupProfile, isLoading: isGroupLoading } =
        useGetChatGroupQuery(id);
    let { data: messages } = useGetChatMessagesQuery(id);
    let { data: groupMessages } = useGetGroupMessagesQuery(id);

    const [readMessage] = useReadChatMessageMutation();

    // show chat details on click
    const [showChatDetails, setShowChatDetails] = useState(false);

    const bottomRef = useRef(null);

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, groupMessages]);

    const handleShowChatDetails = () => {
        setShowChatDetails(!showChatDetails);
    };

    const handleChatDetailsClose = () => {
        setShowChatDetails(false);
    };

    // Mark messages as read when the user visits the page (only once)
    useEffect(() => {
        if (id && profile?.uuid !== id) {
            if (messages?.length > 0) {
                const lastMessage = messages[messages.length - 1];
                if (
                    lastMessage?.from === id &&
                    (lastMessage?.status === 'sent' ||
                        lastMessage?.status === 'delivered')
                ) {
                    readMessage(lastMessage?.uuid);
                }
            }
        }
    }, [id, profile, messages, readMessage]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-20 gap-4">
                <span className="text-teal-400 text-xl font-semibold">
                    Loading...
                </span>
                <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
            </div>
        );
    }

    if (error) {
        userProfile = {};
        messages = [];
    }

    const groupMessagesById = (group) => {
        if (!group) return [];

        const groupedMessages = [];
        let currentGroup = [];

        group.forEach((message, index) => {
            if (
                currentGroup.length === 0 ||
                currentGroup[0].from === message.from
            ) {
                currentGroup.push(message);
            } else {
                groupedMessages.push(currentGroup);
                currentGroup = [message];
            }
        });

        if (currentGroup.length > 0) {
            groupedMessages.push(currentGroup);
        }

        return groupedMessages;
    };

    const groupedMessages = groupMessagesById(groupMessages);

    return (
        <Box sx={{ display: 'flex', gap: '3px' }}>
            <Box sx={{ flex: 1 }}>
                <Box
                    sx={{
                        backgroundColor: '#27485D',
                        height: '64px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: '16px',
                        position: 'sticky',
                        flex: '0 0 auto',
                        top: 0,
                        gap: '8px'
                    }}
                >
                    <div
                        className="hover:cursor-pointer"
                        onClick={handleShowChatDetails}
                    >
                        <UserAvatar
                            imgSource={
                                tab === 'groups'
                                    ? groupProfile?.image_url
                                    : userProfile?.user?.image_url
                            }
                            fullName={
                                tab === 'groups'
                                    ? groupProfile?.name
                                    : userProfile?.user?.fullname
                            }
                            isActive={
                                tab === 'groups'
                                    ? 'active'
                                    : userProfile?.user?.status === 'active' ||
                                      userProfile?.user?.uuid === profile?.uuid
                            }
                        />
                    </div>

                    <Typography
                        sx={{
                            width: '100%',
                            display: 'flex',
                            color: 'white',
                            cursor: 'pointer'
                        }}
                        variant="body2"
                        onClick={handleShowChatDetails}
                    >
                        {tab === 'groups'
                            ? groupProfile?.name
                            : userProfile?.user?.fullname}
                    </Typography>
                </Box>
                {/* Chat messages */}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100dvh - 165px)'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            pt: '12px',
                            overflowY: 'scroll',
                            height: 'calc(100dvh - 265px)'
                        }}
                    >
                        <Box
                            sx={{
                                mt: 'auto',
                                width: '100%'
                            }}
                        >
                            {tab === 'groups' ? (
                                groupedMessages &&
                                groupedMessages.length > 0 ? (
                                    groupedMessages?.map((group, index) => (
                                        <GroupMessageBox
                                            key={index}
                                            groupMessages={group}
                                            profile={profile}
                                        />
                                    ))
                                ) : (
                                    <div className="flex justify-center items-center min-h-screen w-full pt-[300px]">
                                        <p>No messages available !</p>
                                    </div>
                                )
                            ) : tab === 'all' ? (
                                messages && messages.length > 0 ? (
                                    messages.map((text, index) => (
                                        <MessageBox
                                            id={text?.uuid}
                                            messageText={text?.body}
                                            timestamp={text?.created_at}
                                            isRight={
                                                text?.from === profile?.uuid
                                            }
                                            key={index}
                                            status={text?.status}
                                            files={text?.files}
                                        />
                                    ))
                                ) : (
                                    <div className="flex justify-center items-center min-h-screen w-full pt-[300px]">
                                        <p>No messages available !</p>
                                    </div>
                                )
                            ) : null}
                            <div id="bottom" ref={bottomRef} />
                        </Box>
                    </Box>

                    <Box>
                        {userProfile?.blocked === true ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography>
                                    You have blocked this user.
                                </Typography>
                            </Box>
                        ) : userProfile?.blocked_by_user === true ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography>
                                    You are blocked by this user.
                                </Typography>
                            </Box>
                        ) : tab === 'all' ? (
                            <SendChat />
                        ) : tab === 'groups' ? (
                            <SendGroupChat />
                        ) : null}
                    </Box>
                </Box>
            </Box>

            {showChatDetails && (
                <Box
                    sx={{
                        height: 'calc(100dvh - 101px)',
                        borderRadius: '8px',
                        width: '40%',
                        maxWidth: '356px',
                        overflow: 'auto',
                        backgroundColor: 'white'
                    }}
                >
                    {tab === 'all' ? (
                        <ChatDetails
                            onClose={handleChatDetailsClose}
                            open={showChatDetails}
                        />
                    ) : tab === 'groups' ? (
                        <GroupChatDetails
                            onClose={handleChatDetailsClose}
                            open={showChatDetails}
                        />
                    ) : null}
                </Box>
            )}
        </Box>
    );
};
