import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    intraday: [],
    isGetIntradayLoading: false,
    isBuyIntradayLoading: false,
    isSellIntradayLoading: false,
};

export const apiGetIntraday = createAsyncThunk(
    "intraday/getIntraday",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/set/getIntradayData');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiBuyIntraday = createAsyncThunk(
    "intraday/buyIntraday",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/buyIntraday', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiSellIntraday = createAsyncThunk(
    "intraday/sellIntraday",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/set/sellIntraday', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const intradaySlice = createSlice({
    name: "intraday",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Intraday
        builder
            .addCase(apiGetIntraday.pending, (state) => {
                state.isGetIntradayLoading = true;
            })
            .addCase(apiGetIntraday.fulfilled, (state, action) => {
                state.intraday = action.payload.data;
                state.isGetIntradayLoading = false;
            })
            .addCase(apiGetIntraday.rejected, (state) => {
                state.isGetIntradayLoading = false;
            });
        
        // Buy Intraday
        builder
            .addCase(apiBuyIntraday.pending, (state) => {
                state.isBuyIntradayLoading = true;
            })
            .addCase(apiBuyIntraday.fulfilled, (state, action) => {
                state.isBuyIntradayLoading = false;
            })
            .addCase(apiBuyIntraday.rejected, (state) => {
                state.isBuyIntradayLoading = false;
            });
        
        // Sell Intraday
        builder
            .addCase(apiSellIntraday.pending, (state) => {
                state.isSellIntradayLoading = true;
            })
            .addCase(apiSellIntraday.fulfilled, (state, action) => {
                state.isSellIntradayLoading = false;
            })
            .addCase(apiSellIntraday.rejected, (state) => {
                state.isSellIntradayLoading = false;
            });
    }
});

export default intradaySlice.reducer;