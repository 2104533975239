import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    cheEur: [],
    isGetCheEurLoading: false,
    isBuySellPowerOrderLoading: false,
}

export const apiGetCheEur = createAsyncThunk(
    "che/getEur",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/ckw/getchdata/eur');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiBuySellPowerOrder = createAsyncThunk(
    "che/buySellPowerOrder",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/savepowerorder', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cheSlice = createSlice({
    name: "che",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get TTF EUR
        builder
            .addCase(apiGetCheEur.pending, (state) => {
                state.isGetCheEurLoading = true;
            })
            .addCase(apiGetCheEur.fulfilled, (state, action) => {
                state.cheEur = action.payload.data;
                state.isGetCheEurLoading = false;
            })
            .addCase(apiGetCheEur.rejected, (state) => {
                state.isGetCheEurLoading = false;
            });
        
        // Buy/Sell Power Order
        builder
            .addCase(apiBuySellPowerOrder.pending, (state) => {
                state.isBuySellPowerOrderLoading = true;
            })
            .addCase(apiBuySellPowerOrder.fulfilled, (state) => {
                state.isBuySellPowerOrderLoading = false;
            })
            .addCase(apiBuySellPowerOrder.rejected, (state) => {
                state.isBuySellPowerOrderLoading = false;
            });
    }
});

export default cheSlice.reducer;