import React from 'react'
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const MonthHistoricalPrices = ({ monthHistoricalPrices }) => {
    const transformedMonthHistoricalPrices = transformData(monthHistoricalPrices);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                width={500}
                height={400}
                data={transformedMonthHistoricalPrices}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="labels" minTickGap={20} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="mx1" stroke="#05c46b" name='Month+1' />
                <Line type="monotone" dataKey="mx2" stroke="#ffa801" name='Month+2' />
                <Line type="monotone" dataKey="mx3" stroke="#3c40c6" name='Month+3' />
                <Line type="monotone" dataKey="mx4" stroke="#0652DD" name='Month+4' />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default MonthHistoricalPrices

function transformData(datasets) {
    // transform data to recharts format
    // For keys in the object, time is in labels and data is in key itself

    const transformedData = []

    // Get the length of the first dataset
    const length = datasets?.labels?.length

    for (let i = 0; i < length; i++) {
        // Insert first value of all the keys in the object
        const obj = {}

        Object.keys(datasets).forEach((key) => {
            obj[key] = datasets[key][i]
        })

        transformedData.push(obj)
    }

    return transformedData;
}