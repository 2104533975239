import React, { useMemo } from 'react'
import { Box, Button, Typography } from '@mui/material';
import { MRT_ToggleGlobalFilterButton } from 'material-react-table'
import { ReactTable } from '@components';
import { apiDeleteTrailingStop, apiGetTrailingStop, apiActivateDeactivateTrailingStop, apiGetTheEur, apiGetTrailingStopForDisplay } from '@store';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';

const TrailingStop = ({ trailingStop, isGetTrailingStopLoading }) => {
    const dispatch = useDispatch();

    const handleDelete = async (row) => {
        if (window.confirm('Are you sure you want to delete this Trailing Stop?')) {
            const resp = await dispatch(apiDeleteTrailingStop(row))
            if (resp.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Trailing Stop deleted successfully');
                dispatch(apiGetTrailingStop())
                dispatch(apiGetTrailingStopForDisplay())
                dispatch(apiGetTheEur())
            } else if (resp.meta.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Failed to delete Trailing Stop');
            }
        }
    }

    const handleActivateDeactivate = async (row) => {
        const data = {
            ...row,
            active: !row.active
        }
        const resp = await dispatch(apiActivateDeactivateTrailingStop(data))
        if (resp.meta.requestStatus === 'fulfilled') {
            showToast('success', 'Trailing Stop updated successfully');
            dispatch(apiGetTrailingStop())
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp?.payload || 'Failed to update Trailing Stop');
        }
    }

    const trailingStopColumn = useMemo(
        () => [
            {
                header: "Contract",
                id: "contract",
                accessorFn: ({ contract }) => contract || "",
                Cell: ({ renderedCellValue }) =>
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>,
                size: 50
            },
            {
                header: "Market",
                id: "market",
                accessorFn: ({ market }) => market || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Type",
                id: "type",
                accessorFn: ({ type }) => type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Rule",
                id: "rule",
                accessorFn: ({ rule }) => "Trailing Stop",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "T-Floor",
                id: "floor",
                accessorFn: ({ floor }) => floor.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "T-Ceiling",
                id: "ceiling",
                accessorFn: ({ ceiling }) => ceiling.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Auto Trade",
                id: "auto_trade",
                accessorFn: ({ auto_trade }) => auto_trade || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume",
                id: "volume",
                accessorFn: ({ volume }) => volume.toFixed(3) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Active",
                id: "active",
                accessorFn: ({ active }) => active ? "true" : "false",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], []
    );

    const trailingStopActionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <Box className='flex gap-4 items-center justify-center' key={'btns'}>
                        {row?.original?.active ?
                            <Button
                                key="deactivate"
                                variant='outlined'
                                color='primary'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    px: '4px',
                                    width: '93px',
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        backgroundColor: '#27A9FF',
                                        color: 'white',
                                    },
                                }}
                                onClick={() => handleActivateDeactivate(row?.original)}
                            >
                                Deactivate
                            </Button>
                            :
                            <Button
                                key="activate"
                                variant='outlined'
                                color='primary'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    px: '4px',
                                    width: '93px',
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        backgroundColor: '#27A9FF',
                                        color: 'white',
                                    },
                                }}
                                onClick={() => handleActivateDeactivate(row?.original)}
                            >
                                Activate
                            </Button>
                        }
                        <Button
                            key={'delete'}
                            variant='outlined'
                            color='error'
                            sx={{
                                p: 0,
                                m: 0,
                                borderColor: 'brown',
                                color: 'brown',
                                fontSize: '13px',
                                '&:hover': {
                                    borderColor: 'brown',
                                    color: 'white',
                                    backgroundColor: 'brown',
                                },
                            }}
                            onClick={() => handleDelete(row?.original)}
                        >
                            Delete
                        </Button>
                    </Box>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <ReactTable
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                    </div>
                )
            }
            headerText={'Trailing Stop'}
            columns={trailingStopColumn}
            data={trailingStop || []}
            actions={trailingStopActionsMenu}
            isLoading={isGetTrailingStopLoading}
        />
    )
}

export default TrailingStop