import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    priceprofile: [],
    singlePriceprofile: {},
    profiling_rates: {},
    isGetPriceprofileLoading: false,
    isGetSinglePriceprofileLoading: false,
    isCreatePriceprofileLoading: false,
    isDeletePriceprofileLoading: false,
    isBuyPriceProfileLoading: false,
    isGetPriceProfileLoading: false,
    isGetRatesLoading: false,
    isUploadRatesLoading: false,
};

export const apiGetPriceprofile = createAsyncThunk(
    "priceprofile/getPriceprofile",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_all_pricing_profiles');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetSinglePriceprofile = createAsyncThunk(
    "priceprofile/getSinglePriceprofile",
    async ({ uuid, call, put, volume }, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_pricing_profile?profile_uuid=${uuid}&call=${call || 0}&put=${put || 0}&volume=${volume || 0}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreatePriceprofile = createAsyncThunk(
    "priceprofile/createPriceprofile",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_pricing_profile', formData);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeletePriceprofile = createAsyncThunk(
    "priceprofile/deletePriceprofile",
    async (profile_uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_pricing_profile?profile_uuid=${profile_uuid}`)
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiBuyPriceProfile = createAsyncThunk(
    "priceprofile/buyPriceProfile",
    async ({ params: { uuid, year, call, put, volume }, body }, thunkAPI) => {
        try {
            const response = await axios.post(`/api/buy_profile?profile_uuid=${uuid}&year=${year}&call=${call || 0}&put=${put || 0}&volume=${volume || 0}`, body);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetProfilingRates = createAsyncThunk(
    "priceprofile/getRates",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_profiling_rates');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUploadRates = createAsyncThunk(
    "priceprofile/uploadRates",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/save_profiling_rates', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const priceprofileSlice = createSlice({
    name: "priceprofile",
    initialState,
    reducers: {
        deletePriceprofile: (state, action) => {
            state.priceprofile = state.priceprofile.filter(profile => profile.profile_uuid !== action.payload);
        }
    },
    extraReducers: (builder) => {
        // Get Priceprofile
        builder
            .addCase(apiGetPriceprofile.pending, (state) => {
                state.isGetPriceprofileLoading = true;
            })
            .addCase(apiGetPriceprofile.fulfilled, (state, action) => {
                state.isGetPriceprofileLoading = false;
                state.priceprofile = action.payload.data;
            })
            .addCase(apiGetPriceprofile.rejected, (state) => {
                state.isGetPriceprofileLoading = false;
            });

        // Get Single Priceprofile
        builder
            .addCase(apiGetSinglePriceprofile.pending, (state) => {
                state.isGetSinglePriceprofileLoading = true;
            })
            .addCase(apiGetSinglePriceprofile.fulfilled, (state, action) => {
                state.isGetSinglePriceprofileLoading = false;
                state.singlePriceprofile = action.payload.data;
            })
            .addCase(apiGetSinglePriceprofile.rejected, (state) => {
                state.isGetSinglePriceprofileLoading = false;
            });

        // Create Priceprofile
        builder
            .addCase(apiCreatePriceprofile.pending, (state) => {
                state.isCreatePriceprofileLoading = true;
            })
            .addCase(apiCreatePriceprofile.fulfilled, (state) => {
                state.isCreatePriceprofileLoading = false;
            })
            .addCase(apiCreatePriceprofile.rejected, (state) => {
                state.isCreatePriceprofileLoading = false;
            });

        // Delete Priceprofile
        builder
            .addCase(apiDeletePriceprofile.pending, (state) => {
                state.isDeletePriceprofileLoading = true;
            })
            .addCase(apiDeletePriceprofile.fulfilled, (state) => {
                state.isDeletePriceprofileLoading = false;
            })
            .addCase(apiDeletePriceprofile.rejected, (state) => {
                state.isDeletePriceprofileLoading = false;
            });

        // Buy Priceprofile
        builder
            .addCase(apiBuyPriceProfile.pending, (state) => {
                state.isBuyPriceProfileLoading = true;
            })
            .addCase(apiBuyPriceProfile.fulfilled, (state) => {
                state.isBuyPriceProfileLoading = false;
            })
            .addCase(apiBuyPriceProfile.rejected, (state) => {
                state.isBuyPriceProfileLoading = false;
            });

        // Get Rates
        builder
            .addCase(apiGetProfilingRates.pending, (state) => {
                state.isGetRatesLoading = true;
            })
            .addCase(apiGetProfilingRates.fulfilled, (state, action) => {
                state.isGetRatesLoading = false;
                state.profiling_rates = action.payload.data;
            })
            .addCase(apiGetProfilingRates.rejected, (state) => {
                state.isGetRatesLoading = false;
            });

        // Upload Rates
        builder
            .addCase(apiUploadRates.pending, (state) => {
                state.isUploadRatesLoading = true;
            })
            .addCase(apiUploadRates.fulfilled, (state) => {
                state.isUploadRatesLoading = false;
            })
            .addCase(apiUploadRates.rejected, (state) => {
                state.isUploadRatesLoading = false;
            });
    }
});

export const { deletePriceprofile } = priceprofileSlice.actions;

export default priceprofileSlice.reducer;