import React, { forwardRef, useState } from 'react'
import { CustomModal } from '@components'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { apiSetLimitOrder, apiGetPegnordEur, apiGetLimitOrder } from '@store';
import { showToast } from '@utils';

const PriceNotification = forwardRef(({ handleClose, eur }, ref) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.user);

    const [state, setState] = useState({
        market: 'PEGNord',
        username: user?.Username,
        email: user?.Email,
        UserId: user?.UserId,
        provider: '',
        contract: '',
        type: '',
        price_gt: '',
        price_lt: '',
        purchase: 0
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // convert price_gt and price_lt to number
        state.price_gt = parseFloat(state.price_gt)
        state.price_lt = parseFloat(state.price_lt)

        // send 0 if price_gt or price_lt is empty
        if (!state.price_gt) state.price_gt = 0
        if (!state.price_lt) state.price_lt = 0

        const response = await dispatch(apiSetLimitOrder([state]))
        if (response?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Price Notification Set Successfully')
            dispatch(apiGetPegnordEur())
            dispatch(apiGetLimitOrder())
            handleClose()
        } else if (response?.meta?.requestStatus === 'rejected') {
            showToast('error', response?.payload || 'Something went wrong')
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Set Price for Notification`}
            buttonText={'Set Price Notification'}
            ref={ref}
            disabled={state.price_gt || state.price_lt ? false : true}
        >
            <Box>
                <Box className='flex gap-3' mb={2}>
                    <Box className='flex flex-col gap-3 w-full'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Provider"
                                name='provider'
                                onChange={handleChange}
                                value={state.provider}
                                required
                            >
                                {user?.CanBuyFrom?.map((option, index) => (
                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Contract</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Contract"
                                name='contract'
                                onChange={handleChange}
                                value={state.contract}
                                required
                            >
                                {eur?.symbols?.map((option, index) => (
                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Bid/Ask</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Month"
                                name='type'
                                onChange={handleChange}
                                value={state.type}
                                required
                            >
                                <MenuItem value='bid'>Bid</MenuItem>
                                <MenuItem value='ask'>Ask</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='flex flex-col gap-3 w-full'>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="priceGtLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                                shrink
                            >
                                Price Greater Than
                            </InputLabel>
                            <OutlinedInput
                                id="price_gt"
                                label="Price Greater Than"
                                type='number'
                                name='price_gt'
                                placeholder='Trade if price greater than...'
                                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                onChange={handleChange}
                                value={state.price_gt}
                                disabled={state.price_lt ? true : false}
                            />
                        </FormControl>
                        <FormControl fullWidth={true} variant="outlined">
                            <InputLabel
                                id="priceLtLabel"
                                sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                                shrink
                            >
                                Price Lower Than
                            </InputLabel>
                            <OutlinedInput
                                id="price_lt"
                                placeholder='Trade if price lower than...'
                                label="Price Lower Than"
                                type='number'
                                name='price_lt'
                                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                onChange={handleChange}
                                value={state.price_lt}
                                disabled={state.price_gt ? true : false}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Typography variant='caption'>
                    You will get notified by email when the contract you set for goes higher than or less than the prices you have set.
                </Typography>
            </Box>
        </CustomModal >
    )
})

export default PriceNotification