import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import TrailingStop from './TrailingStop';
import MinMaxOrder from './MinMaxOrder';
import LimitOrder from './LimitOrder';
import { AppName, NotificationsTitle } from '@utils';

const Notification = () => {
    useEffect(() => {
        document.title = `${AppName} | ${NotificationsTitle}`
    }, [])

    const {
        limitOrder,
        isGetLimitOrderLoading,
        trailingStop,
        isGetTrailingStopLoading,
        minMaxRaw,
        isGetMinMaxRawLoading
    } = useSelector((state) => state.notification);

    return (
        <Box className='flex flex-col gap-4'>
            <LimitOrder limitOrder={limitOrder} isGetLimitOrderLoading={isGetLimitOrderLoading} />
            <TrailingStop trailingStop={trailingStop} isGetTrailingStopLoading={isGetTrailingStopLoading} />
            <MinMaxOrder minMaxRaw={minMaxRaw} isGetMinMaxRawLoading={isGetMinMaxRawLoading} />
        </Box>
    )
}

export default Notification