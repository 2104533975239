import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography} from '@mui/material'
import { CalendarComponent } from '@components'
import Overview from './Overview'
import { AppName, CalendarTitle } from '@utils'

const Calendar = () => {
    useEffect(() => {
        document.title = `${AppName} | ${CalendarTitle}`
    }, [])

    const { user } = useSelector((state) => state.user);
    const { announcement } = useSelector(state => state.announcement)

    return (
        <>
            <Box className='mb-4'>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 3 }}>Welcome Back {user.FullName}!</Typography>
                <Box className='px-4'>
                    <CalendarComponent events={announcement} />
                </Box>
            </Box>
            {/* <Box className='mb-4'>
                <News newsfeed={newsfeed} />
            </Box> */}
            <Box>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 3 }}>Calendar Events</Typography>
                <Overview announcement={announcement} />
            </Box>
        </>
    )
}

export default Calendar