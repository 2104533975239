import React, { forwardRef, useEffect, useState } from 'react'
import { CustomModal } from '@components'
import { Typography } from '@mui/material';

const InfoModal = forwardRef(({ handleClose }, ref) => {
    // timer
    const [timer, setTimer] = useState(25);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (timer === 0) {
            handleClose();
        }
    }, [timer, handleClose]);

    useEffect(() => {
        if (ref.current) {
            setTimer(25);
        }
    }, [ref]);

    const handleSubmit = () => {
        handleClose();
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            timer={timer}
            modalTitle={`Info`}
            buttonText={'Ok'}
            ref={ref}
        >
            <Typography variant="body1" align="center" color={'#000'}>
                You can only trade between 9:00 AM CET and 15:00 PM CET. Please check back during those times. Thank You.
            </Typography>
        </CustomModal>
    )
})

export default InfoModal