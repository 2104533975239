import React, { useEffect, useState } from 'react'
import { TabComponent } from '@components'
import OrderLog from './orderLog/OrderLog'
import OrderAnalytics from './orderAnalytics/OrderAnalytics'
import { useSelector } from 'react-redux'
import { AppName, OrdersTitle } from '@utils'

const Orders = () => {
    useEffect(() => {
        document.title = `${AppName} | ${OrdersTitle}`
    }, [])

    const { order, isGetOrderLoading, ordersInfo, isGetOrdersInfoLoading } = useSelector((state) => state.order)

    // add entry modal
    const [openModal, setOpenModal] = useState(false)
    const handlelOpenModal = () => { setOpenModal(true) }
    const handleCloseModal = () => { setOpenModal(false) }

    const tabs = [
        {
            name: 'Orders Log',
            content: <OrderLog
                orderLogs={order}
                isLoading={isGetOrderLoading}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
            />,
            // buttons: [
            //     {
            //         text: 'Add New Entry',
            //         backgroundColor: 'blue',
            //         color: 'white',
            //         onClick: () => { handlelOpenModal() }
            //     }
            // ]
        },
        // {
        //     name: 'Orders Analytics',
        //     content: <OrderAnalytics orderInfo={ordersInfo} isLoading={isGetOrdersInfoLoading} />,
        // }
    ]

    return (
        <TabComponent tabs={tabs} />
    )
}

export default Orders