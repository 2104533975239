import React, { forwardRef, useState } from 'react'
import { Box, FormControl, TextField, Typography } from '@mui/material'
import ReactFileReader from 'react-file-reader';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { apiCreateNews, apiGetNews } from '@store';
import { useDispatch } from 'react-redux';
import { showToast } from '@utils';
import { CustomModal } from '@components';

const UploadPost = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        headline: '',
        postFile: '',
        text: '',
        postUrl: ''
    })

    const handleFiles = (files) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            setState({
                ...state,
                postFile: files[0],
                postUrl: e.target.result
            })
        }
        reader.readAsDataURL(files[0]);
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('Headline', state.headline)
        formData.append('postFile', state.postFile)
        formData.append('Text', state.text)
        const response = await dispatch(apiCreateNews(formData))
        if (response.meta.requestStatus === 'fulfilled') {
            showToast('info', 'Post created successfully')
            dispatch(apiGetNews())
            handleClose()
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        }
    }

    return (
        <CustomModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            modalTitle={`Create new post`}
            buttonText={'Submit'}
            ref={ref}
        >
            <FormControl fullWidth={true}>
                <TextField
                    className='modal-input'
                    onChange={handleChange}
                    id="headline"
                    label="Headline"
                    variant="outlined"
                    name="headline"
                    required
                />
                <Box className='border-dashed border-2 border-[#C0C0C0] mt-2 mb-3'>
                    <ReactFileReader handleFiles={handleFiles} fileTypes={'.png,.jpg,.jpeg'} >
                        {state?.postFile ?
                            <Box className='relative'>
                                <img
                                    src={state.postUrl}
                                    alt={state.title}
                                    className='object-cover h-80 w-full relative'
                                />
                                <Box className='absolute top-0 right-0 mt-1 mr-1 bg-white p-1 rounded-full cursor-pointer' >
                                    <FileUploadIcon
                                        sx={{ zIndex: 100 }}
                                        onClick={() => setState({ ...state, image: '' })}
                                        titleAccess='Upload another image'
                                    />
                                </Box>
                            </Box>
                            :
                            <>
                                <Box className='flex flex-col items-center justify-center h-80 cursor-pointer'>
                                    <FileUploadIcon fontSize='large' />
                                    <Typography caption='caption'>Upload Image</Typography>
                                </Box>
                            </>
                        }
                    </ReactFileReader>
                </Box>
                <TextField
                    id="outlined-multiline-static"
                    label="Content"
                    multiline
                    rows={4}
                    variant="outlined"
                    name="text"
                    onChange={handleChange}
                    fullWidth
                />
            </FormControl>
        </CustomModal>
    )
})

export default UploadPost